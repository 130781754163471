<template>
  <div class='page-heading'>
    <div class='page-title'>
      <div class='row'>
        <div class='col-12 col-md-6 order-md-1 order-last'>
          <h3>All Ads</h3>
          <p class='text-subtitle text-muted'>
            Check Ad and Approve to show in client site
          </p>
        </div>
        <div class='col-12 col-md-6 order-md-2 order-first'>
          <nav
            aria-label='breadcrumb'
            class='breadcrumb-header float-start float-lg-end'
          >
            <ol class='breadcrumb'>
              <li class='breadcrumb-item'>
                <router-link :to="{ name: 'home' }">Dashboard</router-link>
              </li>
              <li class='breadcrumb-item active' aria-current='page'>
                All Ads
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <section id='multiple-column-form' v-show='openAddForm'>
      <div class='row match-height'>
        <div class='col-12'>
          <div class='card'>
            <div class='card-content'>
              <div class='card-body'>
                <Form
                  class='form'
                  v-slot='{ handleSubmit }'
                  @submit.prevent='updateAds'
                >
                  <div class='row'>
                    <div class='col-6'>
                      <label for='spec-value'>Condition</label>
                      <div class='form-group'>
                        <div role="radiogroup" class="radio-section">
                          <div class="custom-radio">
                            <input name="condition" id="condition_0" type="radio" class="sr-only" value="0" @change="errorMessages.condition=''" v-model="AdsModel.condition" required>
                            <label for="condition_0">
                              <span></span>
                              <div class="radio-title">Used</div>
                            </label>
                          </div>
                          <div class="custom-radio">
                            <input name="condition" id="condition_1" type="radio" class="sr-only" value="1" @change="errorMessages.condition=''" v-model="AdsModel.condition">
                            <label for="condition_1">
                              <span></span>
                              <div class="radio-title">New</div>
                            </label>
                          </div>
                          <div class="custom-radio">
                            <input name="condition" id="condition_2" type="radio" class="sr-only" value="2" @change="errorMessages.condition=''" v-model="AdsModel.condition">
                            <label for="condition_2">
                              <span></span>
                              <div class="radio-title">Reconditioned</div>
                            </label>
                          </div>
                        </div>
                        <small class="text-danger" v-if="this.errorMessages.condition && this.errorMessages.condition!==''">this.errorMessages.condition</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Brand</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="brand"
                          placeholder="Vehicle Brand"
                          v-model="selectedBrand"
                          @change="handleBrandChange"
                        >
                          <option disabled selected hidden value="0">
                            Select Brand
                          </option>
                          <option v-for="brand in brands" :key="brand.id"  :value="generateBrandValue(brand)">
                            {{ brand.title }}
                          </option>
                        </Field>
                        <small class="text-danger" v-if="this.errorMessages.brand && this.errorMessages.brand!==''">{{ this.errorMessages.brand }}</small>
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group' v-if="this.AdsModel.model_id!=='0'">
                        <label for='spec-value'>Model</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="model"
                          placeholder="Vehicle Model"
                          v-model="selectedModel"
                          @change="handleModelChange"
                        >
                          <option disabled selected hidden value="0">
                            Select Model
                          </option>
                          <option v-for="model in this.models"  :key="model.product_id" :value="generateModelValue(model)">
                            {{ model.value }}
                          </option>
                        </Field>
                        <small class="text-danger" v-if="this.errorMessages.model && this.errorMessages.model!==''">{{ this.errorMessages.model }}</small>
                      </div>
                      <div v-else>
                        <div class='form-group'>
                          <label for='spec-value'>Model Id</label>
                          <Field
                            type='text'
                            class='form-control'
                            name='model_id'
                            placeholder='Model id'
                            v-model="otherModelId"
                          />
                          <small class="text-danger" v-if="this.errorMessages.model && this.errorMessages.model!==''">{{ this.errorMessages.model }}</small>
                        </div>
                        <div class='form-group'>
                          <label for='spec-value'>Model Name</label>
                          <Field
                            type='text'
                            class='form-control'
                            name='model_name'
                            placeholder='Model name'
                            v-model="otherModelName"
                          />
                          <small class="text-danger" v-if="this.errorMessages.model && this.errorMessages.model!==''">{{ this.errorMessages.model }}</small>
                        </div>
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Model Year</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="model_year"
                          placeholder="Model Year"
                          v-model="AdsModel.model_year"
                          @change="errorMessages.model_year=''"
                        >
                          <option disabled selected hidden value="0">
                            Select Model Year
                          </option>
                          <option v-for="year in years" :key="year.value" :value="year.value">
                            {{ year.value }}
                          </option>
                        </Field>
                        <small class="text-danger" v-if="this.errorMessages.model_year && this.errorMessages.model_year!==''">{{ this.errorMessages.model_year }}</small>
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Registration Year</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="reg_year"
                          placeholder="Registration Year"
                          v-model="AdsModel.reg_year"
                          @change="errorMessages.reg_year=''"
                        >
                          <option disabled selected hidden value="0">
                            Select Registration Year
                          </option>
                          <option v-for="year in years" :key="year.value" :value="year.value">
                            {{ year.value }}
                          </option>
                        </Field>
                        <small class="text-danger" v-if="this.errorMessages.model_year && this.errorMessages.reg_year!==''">{{ this.errorMessages.reg_year }}</small>
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Kilometers run (km)</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='run'
                          placeholder='Kilometers run (km)'
                          v-model="AdsModel.run"
                        />
                        <small class="text-danger" v-if="this.errorMessages.run && this.errorMessages.run!==''">{{ this.errorMessages.run }}</small>
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Price (Tk)</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='price'
                          placeholder='Price (Tk)'
                          v-model="AdsModel.price"
                          @change="errorMessages.price=''"
                        />
                        <small class="text-danger" v-if="this.errorMessages.run && this.errorMessages.run!==''">{{ this.errorMessages.price }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <label for='spec-value'>Body Type</label>
                    <div class="col-4">
                      <div class="form-group">
                        <div role="radiogroup" class="radio-section">
                          <div class="custom-radio">
                            <input name="body_type" id="body_type_0" type="radio" class="sr-only" value="0" @change="errorMessages.body_type=''" v-model="AdsModel.body_type" required>
                            <label for="body_type_0">
                              <span></span>
                              <div class="radio-title">Open Body</div>
                            </label>
                          </div>
                          <div class="custom-radio">
                            <input name="body_type" id="body_type_1" type="radio" class="sr-only" value="1" @change="errorMessages.body_type=''" v-model="AdsModel.body_type">
                            <label for="body_type_1">
                              <span></span>
                              <div class="radio-title">Covered Van</div>
                            </label>
                          </div>
                        </div>
                        <small class="text-danger" v-if="this.errorMessages.body_type && this.errorMessages.body_type!==''">{{ this.errorMessages.body_type }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class='col-md-12 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Description</label>
                        <textarea class='form-control' name='description'  placeholder='Mention used year, document status, engine condition, running status etc.' rows="8" v-model="AdsModel.description" @change="errorMessages.description=''">
                        </textarea>
                        <small class="text-danger" v-if="this.errorMessages.description && this.errorMessages.description!==''">{{ this.errorMessages.description }}</small>
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Division</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="division"
                          placeholder="Division"
                          v-model="selectedDivision"
                          @change="bindDivisions"
                        >
                          <option value="0">
                            All Divisions
                          </option>
                          <option v-for="division in divisions" :key="division.id" :value="division.id">
                            {{division.name}}
                          </option>
                        </Field>
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>District</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="district"
                          placeholder="District"
                          v-model="selectedDistrict"
                          @change="setDistrict"
                        >
                          <option value="0">All District</option>
                          <option v-for="district in selectedDistricts"  :key="district.id" :value="district.id">
                            {{ district.name }}</option>
                        </Field>
                      </div>
                    </div>
                    <div class='col-md-12 col-12'>
                      <div class="form-group">
                        <label for="imageUrl">Ads Image</label>
                        <div v-if="AdsModel.ad_photo_files.length>0" class="image-container">
                          <template v-for="(AdsImage, index1) in adsImages" :key="index1">
                            <div v-if="AdsImage!==''" class="image-section" >
                                <img class="img" width="140" :src="AdsImage" :alt="AdsImage" />
                                <i class="bi bi-x cross-icon" @click="removeAdsImage(AdsImage)"></i>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                    <div class='form-group col-12'>
                      <div class='form-check'>
                        <div class='checkbox'>
                          <input
                            type='checkbox'
                            id='checkbox5'
                            class='form-check-input'
                            checked
                            v-model="AdsModel.status"
                          />
                          <label for='checkbox5'>Approve</label>
                        </div>
                      </div>
                    </div>
                    <div class='col-12 d-flex justify-content-end'>
                      <button
                        v-show="AdsModel.id!=='undefined' && AdsModel.id!==0"
                        class='btn btn-primary me-1 mb-1'
                        @click='handleSubmit($event, updateAds)'
                      >
                        Update
                      </button>
                      <button
                        class='btn btn-secondary me-1 mb-1'
                        @click='reset'
                      >
                        Reset
                      </button>
                      <button
                        class='btn btn-danger me-1 mb-1'
                        @click='openAddForm = false'
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- // Basic multiple Column Form section end -->
    <!-- Basic Tables start -->
    <section class='section'>
      <div class='card'>
        <div class='card-header'>
        </div>
        <div class='card-body'>
          <table class='table table-striped' id='table1'>
            <thead>
            <tr>
              <th>#</th>
              <th>Brand</th>
              <th>Model</th>
              <th>Image</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for='(ad, index) in ads' :key='index' >
              <td>{{ index+1 }}</td>
              <td>{{ ad.brand_name }}{{ad.district_id}}</td>
              <td>{{ ad.model_name}}</td>
              <td>
                <div v-if="ad.ad_photo_files.length>0">
                  <template v-for="(adImage, index1) in ad.ad_photo_files" :key="index1">
                    <img class="img mb-3 me-3" width="140" :src="adImage" :alt="adImage" />
                  </template>
                </div>
              </td>
              <td>
                <span :class="'badge chip' + (ad.status ? ' bg-light-success' :' bg-light-danger')">{{ad.status? "Approved" : "UnApproved"}}</span>
              </td>
              <td>
                <button class='badge bg-danger mr-5' @click="deleteAds(ad.id)">Delete</button>
                <button class='badge bg-primary ml-5' @click="editAds(ad)">Edit</button>
                <button class='badge bg-secondary ml-5' @click="approveOrUnapproveAds(ad)">{{ad.status? "UnApprove" : "Approve"}}</button>
              </td>
            </tr>
            <tr v-if='!ads.length'>
              <td colspan='6' class='text-center'>No entries found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <!-- Basic Tables end -->
  </div>
</template>

<style scoped>
.image-container{
 display: flex;
}
.image-section{
  border: 1px solid #ddd;
  padding: 30px;
  border-radius: 4px;
  position: relative;
  margin-right: 15px;
}
.cross-icon{
  position: absolute;
  top: 3px;
  right: 12px;
  cursor: pointer;
}
.bi-x::before {
  font-size: 25px;
}
.radio-section{
  display: flex;
  justify-content: space-between;
}
.custom-radio {
  overflow: hidden;
}
.custom-radio input[type=radio], .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
  line-height: 1.15;
}
input[type=radio]+label {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px !important;
  -ms-flex-align: center;
  align-items: center;
}
.custom-radio input[type=radio]+label span {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #afb7ad;
  padding: 6px;
  border-radius: 50%;
}

.custom-radio input[type=radio]+label span, .radio-desktop-standard--1douS {
  height: 16px;
  width: 16px;
}
.radio-title {
  margin-left: 8px;
  color: #424e4e;
  margin-top: 1px;
  width: 100%;
  font-size: 15px;
}
.custom-radio input[type=radio]:checked+label span {
  background: radial-gradient(#fff 27.5%,#ffb600 0);
  border: 1px solid #ffb600;
}
.terms-label{
  font-size: 16px;
  margin-left: 10px;
  display: inline;
}
.terms-label a{
  color: #0074ba;
  margin-right: 5px;
}
</style>

<script>
import { Field, Form } from 'vee-validate'
import _ from 'lodash'

import AdsModel from '../../models/AdsModel'
import brandService from '@/services/brand.service'
import adsService from '../../services/ads.service'
import divisionsData from '@/../public/assets/js/bd-divisions.json'
import districtsData from '@/../public/assets/js/bd-districts.json'
import yearsData from '@/../public/assets/js/years.json'

export default {
  name: 'Ads',
  data () {
    return {
      AdsModel: new AdsModel(),
      itemBlueprints: [],
      ads: [],
      adsImages: [],
      openAddForm: false,
      editImageURL: '',
      brands: [],
      models: [],
      selectedBrand: '0',
      selectedModel: '0',
      errorMessages: {},
      component: {},
      options: {},
      imageSize: 4100000, // 4MB
      formatType: ['image/jpeg', 'image/png', 'image/jpg'],
      showSuccessModal: false,
      showImageOverlay: false,
      selectedImage: '',
      selectedDivision: '',
      selectedDistrict: '',
      selectedDistricts: districtsData,
      divisions: divisionsData.divisions,
      districts: districtsData,
      years: yearsData.years,
      termsCheck: 0,
      buttonDisabled: false,
      otherModelId: '0',
      otherModelName: ''
    }
  },
  components: { Form, Field },
  watch: {
  },
  mounted () {
    this.getAds()
    this.getBrands()
  },
  methods: {
    removeAdsImage (image) {
      this.adsImages = this.adsImages.filter(imageString => {
        if (imageString !== image) return imageString
      })
      this.AdsModel.ad_photo_files = this.adsImages
    },
    getAds () {
      this.ads = []
      adsService.getAds().then(
        response => {
          if (response.data.status) {
            this.ads = _.map(response.data.objects.ads, function (b) {
              return new AdsModel().load(b)
            })
          }
        }
      )
    },
    reset () {
      this.AdsModel = new AdsModel()
      this.adsImages = []
    },
    editAds (AdsModel) {
      this.AdsModel = AdsModel
      this.adsImages = this.AdsModel.ad_photo_files
      this.selectedBrand = this.AdsModel.brand_id + '_' + this.AdsModel.brand_name
      this.getModelByProductId(this.AdsModel.brand_id)
      this.selectedModel = this.AdsModel.model_id + '_' + this.AdsModel.model_name
      this.otherModelName = this.AdsModel.model_name
      this.selectedDistrict = this.AdsModel.district_id.toString()
      // console.log(this.AdsModel.district_id, this.selectedDistricts)
      if (this.AdsModel.district_id !== 0) {
        this.selectedDivision = this.selectedDistricts.filter(item => item.id === this.AdsModel.district_id.toString())[0].division_id.toString()
      } else {
        this.selectedDivision = 0
      }
      // console.log(this.AdsModel.district_id, this.selectedDivision, this.selectedDistricts)
      this.openAddForm = true
    },
    setDistrict () {
      this.AdsModel.district_id = this.selectedDistrict
    },
    approveOrUnapproveAds (AdsModel) {
      this.AdsModel = AdsModel
      this.AdsModel.status = !AdsModel.status
      this.updateAds()
    },
    updateAds () {
      const vm = this
      if (this.otherModelId !== '0') {
        this.AdsModel.model_id = this.otherModelId
        this.AdsModel.model_name = this.otherModelName
      }
      adsService.updateAds(this.AdsModel).then(

        response => {
          if (response.data.status) {
            const ads = _.find(vm.ads, function (p) { return p.id === vm.AdsModel.id })
            ads.load(response.data.objects.ads)
            vm.reset()
            this.openAddForm = false
            this.getAds()
          }
        }
      )
    },
    deleteAds (adsId) {
      const vm = this
      adsService.deleteAds(adsId).then(
        response => {
          if (response.data.status) {
            vm.ads = _.filter(vm.ads, function (p) { return p.id !== adsId })
            vm.reset()
            this.openAddForm = false
            this.getAds()
          }
        }
      )
    },
    bindDivisions () {
      this.selectedDistricts = this.districts.filter(item => item.division_id === this.selectedDivision)
    },
    generateBrandValue (brand) {
      return `${brand.id}_${brand.title}`
    },
    handleBrandChange () {
      const brandArray = this.selectedBrand.split('_')
      this.AdsModel.brand_id = brandArray[0]
      this.AdsModel.brand_name = brandArray[1]
      this.selectedModel = ''
      this.getModelByProductId(brandArray[0])
      this.errorMessages.brand = ''
    },
    generateModelValue (model) {
      return `${model.product_id}_${model.value}`
    },
    handleModelChange () {
      const modelArray = this.selectedModel.split('_')
      this.AdsModel.model_id = modelArray[0]
      this.AdsModel.model_name = modelArray[1]
      this.errorMessages.model = ''
    },
    getModelByProductId (brandId) {
      brandService.getModelByBrandId(brandId).then(
        response => {
          if (response.data.status) {
            this.models = response.data.objects.models
          }
        }
      )
    },
    closeSuccessModal () {
      this.showSuccessModal = false
      window.location.reload()
    },
    getBrands () {
      this.brands = []
      brandService.getBrands().then(
        response => {
          if (response.data.status) {
            this.brands = response.data.objects.brands
          }
        }
      )
    },
    handleOtherModel () {
      this.errorMessages.model = ''
      this.AdsModel.model_id = 0
      this.AdsModel.model_name = this.otherModelName
    }
  },
  computed: {
  }
}
</script>
