import UtilityService from '../services/utility.service'
export default class ServiceModel {
  constructor () {
    this.id = 0
    this.site_url = ''
    this.image_url = ''
    this.position = 0
    this.page_type = 0
    this.created_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
  }

  load (data) {
    this.id = data.id
    this.site_url = data.site_url
    this.image_url = data.image_url
    this.position = data.position
    this.page_type = data.page_type
    this.created_at = UtilityService.formatDateTime(
      data.created_at,
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      data.updated_at,
      'MM/DD/YY hh:mm A'
    )
    return this
  }
}
