<template>
  <div class='page-heading'>
    <div class='page-title'>
      <div class='row'>
        <div class='col-12 col-md-6 order-md-1 order-last'>
          <h3>Settings</h3>
          <p class='text-subtitle text-muted'>
            Change your app settings
          </p>
        </div>
      </div>
    </div>
    <section id='multiple-column-form'>
      <div class='row match-height'>
        <div class='col-12'>
          <div class='card'>
            <div class='card-content'>
              <div class='card-body'>
                <Form
                  class='form'
                  v-slot='{ handleSubmit }'
                >
                  <div class='row'>
                    <div class="">
                      <div class='form-group col-12 mb-4'>
                        <label for='spec-value'>Offer Settings</label>
                        <span class="small-text">Change visibility of Offers section in the client website by change this action.</span>
                        <div class='form-check'>
                          <div class='checkbox'>
                            <input
                              type='checkbox'
                              id='checkbox1'
                              class='form-check-input'
                              checked
                              v-model="isOfferActive"
                            />
                            <label for='checkbox1'>Active</label>
                          </div>
                        </div>
                      </div>

                      <div class='form-group col-12 mt-4 mb-4'>
                        <label for='spec-value'>News Settings</label>
                        <span class="small-text">Change visibility of News section in the client website by change this action.</span>
                        <div class='form-check'>
                          <div class='checkbox'>
                            <input
                              type='checkbox'
                              id='checkbox2'
                              class='form-check-input'
                              checked
                              v-model="isNewsActive"
                            />
                            <label for='checkbox2'>Active</label>
                          </div>
                        </div>
                      </div>

                      <div class='form-group col-12 mb-4'>
                        <label for='spec-value'>Video Settings</label>
                        <span class="small-text">Change visibility of Video section in the client website by change this action.</span>
                        <div class='form-check'>
                          <div class='checkbox'>
                            <input
                              type='checkbox'
                              id='checkbox3'
                              class='form-check-input'
                              checked
                              v-model="isVideoActive"
                            />
                            <label for='checkbox3'>Active</label>
                          </div>
                        </div>
                      </div>

                      <div class='form-group col-12 col-md-6'>
                        <label>Owners Youtube Channel URL</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='youtubeURL'
                          placeholder='Enter youtube Channel URL'
                          v-model="youtubeUrl"
                        />
                      </div>
                    </div>
                    <div class='col-12 d-flex justify-content-end'>
                      <button
                        class='btn btn-primary me-1 mb-1'
                        @click='handleSubmit($event, createOrUpdateSettings)'
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.small-text{
  display: block;
  font-size: 12px;
  color: #555555;
  margin-bottom: 10px;
}
</style>

<script>
import { Form, Field } from 'vee-validate'
import _ from 'lodash'

import SettingsModel from '../../models/SettingsModel'
import SettingsService from '../../services/settings.service'
export default {
  name: 'Settings',
  data () {
    return {
      SettingsModel: new SettingsModel(),
      settings: [],
      isOfferActive: false,
      isNewsActive: false,
      isVideoActive: false,
      youtubeUrl: ''
    }
  },
  components: { Form, Field },
  watch: {
  },
  mounted () {
    this.getSettings()
  },
  methods: {
    getSettings () {
      this.settings = []
      SettingsService.getSettings().then(
        response => {
          if (response.data.status) {
            if (response.data.objects.settings.length > 0) {
              this.isOfferActive = response.data.objects.settings[0].isOfferActive === 1
              this.isNewsActive = response.data.objects.settings[0].isNewsActive === 1
              this.isVideoActive = response.data.objects.settings[0].isVideoActive === 1
              this.youtubeUrl = response.data.objects.settings[0].youtubeUrl
              this.itemBlueprints = _.map(response.data.objects.settings, function (p) {
                return new SettingsModel().load(p)
              })
              this.settings = _.map(response.data.objects.settings[0], function (b) {
                return new SettingsModel().load(response.data.objects.settings[0])
              })
            }
          }
        }
      )
    },
    createOrUpdateSettings () {
      this.SettingsModel.isOfferActive = this.isOfferActive
      this.SettingsModel.isNewsActive = this.isNewsActive
      this.SettingsModel.isVideoActive = this.isVideoActive
      this.SettingsModel.youtubeUrl = this.youtubeUrl
      SettingsService.updateSettings(this.SettingsModel).then(
        response => {
          if (response.data.status) {
            this.getSettings()
          }
        }
      )
    }
  },
  computed: {
  }
}
</script>
