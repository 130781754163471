import UtilityService from '../services/utility.service'
export default class BrochureModel {
  constructor () {
    this.id = 0
    this.brand_id = 0
    this.product_id = 0
    this.title = ''
    this.file_url = ''
    this.brochure_file = []
    this.type = -1
    this.created_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
  }

  load (data) {
    this.id = data.id
    this.brand_id = data.brand_id
    this.product_id = data.product_id
    this.title = data.title
    this.file_url = data.file_url ?? ''
    this.brochure_file = data.brochure_file ?? []
    this.type = data.type
    this.created_at = UtilityService.formatDateTime(
      data.created_at,
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      data.updated_at,
      'MM/DD/YY hh:mm A'
    )
    return this
  }
}
