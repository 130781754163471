<template>
  <div class='page-heading'>
    <div class='page-title'>
      <div class='row'>
        <div class='col-12 col-md-6 order-md-1 order-last'>
          <h3>All Videos</h3>
          <p class='text-subtitle text-muted'>
            View your added videos and add new ones
          </p>
        </div>
        <div class='col-12 col-md-6 order-md-2 order-first'>
          <nav
            aria-label='breadcrumb'
            class='breadcrumb-header float-start float-lg-end'
          >
            <ol class='breadcrumb'>
              <li class='breadcrumb-item'>
                <router-link :to="{ name: 'home' }">Dashboard</router-link>
              </li>
              <li class='breadcrumb-item active' aria-current='page'>
                All Videos
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <section id='multiple-column-form'>
      <div class='row match-height'>
        <div class='col-12'>
          <div class='card'>
            <div class='card-header d-flex justify-content-between align-items-center'>
              <button
                @click='openAddForm = !openAddForm'
                class='card-title btn btn-outline-dark'
              >
                Add Video
              </button>
              <i class="bi bi-filter" style="font-size: 35px; display: contents; cursor: pointer" @click="modalToggle(true)"></i>
            </div>
            <div v-show='openAddForm' class='card-content'>
              <div class='card-body'>
                <Form
                  class='form'
                  v-slot='{ handleSubmit }'
                  @submit.prevent='createOrUpdateVideo'
                  :validation-schema='ValidationSchema'
                >
                  <div class='row'>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label>Title</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='title'
                          placeholder='Video Title'
                          v-model="VideoModel.title"
                        />
                        <ErrorMessage class='text-danger' name='title' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label>Title (In Bangla)</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='title_bn'
                          placeholder='Video Title (In Bangla)'
                          v-model="VideoModel.title_bn"
                        />
                        <ErrorMessage class='text-danger' name='title_bn' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label>Youtube Video URL</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='video_url'
                          placeholder='Youtube Video URL'
                          v-model="VideoModel.video_url"
                        />
                        <ErrorMessage class='text-danger' name='video_url' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class="form-group">
                        <label>Thumbnail Image</label>
                        <Field @change="onThumbnailUpload" type="file" class="form-control" name="thumbnailFile" placeholder="Thumbnail File" id="input_file"/>
                        <span class="small-text">Upload Landscape Image for better view</span>
                        <img v-if="editImageURL!==''" class="img" width="200" style="margin-top: 10px" :src="editImageURL" alt="Thumbnail_Image">
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label>Upload Date</label>
                        <Field
                          type='date'
                          class='form-control'
                          name='upload_date'
                          placeholder='Upload date'
                          v-model="VideoModel.upload_date"
                        />
                        <ErrorMessage class='text-danger' name='upload_date' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label>Video Duration</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='duration'
                          placeholder='Video Duration Ex. 05:30'
                          v-model="VideoModel.duration"
                        />
                        <ErrorMessage class='text-danger' name='duration' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label>Subscribe URL</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='subscribe_url'
                          placeholder='Subscribe URL'
                          v-model="VideoModel.subscribe_url"
                        />
                        <ErrorMessage class='text-danger' name='subscribe_url' />
                        <div class='form-check mt-2'>
                          <div class='checkbox'>
                            <input
                              type='checkbox'
                              id='checkbox1'
                              class='form-check-input'
                              @change="bindDefaultSubscribedURL"
                            />
                            <label for='checkbox1'>Set Default URL</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label>Priority</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='priority'
                          placeholder='Priority'
                          v-model="VideoModel.priority"
                        />
                        <ErrorMessage class='text-danger' name='priority' />
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-12 d-flex justify-content-center">
                        <hr class="break-line">
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label class='typo__label'>Category</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="category"
                          placeholder="Category"
                          v-model="VideoModel.category_id_1"
                        >
                          <option disabled selected hidden value="">
                            Select Category
                          </option>
                          <option v-for="category in categoryData" :key="category.id" :value="category.id">
                            {{ category.title }}
                          </option>
                        </Field>
                        <ErrorMessage class='text-danger' name='category' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label class='typo__label'>Sub category</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="subCategory"
                          placeholder="Sub category"
                          v-model="VideoModel.sub_category_id_1"
                        >
                          <option disabled selected hidden value="">
                            Select Sub category
                          </option>
                          <option v-for="subCategory in subCategoryData1" :key="subCategory.id" :value="subCategory.id">
                            {{ subCategory.title }}
                          </option>
                        </Field>
                        <ErrorMessage class='text-danger' name='subCategory' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label class='typo__label'>Brand</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="brand"
                          placeholder="Brand"
                          v-model="VideoModel.brand_id_1"
                        >
                          <option disabled selected hidden value="">
                            Select Brand
                          </option>
                          <option v-for="brand in brandOptions" :key="brand.brand_id" :value="brand.brand_id">
                            {{ brand.brand_name }}
                          </option>
                        </Field>
                        <ErrorMessage class='text-danger' name='brand' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label class='typo__label'>Model</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="model"
                          placeholder="Model"
                          v-model="VideoModel.model_id_1"
                        >
                          <option disabled selected hidden value="">
                            Select Model
                          </option>
                          <option v-for="model in models1" :key="model.product_id" :value="model.product_id">
                            {{ model.value }}
                          </option>
                        </Field>
                        <ErrorMessage class='text-danger' name='brand' />
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-12 d-flex justify-content-center">
                        <hr class="break-line">
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label class='typo__label'>Category</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="category2"
                          placeholder="Category"
                          v-model="VideoModel.category_id_2"
                        >
                          <option disabled selected hidden value="">
                            Select Category
                          </option>
                          <option v-for="category in categoryData" :key="category.id" :value="category.id">
                            {{ category.title }}
                          </option>
                        </Field>
                        <ErrorMessage class='text-danger' name='category2' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label class='typo__label'>Sub category</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="subCategory2"
                          placeholder="Sub category"
                          v-model="VideoModel.sub_category_id_2"
                        >
                          <option disabled selected hidden value="">
                            Select Sub category
                          </option>
                          <option v-for="subCategory in subCategoryData2" :key="subCategory.id" :value="subCategory.id">
                            {{ subCategory.title }}
                          </option>
                        </Field>
                        <ErrorMessage class='text-danger' name='subCategory2' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label class='typo__label'>Brand</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="brand2"
                          placeholder="Brand"
                          v-model="VideoModel.brand_id_2"
                        >
                          <option disabled selected hidden value="">
                            Select Brand
                          </option>
                          <option v-for="brand in brandOptions" :key="brand.brand_id" :value="brand.brand_id">
                            {{ brand.brand_name }}
                          </option>
                        </Field>
                        <ErrorMessage class='text-danger' name='brand2' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label class='typo__label'>Model</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="model2"
                          placeholder="Model"
                          v-model="VideoModel.model_id_2"
                        >
                          <option disabled selected hidden value="">
                            Select Model
                          </option>
                          <option v-for="model in models2" :key="model.product_id" :value="model.product_id">
                            {{ model.value }}
                          </option>
                        </Field>
                        <ErrorMessage class='text-danger' name='model2' />
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-12 d-flex justify-content-center">
                        <hr class="break-line">
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label class='typo__label'>Category</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="category3"
                          placeholder="Category"
                          v-model="VideoModel.category_id_3"
                        >
                          <option disabled selected hidden value="">
                            Select Category
                          </option>
                          <option v-for="category in categoryData" :key="category.id" :value="category.id">
                            {{ category.title }}
                          </option>
                        </Field>
                        <ErrorMessage class='text-danger' name='category3' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label class='typo__label'>Sub category</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="subCategory3"
                          placeholder="Sub category"
                          v-model="VideoModel.sub_category_id_3"
                        >
                          <option disabled selected hidden value="">
                            Select Sub category
                          </option>
                          <option v-for="subCategory in subCategoryData3" :key="subCategory.id" :value="subCategory.id">
                            {{ subCategory.title }}
                          </option>
                        </Field>
                        <ErrorMessage class='text-danger' name='subCategory3' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label class='typo__label'>Brand</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="brand3"
                          placeholder="Brand"
                          v-model="VideoModel.brand_id_3"
                        >
                          <option disabled selected hidden value="">
                            Select Brand
                          </option>
                          <option v-for="brand in brandOptions" :key="brand.brand_id" :value="brand.brand_id">
                            {{ brand.brand_name }}
                          </option>
                        </Field>
                        <ErrorMessage class='text-danger' name='brand3' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label class='typo__label'>Model</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="model3"
                          placeholder="Model"
                          v-model="VideoModel.model_id_3"
                        >
                          <option disabled selected hidden value="">
                            Select Model
                          </option>
                          <option v-for="model in models3" :key="model.product_id" :value="model.product_id">
                            {{ model.value }}
                          </option>
                        </Field>
                        <ErrorMessage class='text-danger' name='model3' />
                      </div>
                    </div>
                    <div class='form-group col-12'>
                      <div class='form-check'>
                        <div class='checkbox'>
                          <input
                            type='checkbox'
                            id='checkbox'
                            class='form-check-input'
                            checked
                            @change="bindStatusData"
                            v-model="isActive"
                          />
                          <label for='checkbox'>Active</label>
                        </div>
                      </div>
                    </div>
                    <div class='col-12 d-flex justify-content-end'>
                      <button
                        class='btn btn-primary me-1 mb-1' v-show="VideoModel.id==='undefined' || VideoModel.id===0"
                        @click='handleSubmit($event, createOrUpdateVideo)'
                      >
                        Create
                      </button>
                      <button
                        v-show="VideoModel.id!=='undefined' && VideoModel.id!==0"
                        class='btn btn-primary me-1 mb-1'
                        @click='handleSubmit($event, updateVideo)'
                      >
                        Update
                      </button>
                      <button
                        class='btn btn-secondary me-1 mb-1'
                        @click='reset'
                      >
                        Reset
                      </button>
                      <button
                        class='btn btn-danger me-1 mb-1'
                        @click='openAddForm = false'
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- // Basic multiple Column Form section end -->
    <!-- Basic Tables start -->
    <section class='section'>
      <div class='card'>
        <div class='card-header'>
          <button class='badge bg-success' @click='loadDataTable' v-show="false">
            <i class="fa fa-magic"></i>
          </button>
        </div>
        <div class='card-body'>
          <table class='table table-striped' id='table1'>
            <thead>
            <tr>
              <th style="width: 7%">#</th>
              <th style="width: 38%">Title</th>
              <th style="width: 18%">Thumbnail Image</th>
              <th style="width: 12%">Upload Date</th>
              <th style="width: 10%">Duration</th>
              <th style="width: 15%">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for='(video, index) in displayVideos' :key='video.id' >
              <td>{{ index+1 }}</td>
              <td>{{ video.title }}</td>
              <td><img class="img" width="120" :src="video.thumbnail" :alt="video.title.toLocaleUpperCase()"></td>
              <td>{{ video.upload_date}}</td>
              <td>{{ video.duration}}</td>
              <td>
                <button class='badge bg-danger mr-5' @click="deleteVideo(video.id)">Delete</button>
                <button class='badge bg-primary ml-5' @click="editVideo(video)">Edit</button>
              </td>
            </tr>
            <tr v-if='!displayVideos.length'>
              <td colspan='7' class='text-center'>No entries found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <!-- Basic Tables end -->

    <div class="overlay" v-if="modalStatus">
      <div class="filter-section">
        <button class="close btn-close" @click="modalToggle(false)"></button>
        <div class="row filter-input-section">
          <div class="col-12">
            <div class="form-group">
              <Field type="text" class="form-control" v-model="filter.searchText" name="search" placeholder="Search by title" />
            </div>
          </div>
          <div class='col-12'>
            <div class="form-group">
              <label for="first-name-column">Category</label>
              <Field
                as="select"
                class="form-control"
                name="category"
                placeholder="category"
                v-model="filter.category"
              >
                <option disabled selected hidden value="">
                  Select Category
                </option>
                <option v-for="category in categoryData" :key="category.id" :value="category.id">
                  {{ category.title }}
                </option>
              </Field>
            </div>
          </div>
          <div class='col-12'>
            <div class="form-group">
              <label for="first-name-column">Brand</label>
              <Field
                as="select"
                class="form-control"
                name="brand"
                placeholder="Brand"
                v-model="filter.brand"
              >
                <option disabled selected hidden value="">
                  Select Brand
                </option>
                <option v-for="brand in brandOptions" :key="brand.brand_id" :value="brand.brand_id">
                  {{ brand.brand_name }}
                </option>
              </Field>
            </div>
          </div>
          <div class="col-12 text-right">
            <button class="btn btn-sm btn-success" @click="filterList">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.chip{
  margin: 0px 10px 5px 0px;
}
.small-text{
  display: block;
  font-size: 12px;
  color: #555555;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.filter-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 4px;
  width: 550px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}
.filter-input-section{
  width: 90%;
  margin-top: 10px;
}
.btn-close{
  position: absolute;
  top: 14px;
  right: 14px;
  font-size: 16px;
  cursor: pointer;
}
.break-line{
  width: 300px;
  height: 1px;
  border: 0;
}
</style>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate'
import _ from 'lodash'

import VideoModel from '../../models/VideoModel'
import brandService from '../../services/brand.service'
import FileService from '../../services/file.service'
import VideoService from '../../services/video.service'
import VehicleTypeService from '@/services/vehicleType.service'
import SettingsService from '../../services/settings.service'

export default {
  name: 'Video',
  data () {
    return {
      VideoModel: new VideoModel(),
      itemBlueprints: [],
      videos: [],
      displayVideos: [],
      openAddForm: false,
      brandOptions: [],
      brandObject: {},
      models1: [],
      models2: [],
      models3: [],
      thumbnailFile: [],
      editImageURL: '',
      categoryData: [],
      subCategoryData1: [],
      subCategoryData2: [],
      subCategoryData3: [],
      modalStatus: false,
      filter: {
        category: 0,
        searchText: '',
        brand: -1
      },
      isActive: true
    }
  },
  components: { Form, Field, ErrorMessage },
  watch: {
    'VideoModel.category_id_1': function () {
      this.getSubCategories(this.VideoModel.category_id_1, 1)
    },
    'VideoModel.category_id_2': function () {
      this.getSubCategories(this.VideoModel.category_id_2, 2)
    },
    'VideoModel.category_id_3': function () {
      this.getSubCategories(this.VideoModel.category_id_3, 3)
    },
    'VideoModel.brand_id_1': function () {
      this.getModels(this.VideoModel.brand_id_1, 1)
    },
    'VideoModel.brand_id_2': function () {
      this.getModels(this.VideoModel.brand_id_2, 2)
    },
    'VideoModel.brand_id_3': function () {
      this.getModels(this.VideoModel.brand_id_3, 3)
    }
  },
  mounted () {
    this.getBrands()
    this.getVideos()
    this.getVehicleCategoryWiseSubcategory()
  },
  methods: {
    bindStatusData () {
      this.VideoModel.status = this.isActive === true ? 1 : 0
    },
    bindDefaultSubscribedURL (event) {
      const isChecked = event.target.checked
      if (isChecked) {
        SettingsService.getSettings().then(
          response => {
            if (response.data.status) {
              this.VideoModel.subscribe_url = response.data.objects.settings[0].youtubeUrl
            }
          })
      } else {
        this.VideoModel.subscribe_url = ''
      }
    },
    getVehicleCategoryWiseSubcategory () {
      this.categoryData = []
      VehicleTypeService.getVehicleTypesWithCategoriesAndSubCategories().then(
        response => {
          if (response.data.status) {
            this.categoryData = response.data.objects.vehicleTypes[0].categories
          }
        }
      )
    },
    getSubCategories (catId, serial) {
      if (this.categoryData.length > 0 && catId > 0) {
        this[`subCategoryData${serial}`] = this.categoryData.filter(cat => cat.id === catId)[0].sub_categories
      }
    },
    onThumbnailUpload (event) {
      const files = event.target.files ? event.target.files : null
      this.thumbnailFile = []
      if (!files.length) { return }
      FileService.prepareFileForUpload(files, this.VideoModel.id, 'video').then(
        response => {
          this.thumbnailFile = response
          this.VideoModel.thumbnail_photo_file = response
        },
        error => {
          console.log(error)
          alert('Error while trying to process uploaded files')
        }
      )
    },
    getBrands () {
      brandService.getBrands().then((response) => {
        if (response.data.status) {
          this.brandOptions = _.map(response.data.objects.brands, function (brand) {
            return {
              brand_id: brand.id,
              brand_name: brand.title
            }
          })
        }
      })
    },
    getModels (brandId, serial) {
      this[`models${serial}`] = []
      brandService.getModelByBrandId(brandId).then(
        response => {
          if (response.data.status) {
            this[`models${serial}`] = response.data.objects.models
          }
        }
      )
    },
    getVideos () {
      this.videos = []
      VideoService.getVideos().then(
        response => {
          if (response.data.status) {
            this.itemBlueprints = _.map(response.data.objects.videos, function (p) {
              return new VideoModel().load(p)
            })
            this.videos = _.map(response.data.objects.videos, function (b) {
              return new VideoModel().load(b)
            })
            this.displayVideos = _.map(response.data.objects.videos, function (b) {
              return new VideoModel().load(b)
            })
          }
        }
      )
    },
    reset () {
      this.VideoModel = new VideoModel()
      this.thumbnailFile = []
      document.getElementById('input_file').value = ''
      document.getElementById('checkbox1').checked = false
    },
    createOrUpdateVideo () {
      const vm = this
      VideoService.createVideo(this.VideoModel).then(
        response => {
          if (response.data.status) {
            vm.videos.push(new VideoModel().load(response.data.objects.video))
            this.getVideos()
            vm.reset()
          }
        }
      )
    },
    editVideo (VideoModel) {
      this.VideoModel = VideoModel
      this.editImageURL = this.VideoModel.thumbnail
      this.isActive = this.VideoModel.status
      this.openAddForm = true
    },
    updateVideo () {
      const vm = this
      VideoService.updateVideo(this.VideoModel).then(
        response => {
          if (response.data.status) {
            const videos = _.find(vm.videos, function (p) { return p.id === vm.VideoModel.id })
            videos.load(response.data.objects.video)
            vm.reset()
            this.openAddForm = false
            this.getVideos()
          }
        }
      )
    },
    deleteVideo (videoId) {
      const vm = this
      VideoService.deleteVideo(videoId).then(
        response => {
          if (response.data.status) {
            vm.videos = _.filter(vm.videos, function (p) { return p.id !== videoId })
            vm.reset()
            this.openAddForm = false
            this.getVideos()
          }
        }
      )
    },
    modalToggle (status) {
      this.modalStatus = status
      this.filter.category = 0
      this.filter.searchText = ''
      this.filter.brand = -1
    },
    filterList: function () {
      const vm = this
      if (vm.filter.category !== 0) {
        vm.displayVideos = vm.videos.filter(video => {
          return (video.category_id_1 === vm.filter.category || video.category_id_2 === vm.filter.category || video.category_id_3 === vm.filter.category)
        })
      }
      if (vm.filter.brand !== -1) {
        vm.displayVideos = vm.videos.filter(video => {
          return (video.brand_id_1 === vm.filter.brand || video.brand_id_2 === vm.filter.brand || video.brand_id_3 === vm.filter.brand)
        })
      }
      const key = vm.filter.searchText.trim().toLowerCase()
      if (key) {
        vm.displayVideos = vm.videos.filter(item => item.title.toLowerCase().includes(key))
      }
      this.modalToggle(false)
    }
  },
  computed: {
  }
}
</script>
