import UtilityService from '../services/utility.service'

export default class ReviewModel {
  constructor () {
    this.id = 0
    this.name = ''
    this.phone = ''
    this.rating = ''
    this.title = ''
    this.details = ''
    this.review_photo_files = []
    this.brand_id = ''
    this.brand_name = ''
    this.model = ''
    this.model_name = ''
    this.status = false
    this.created_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
  }

  load (data) {
    // console.log('Data: ', data)
    this.id = data.id
    this.name = data.name
    this.phone = data.phone
    this.rating = data.rating
    this.title = data.title
    this.details = data.details
    this.review_photo_files = data.image_url !== '' ? data.image_url.split('##**') : []
    this.brand_id = data.brand_id ?? ''
    this.brand_name = data.brand_name ?? ''
    this.model = data.model ?? ''
    this.model_name = data.model_name ?? ''
    this.status = data.status === 1
    this.created_at = UtilityService.formatDateTime(
      data.created_at,
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      data.updated_at,
      'MM/DD/YY hh:mm A'
    )
    return this
  }
}
