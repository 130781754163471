import Config from './config'

const api = Config.api

const vehicleSubCategoryAPIRoot = api.BASE_URL + '/vehicleSubCategory'

class VehicleSubCategoryService {
  createVehicleSubCategory (vehicleSubCategoryModel) {
    return api.postCall(vehicleSubCategoryAPIRoot, vehicleSubCategoryModel, api.ShowAlert, api.ShowAlert)
  }

  getVehicleSubCategories () {
    return api.getCall(vehicleSubCategoryAPIRoot, api.DoNothing, api.ShowAlert)
  }

  updateVehicleSubCategory (vehicleSubCategoryModel) {
    return api.putCall(vehicleSubCategoryAPIRoot + '/' + vehicleSubCategoryModel.id, vehicleSubCategoryModel, api.ShowAlert, api.ShowAlert)
  }

  deleteVehicleSubCategory (subCategoryId) {
    return api.deleteCall(vehicleSubCategoryAPIRoot + '/' + subCategoryId, api.ShowAlert, api.ShowAlert)
  }
}
export default new VehicleSubCategoryService()
