import UtilityService from '../services/utility.service'
export default class InquiryModel {
  constructor () {
    this.id = 0
    this.name = ''
    this.phone = ''
    this.district = ''
    this.brand = ''
    this.model = ''
    this.ip = ''
    this.category = ''
    this.subcategory = ''
    this.contact_demand = ''
    this.number_of_submit = ''
    this.businessTitle = ''
    this.businessPhone = ''
    this.businessDistrict = ''
    this.businessAddress = ''
    this.commentStatus = false
    this.comment = ''
    this.comment_updated_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
    this.created_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
  }

  load (data) {
    this.id = data.id
    this.name = data.name
    this.phone = data.phone
    this.district = data.district
    this.brand = data.brand
    this.model = data.model
    this.ip = data.ip
    this.category = data.category
    this.subcategory = data.subcategory
    this.contact_demand = data.contact_demand
    this.number_of_submit = data.number_of_submit
    this.businessTitle = data.businessTitle
    this.businessPhone = data.businessPhone
    this.businessDistrict = data.businessDistrict
    this.businessAddress = data.businessAddress
    this.commentStatus = data.comment && data.comment.length > 0 ? 'true' : 'false'
    this.comment = data.comment
    this.comment_updated_at = data.comment_updated_at ? UtilityService.convertToBDTimeZone(
      data.comment_updated_at
    ) : ''
    this.created_at = UtilityService.formatDateTime(
      data.created_at,
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      data.updated_at,
      'MM/DD/YY hh:mm A'
    )
    return this
  }
}
