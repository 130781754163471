import UtilityService from '../services/utility.service'

export default class AdsModel {
  constructor () {
    this.id = 0
    this.condition = ''
    this.brand_id = ''
    this.brand_name = ''
    this.model_id = ''
    this.model_name = ''
    this.model_year = '0'
    this.reg_year = '0'
    this.run = ''
    this.body_type = ''
    this.description = ''
    this.price = ''
    this.ad_photo_files = []
    this.name = ''
    this.phone = ''
    this.district_id = '0'
    this.status = false
    this.created_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
  }

  load (data) {
    this.id = data.id
    this.condition = data.condition
    this.brand_id = data.brand_id ?? ''
    this.brand_name = data.brand_name ?? ''
    this.model_id = data.model_id ?? ''
    this.model_name = data.model_name ?? ''
    this.model_year = data.model_year
    this.reg_year = data.reg_year
    this.run = data.run
    this.body_type = data.body_type
    this.description = data.description
    this.price = data.price
    this.ad_photo_files = data.image_url !== '' ? data.image_url.split('##**') : []
    this.name = data.name
    this.phone = data.phone
    this.district_id = data.district_id
    this.status = data.status === 1
    this.created_at = UtilityService.formatDateTime(
      data.created_at,
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      data.updated_at,
      'MM/DD/YY hh:mm A'
    )
    return this
  }
}
