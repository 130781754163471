import Config from './config'

const api = Config.api

const adsSettingsAPIRoot = api.BASE_URL + '/flyer'

class AdsSettingsService {
  createAds (AdsSettingsModel) {
    return api.postCall(adsSettingsAPIRoot, AdsSettingsModel, api.ShowAlert, api.ShowAlert)
  }

  getAds () {
    return api.getCall(adsSettingsAPIRoot, api.DoNothing, api.ShowAlert)
  }

  updateAds (AdsSettingsModel) {
    return api.putCall(adsSettingsAPIRoot + '/' + AdsSettingsModel.id, AdsSettingsModel, api.ShowAlert, api.ShowAlert)
  }

  deleteAds (adsId) {
    return api.deleteCall(adsSettingsAPIRoot + '/' + adsId, api.ShowAlert, api.ShowAlert)
  }
}
export default new AdsSettingsService()
