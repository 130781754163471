import Config from './config'
var api = Config.api

const reviewAPIRoot = api.BASE_URL + '/review'

class ReviewService {
  createReview (ReviewModel) {
    return api.postCall(
      reviewAPIRoot,
      ReviewModel,
      api.ShowAlert,
      api.ShowAlert
    )
  }

  getReviews () {
    return api.getCall(reviewAPIRoot, api.DoNothing, api.ShowAlert)
  }

  getActiveReviews (status) {
    return api.getCall(reviewAPIRoot + '/' + status, api.DoNothing, api.ShowAlert)
  }

  getReviewById (reviewId) {
    return api.getCall(reviewAPIRoot + '/single/' + reviewId, api.DoNothing, api.ShowAlert)
  }

  updateReview (ReviewModel) {
    return api.putCall(reviewAPIRoot + '/' + ReviewModel.id, ReviewModel, api.ShowAlert, api.ShowAlert)
  }

  deleteReview (reviewId) {
    return api.deleteCall(reviewAPIRoot + '/' + reviewId, api.ShowAlert, api.ShowAlert)
  }
}
export default new ReviewService()
