<template>
  <div class='page-heading'>
    <div class='page-title'>
      <div class='row'>
        <div class='col-12 col-md-6 order-md-1 order-last'>
          <h3>All Vehicle Types</h3>
          <p class='text-subtitle text-muted'>
            View your added vehicle types and add new ones
          </p>
        </div>
        <div class='col-12 col-md-6 order-md-2 order-first'>
          <nav
            aria-label='breadcrumb'
            class='breadcrumb-header float-start float-lg-end'
          >
            <ol class='breadcrumb'>
              <li class='breadcrumb-item'>
                <router-link :to="{ name: 'home' }">Dashboard</router-link>
              </li>
              <li class='breadcrumb-item active' aria-current='page'>
                All Vehicle Types
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
    <!-- Basic Tables start -->
    <!--    <section class="section">-->
    <!--      <div class="card">-->
    <!--        <div class="card-header">-->
    <!--          Bulk upload from excel-->
    <!--        </div>-->
    <!--        <div class="card-body">-->
    <!--          <ExcelTool @ExcelProductsUpload="OnExcelItemsUpload" :itemType="'business'" :products="itemBlueprints" :payload = "payload"></ExcelTool>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--    </section>-->
    <!-- // Basic multiple Column Form section start -->
    <section id='multiple-column-form'>
      <div class='row match-height'>
        <div class='col-12'>
          <div class='card'>
            <div class='card-header'>
              <button
                @click='openAddForm = !openAddForm'
                class='card-title btn btn-outline-dark'
              >
                Add Vehicle Type
              </button>
            </div>
            <div v-show='openAddForm' class='card-content'>
              <div class='card-body'>
                <Form
                  class='form'
                  v-slot='{ handleSubmit }'
                  @submit.prevent='createVehicleType'
                  :validation-schema='ValidationSchema'
                >
                  <div class='row'>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Title</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='title'
                          placeholder='Vehicle Type Title'
                          v-model="VehicleTypeModel.title"
                        />
                        <ErrorMessage class='text-danger' name='title' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Title (In Bangla)</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='title_bangla'
                          placeholder='Vehicle Type Title (In Bangla)'
                          v-model="VehicleTypeModel.title_bangla"
                        />
                        <ErrorMessage class='text-danger' name='title_bangla' />
                      </div>
                    </div>
                    <div class='col-12 d-flex justify-content-end'>
                      <button
                        class='btn btn-primary me-1 mb-1' v-show="VehicleTypeModel.id==='undefined' || VehicleTypeModel.id===0"
                        @click='handleSubmit($event, createVehicleType)'
                      >
                        Create
                      </button>
                      <button
                        v-show="VehicleTypeModel.id!=='undefined' && VehicleTypeModel.id!==0"
                        class='btn btn-primary me-1 mb-1'
                        @click='handleSubmit($event, updateVehicleType)'
                      >
                        Update
                      </button>
                      <button
                        class='btn btn-secondary me-1 mb-1'
                        @click='reset'
                      >
                        Reset
                      </button>
                      <button
                        class='btn btn-danger me-1 mb-1'
                        @click='openAddForm = false'
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- // Basic multiple Column Form section end -->
    <!-- Basic Tables start -->
    <section class='section'>
      <div class='card'>
        <div class='card-header'>
          <button class='badge bg-success' @click='loadDataTable' v-show="false">
            <i class="fa fa-magic"></i>
          </button>
        </div>
        <div class='card-body'>
          <table class='table table-striped' id='table1'>
            <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Title (Bangla)</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for='(type, index) in vehicleTypes' :key='type.id' >
              <td>{{ index+1 }}</td>
              <td>{{ type.title }}</td>
              <td>{{ type.title_bangla }}</td>
              <td>
                <button class='badge bg-danger mr-5' @click="deleteVehicleType(type.id)">Delete</button>
                <button class='badge bg-primary ml-5' @click="editVehicleType(type)">Edit</button>
              </td>
            </tr>
            <tr v-if='!vehicleTypes.length'>
              <td colspan='4' class='text-center'>No entries found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <!-- Basic Tables end -->
  </div>
</template>

<style src='vue-multiselect/dist/vue-multiselect.css'></style>
<style scoped>
.small-text{
  display: block;
  font-size: 12px;
  color: #555555;
}
</style>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate'
import _ from 'lodash'

import VehicleTypeModel from '../../models/VehicleTypeModel'
import VehicleTypeService from '../../services/vehicleType.service'

export default {
  name: 'VehicleType',
  data () {
    return {
      // ValidationSchema: ValidationService.createUpdateSpecification,
      VehicleTypeModel: new VehicleTypeModel(),
      itemBlueprints: [],
      openAddForm: false,
      vehicleTypes: []
    }
  },
  components: { Form, Field, ErrorMessage },
  watch: {
  },
  mounted () {
    this.getVehicleTypes()
  },
  methods: {
    loadDataTable: async () => {
      // eslint-disable-next-line no-undef
      setTimeout($GLOBAL.loadTable('#table1'), 100000)
    },
    getVehicleTypes () {
      VehicleTypeService.getVehicleTypes().then((response) => {
        if (response.data.status) {
          this.vehicleTypes = _.map(response.data.objects.vehicleTypes, function (b) {
            return new VehicleTypeModel().load(b)
          })
        }
      })
    },
    reset () {
      this.VehicleTypeModel = new VehicleTypeModel()
    },
    createVehicleType () {
      const vm = this
      VehicleTypeService.createVehicleType(this.VehicleTypeModel).then(
        response => {
          if (response.data.status) {
            vm.vehicleTypes.push(new VehicleTypeModel().load(response.data.objects.vehicleType))
            this.getVehicleTypes()
            vm.reset()
          }
        }
      )
    },
    editVehicleType (VehicleTypeModel) {
      this.VehicleTypeModel = VehicleTypeModel
      this.openAddForm = true
    },
    updateVehicleType () {
      const vm = this
      VehicleTypeService.updateVehicleType(this.VehicleTypeModel).then(
        response => {
          if (response.data.status) {
            const vehicleTypes = _.find(vm.vehicleTypes, function (p) { return p.id === vm.VehicleTypeModel.id })
            vehicleTypes.load(response.data.objects.vehicleType)
            vm.reset()
            this.openAddForm = false
            this.getVehicleTypes()
          }
        }
      )
    },
    deleteVehicleType (typeId) {
      const vm = this
      VehicleTypeService.deleteVehicleType(typeId).then(
        response => {
          if (response.data.status) {
            vm.vehicleTypes = _.filter(vm.vehicleTypes, function (p) { return p.id !== typeId })
            vm.reset()
            this.openAddForm = false
            this.getVehicleTypes()
          }
        }
      )
    }
  },
  computed: {
  }
}
</script>
