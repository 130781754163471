<template>
  <div class='page-heading'>
    <div class='page-title'>
      <div class='row'>
        <div class='col-12 col-md-6 order-md-1 order-last'>
          <h3>All Frequently Asked Questions</h3>
          <p class='text-subtitle text-muted'>
            View your added Frequently Asked Questions and add new ones
          </p>
        </div>
        <div class='col-12 col-md-6 order-md-2 order-first'>
          <nav
            aria-label='breadcrumb'
            class='breadcrumb-header float-start float-lg-end'
          >
            <ol class='breadcrumb'>
              <li class='breadcrumb-item'>
                <router-link :to="{ name: 'home' }">Dashboard</router-link>
              </li>
              <li class='breadcrumb-item active' aria-current='page'>
                All Frequently Asked Questions
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <section id='multiple-column-form'>
      <div class='row match-height'>
        <div class='col-12'>
          <div class='card'>
            <div class='card-header'>
              <button
                @click='openAddForm = !openAddForm'
                class='card-title btn btn-outline-dark'
              >
                Add FAQ
              </button>
            </div>
            <div v-show='openAddForm' class='card-content'>
              <div class='card-body'>
                <Form
                  class='form'
                  v-slot='{ handleSubmit }'
                  @submit.prevent='createFaq'
                  :validation-schema='ValidationSchema'
                >
                  <div class='row'>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Question</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='question'
                          placeholder='Question'
                          v-model="FaqModel.question"
                        />
                        <ErrorMessage class='text-danger' name='question' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Question (In Bangla)</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='question_bangla'
                          placeholder='Question (In Bangla)'
                          v-model="FaqModel.question_bangla"
                        />
                        <ErrorMessage class='text-danger' name='question_bangla' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Answer</label>
                        <textarea class='form-control faq-textarea' name='answer'  placeholder='Answer' v-model="FaqModel.answer">
                        </textarea>
                        <ErrorMessage class='text-danger' name='answer' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Answer (In Bangla)</label>
                        <textarea class='form-control faq-textarea' name='answer_bangla'  placeholder='Answer (In Bangla)' v-model="FaqModel.answer_bangla">
                        </textarea>
                        <ErrorMessage class='text-danger' name='answer_bangla' />
                      </div>
                    </div>
                    <div class='form-group col-12'>
                      <div class='form-check'>
                        <div class='checkbox'>
                          <input
                            type='checkbox'
                            id='checkbox5'
                            class='form-check-input'
                            checked
                            v-model="FaqModel.status"
                          />
                          <label for='checkbox5'>Active</label>
                        </div>
                      </div>
                    </div>
                    <div class='col-12 d-flex justify-content-end'>
                      <button
                        class='btn btn-primary me-1 mb-1' v-show="FaqModel.id==='undefined' || FaqModel.id===0"
                        @click='handleSubmit($event, createFaq)'
                      >
                        Create
                      </button>
                      <button
                        v-show="FaqModel.id!=='undefined' && FaqModel.id!==0"
                        class='btn btn-primary me-1 mb-1'
                        @click='handleSubmit($event, updateFaq)'
                      >
                        Update
                      </button>
                      <button
                        class='btn btn-secondary me-1 mb-1'
                        @click='reset'
                      >
                        Reset
                      </button>
                      <button
                        class='btn btn-danger me-1 mb-1'
                        @click='openAddForm = false'
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- // Basic multiple Column Form section end -->
    <!-- Basic Tables start -->
    <section class='section'>
      <div class='card'>
        <div class='card-header'>
          <button class='badge bg-success' @click='loadDataTable' v-show="false">
            <i class="fa fa-magic"></i>
          </button>
        </div>
        <div class='card-body'>
          <table class='table table-striped' id='table1'>
            <thead>
            <tr>
              <th>#</th>
              <th>Question</th>
              <th>Question (Bangla)</th>
              <th>Answer</th>
              <th>Answer (Bangla)</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for='(faq, index) in faqs' :key='faq.id' >
              <td>{{ index+1 }}</td>
              <td>{{ faq.question }}</td>
              <td>{{ faq.question_bangla }}</td>
              <td>{{ faq.answer }}</td>
              <td>{{ faq.answer_bangla }}</td>
              <td>
                <button class='badge bg-danger mr-5' @click="deleteFaq(faq.id)">Delete</button>
                <button class='badge bg-primary ml-5' @click="editFaq(faq)">Edit</button>
              </td>
            </tr>
            <tr v-if='!faqs.length'>
              <td colspan='6' class='text-center'>No entries found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <!-- Basic Tables end -->
  </div>
</template>

<style src='vue-multiselect/dist/vue-multiselect.css'></style>
<style scoped>
.small-text{
  display: block;
  font-size: 12px;
  color: #555555;
}
.faq-textarea{
  height: 200px;
}
</style>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate'
import _ from 'lodash'

import FaqModel from '../../models/FaqModel'
import FaqService from '../../services/faq.service'

export default {
  name: 'Faq',
  data () {
    return {
      // ValidationSchema: ValidationService.createUpdateSpecification,
      FaqModel: new FaqModel(),
      itemBlueprints: [],
      openAddForm: false,
      faqs: []
    }
  },
  components: { Form, Field, ErrorMessage },
  watch: {
  },
  mounted () {
    this.getFaqs()
  },
  methods: {
    loadDataTable: async () => {
      // eslint-disable-next-line no-undef
      setTimeout($GLOBAL.loadTable('#table1'), 100000)
    },
    getFaqs () {
      FaqService.getFaqs().then((response) => {
        if (response.data.status) {
          this.faqs = _.map(response.data.objects.faqs, function (b) {
            return new FaqModel().load(b)
          })
        }
      })
    },
    reset () {
      this.FaqModel = new FaqModel()
    },
    createFaq () {
      const vm = this
      FaqService.createFaq(this.FaqModel).then(
        response => {
          if (response.data.status) {
            vm.faqs.push(new FaqModel().load(response.data.objects.faq))
            this.getFaqs()
            vm.reset()
          }
        }
      )
    },
    editFaq (FaqModel) {
      this.FaqModel = FaqModel
      this.openAddForm = true
    },
    updateFaq () {
      const vm = this
      FaqService.updateFaq(this.FaqModel).then(
        response => {
          if (response.data.status) {
            const faqs = _.find(vm.faqs, function (p) { return p.id === vm.FaqModel.id })
            faqs.load(response.data.objects.faq)
            vm.reset()
            this.openAddForm = false
            this.getFaqs()
          }
        }
      )
    },
    deleteFaq (faqId) {
      const vm = this
      FaqService.deleteFaq(faqId).then(
        response => {
          if (response.data.status) {
            vm.faqs = _.filter(vm.faqs, function (p) { return p.id !== faqId })
            vm.reset()
            this.openAddForm = false
            this.getFaqs()
          }
        }
      )
    }
  }
}
</script>
