<template>
  <div class='page-heading'>
    <div class='page-title'>
      <div class='row'>
        <div class='col-12 col-md-6 order-md-1 order-last'>
          <h3>All Reviews</h3>
          <p class='text-subtitle text-muted'>
            Check Review and Approve to show in client site
          </p>
        </div>
        <div class='col-12 col-md-6 order-md-2 order-first'>
          <nav
            aria-label='breadcrumb'
            class='breadcrumb-header float-start float-lg-end'
          >
            <ol class='breadcrumb'>
              <li class='breadcrumb-item'>
                <router-link :to="{ name: 'home' }">Dashboard</router-link>
              </li>
              <li class='breadcrumb-item active' aria-current='page'>
                All Reviews
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
    <!-- Basic Tables start -->
    <!--    <section class="section">-->
    <!--      <div class="card">-->
    <!--        <div class="card-header">-->
    <!--          Bulk upload from excel-->
    <!--        </div>-->
    <!--        <div class="card-body">-->
    <!--          <ExcelTool @ExcelProductsUpload="OnExcelItemsUpload" :itemType="'business'" :products="itemBlueprints" :payload = "payload"></ExcelTool>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--    </section>-->
    <!-- // Basic multiple Column Form section start -->
    <section id='multiple-column-form' v-show='openAddForm'>
      <div class='row match-height'>
        <div class='col-12'>
          <div class='card'>
            <div class='card-content'>
              <div class='card-body'>
                <Form
                  class='form'
                  v-slot='{ handleSubmit }'
                  @submit.prevent='updateReview'
                >
                  <div class='row'>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Name</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='name'
                          placeholder='Review Name'
                          v-model="ReviewModel.name"
                        />
                        <ErrorMessage class='text-danger' name='name' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Phone</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='phone'
                          placeholder='Review Phone'
                          v-model="ReviewModel.phone"
                        />
                        <ErrorMessage class='text-danger' name='phone' />
                      </div>
                    </div>
                    <div class='col-md-12 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Review Details</label>
                        <textarea class='form-control' name='details'  placeholder='Offer Summary' rows="8" v-model="ReviewModel.details">
                        </textarea>
                        <ErrorMessage class='text-danger' name='details' />
                      </div>
                    </div>
                    <div class='col-md-12 col-12'>
                      <div class="form-group">
                        <label for="imageUrl">Review Image</label>
                        <div v-if="ReviewModel.review_photo_files.length>0" class="image-container">
                          <template v-for="(reviewImage, index1) in reviewImages" :key="index1">
                            <div v-if="reviewImage!==''" class="image-section" >
                                <img class="img" width="140" :src="reviewImage" :alt="reviewImage" />
                                <i class="bi bi-x cross-icon" @click="removeReviewImage(reviewImage)"></i>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                    <div class='form-group col-12'>
                      <div class='form-check'>
                        <div class='checkbox'>
                          <input
                            type='checkbox'
                            id='checkbox5'
                            class='form-check-input'
                            checked
                            v-model="ReviewModel.status"
                          />
                          <label for='checkbox5'>Approve</label>
                        </div>
                      </div>
                    </div>
                    <div class='col-12 d-flex justify-content-end'>
                      <button
                        v-show="ReviewModel.id!=='undefined' && ReviewModel.id!==0"
                        class='btn btn-primary me-1 mb-1'
                        @click='handleSubmit($event, updateReview)'
                      >
                        Update
                      </button>
                      <button
                        class='btn btn-secondary me-1 mb-1'
                        @click='reset'
                      >
                        Reset
                      </button>
                      <button
                        class='btn btn-danger me-1 mb-1'
                        @click='openAddForm = false'
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- // Basic multiple Column Form section end -->
    <!-- Basic Tables start -->
    <section class='section'>
      <div class='card'>
        <div class='card-header d-flex justify-content-end'>
          <i class="bi bi-filter" style="font-size: 35px; display: contents; cursor: pointer" @click="modalToggle(true)"></i>
        </div>
        <div class='card-body'>
          <table class='table table-striped' id='table1'>
            <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Details</th>
              <th>Image</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for='(review, index) in displayReviews' :key='index' >
              <td>{{ index+1 }}</td>
              <td>{{ review.name }}</td>
              <td>{{ review.details}}</td>
              <td>
                <div v-if="review.review_photo_files.length>0">
                  <template v-for="(reviewImage, index1) in review.review_photo_files" :key="index1">
                    <img class="img mb-3" width="140" :src="reviewImage" :alt="reviewImage" />
                  </template>
                </div>
              </td>
              <td>
                <span :class="'badge chip' + (review.status ? ' bg-light-success' :' bg-light-danger')">{{review.status? "Approved" : "UnApproved"}}</span>
              </td>
              <td>
                <button class='badge bg-danger mr-5' @click="deleteReview(review.id)">Delete</button>
                <button class='badge bg-primary ml-5' @click="editReview(review)">Edit</button>
                <button class='badge bg-secondary ml-5' @click="approveOrUnapproveReview(review)">{{review.status? "UnApprove" : "Approve"}}</button>
              </td>
            </tr>
            <tr v-if='!displayReviews.length'>
              <td colspan='7' class='text-center'>No entries found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <!-- Basic Tables end -->
    <div class="overlay" v-if="modalStatus">
      <div class="filter-section">
        <button class="close btn-close" @click="modalToggle(false)"></button>
        <div class="row filter-input-section">
          <div class='col-12'>
            <div class="form-group">
              <label for="first-name-column">Brand</label>
              <Field
                as="select"
                class="form-control"
                name="brand"
                placeholder="brand"
                v-model="filter.brand"
              >
                <option disabled selected hidden value="">
                  Select brand
                </option>
                <option v-for="brand in brands" :key="brand.id" :value="brand.id">
                  {{ brand.title }}
                </option>
              </Field>
            </div>
          </div>
          <div class='col-12'>
            <div class="form-group">
              <label for="first-name-column">Model</label>
              <Field
                as="select"
                class="form-control"
                name="model"
                placeholder="Model"
                v-model="filter.model"
              >
                <option disabled selected hidden value="">
                  Select Model
                </option>
                <option v-for="model in models" :key="model.product_id" :value="model.product_id">
                  {{ model.value }}
                </option>
              </Field>
            </div>
          </div>
          <div class='col-12'>
            <div class="form-group">
              <label for="first-name-column">Status</label>
              <Field
                as="select"
                class="form-control"
                name="status"
                placeholder="Status"
                v-model="filter.status"
              >
                <option disabled selected hidden value="">
                  Select status
                </option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </Field>
            </div>
          </div>
          <div class="col-12 text-right">
            <button class="btn btn-sm btn-success" @click="filterList">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.image-container{
 display: flex;
}
.image-section{
  border: 1px solid #ddd;
  padding: 30px;
  border-radius: 4px;
  position: relative;
  margin-right: 15px;
}
.cross-icon{
  position: absolute;
  top: 3px;
  right: 12px;
  cursor: pointer;
}
.bi-x::before {
  font-size: 25px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.filter-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 4px;
  width: 550px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}
.filter-input-section{
  width: 90%;
  margin-top: 10px;
}
.btn-close{
  position: absolute;
  top: 14px;
  right: 14px;
  font-size: 16px;
  cursor: pointer;
}
</style>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate'
import _ from 'lodash'
import ReviewModel from '../../models/ReviewModel'
import ReviewService from '../../services/review.service'
import brandService from '@/services/brand.service'

export default {
  name: 'Review',
  data () {
    return {
      ReviewModel: new ReviewModel(),
      itemBlueprints: [],
      reviews: [],
      displayReviews: [],
      brands: [],
      models: [],
      reviewImages: [],
      openAddForm: false,
      editImageURL: '',
      modalStatus: false,
      filter: {
        brand: -1,
        model: 0,
        status: null
      }
    }
  },
  components: { Form, Field, ErrorMessage },
  watch: {
    'filter.brand': function () {
      this.getModels(this.filter.brand)
    }
  },
  mounted () {
    this.getReviews()
    this.getBrands()
  },
  methods: {
    removeReviewImage (image) {
      this.reviewImages = this.reviewImages.filter(imageString => {
        if (imageString !== image) return imageString
      })
      this.ReviewModel.review_photo_files = this.reviewImages
    },
    getReviews () {
      this.reviews = []
      ReviewService.getReviews().then(
        response => {
          if (response.data.status) {
            this.reviews = _.map(response.data.objects.reviews, function (b) {
              return new ReviewModel().load(b)
            })
            this.displayReviews = _.map(response.data.objects.reviews, function (b) {
              return new ReviewModel().load(b)
            })
          }
        }
      )
    },
    reset () {
      this.ReviewModel = new ReviewModel()
      this.reviewImages = []
    },
    editReview (ReviewModel) {
      this.ReviewModel = ReviewModel
      this.reviewImages = this.ReviewModel.review_photo_files
      this.openAddForm = true
    },
    approveOrUnapproveReview (ReviewModel) {
      this.ReviewModel = ReviewModel
      this.ReviewModel.status = !ReviewModel.status
      this.updateReview()
    },
    updateReview () {
      const vm = this
      ReviewService.updateReview(this.ReviewModel).then(
        response => {
          if (response.data.status) {
            const reviews = _.find(vm.reviews, function (p) { return p.id === vm.ReviewModel.id })
            reviews.load(response.data.objects.review)
            vm.reset()
            this.openAddForm = false
            this.getReviews()
          }
        }
      )
    },
    deleteReview (reviewId) {
      const vm = this
      ReviewService.deleteReview(reviewId).then(
        response => {
          if (response.data.status) {
            vm.reviews = _.filter(vm.reviews, function (p) { return p.id !== reviewId })
            vm.reset()
            this.openAddForm = false
            this.getReviews()
          }
        }
      )
    },
    modalToggle (status) {
      this.modalStatus = status
      this.filter.brand = -1
      this.filter.model = 0
      this.filter.status = null
    },
    getBrands () {
      this.brands = []
      this.models = []
      brandService.getBrands().then(
        response => {
          if (response.data.status) {
            this.brands = response.data.objects.brands
          }
        }
      )
    },
    getModels (brandId) {
      this.models = []
      brandService.getModelByBrandId(brandId).then(
        response => {
          if (response.data.status) {
            this.models = response.data.objects.models
          }
        }
      )
    },
    filterList: function () {
      const vm = this
      if (vm.filter.brand !== -1) {
        vm.displayReviews = vm.reviews.filter(item => parseInt(item.brand_id) === parseInt(vm.filter.brand))
      }
      if (vm.filter.model !== 0) {
        vm.displayReviews = vm.reviews.filter(item => parseInt(item.model) === parseInt(vm.filter.model))
      }
      if (vm.filter.status !== null) {
        console.log(vm.filter.status)
        const status = vm.filter.status === 'true'
        vm.displayReviews = vm.reviews.filter(item => item.status === status)
      }
      this.modalToggle(false)
    }
  },
  computed: {
  }
}
</script>
