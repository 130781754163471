import UtilityService from '../services/utility.service'
export default class SliderModel {
  constructor () {
    this.id = 0
    this.image_url = ''
    this.slider_photo_file = []
    this.priority = 0
    this.status = 1
    this.created_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
  }

  load (data) {
    this.id = data.id
    this.image_url = data.image_url ?? ''
    this.slider_photo_file = data.slider_photo_file ?? []
    this.priority = data.priority
    this.status = data.status
    this.created_at = UtilityService.formatDateTime(
      data.created_at,
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      data.updated_at,
      'MM/DD/YY hh:mm A'
    )
    return this
  }
}
