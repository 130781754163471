import Config from './config'

const api = Config.api

const vehicleCategoryAPIRoot = api.BASE_URL + '/vehicleCategory'

class VehicleCategoryService {
  createVehicleCategory (vehicleCategoryModel) {
    return api.postCall(vehicleCategoryAPIRoot, vehicleCategoryModel, api.ShowAlert, api.ShowAlert)
  }

  getVehicleCategories () {
    return api.getCall(vehicleCategoryAPIRoot, api.DoNothing, api.ShowAlert)
  }

  updateVehicleCategory (vehicleCategoryModel) {
    return api.putCall(vehicleCategoryAPIRoot + '/' + vehicleCategoryModel.id, vehicleCategoryModel, api.ShowAlert, api.ShowAlert)
  }

  deleteVehicleCategory (categoryId) {
    return api.deleteCall(vehicleCategoryAPIRoot + '/' + categoryId, api.ShowAlert, api.ShowAlert)
  }
}
export default new VehicleCategoryService()
