import Config from './config'

const api = Config.api

const faqAPIRoot = api.BASE_URL + '/faq'

class FaqService {
  createFaq (FaqModel) {
    return api.postCall(faqAPIRoot, FaqModel, api.ShowAlert, api.ShowAlert)
  }

  getFaqs () {
    return api.getCall(faqAPIRoot, api.DoNothing, api.ShowAlert)
  }

  updateFaq (FaqModel) {
    return api.putCall(faqAPIRoot + '/' + FaqModel.id, FaqModel, api.ShowAlert, api.ShowAlert)
  }

  deleteFaq (faqId) {
    return api.deleteCall(faqAPIRoot + '/' + faqId, api.ShowAlert, api.ShowAlert)
  }
}
export default new FaqService()
