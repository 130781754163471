<template>
  <div class='page-heading'>
    <div class='page-title'>
      <div class='row'>
        <div class='col-12 col-md-6 order-md-1 order-last'>
          <h3>All Offers</h3>
          <p class='text-subtitle text-muted'>
            View your added offers and add new ones
          </p>
        </div>
        <div class='col-12 col-md-6 order-md-2 order-first'>
          <nav
            aria-label='breadcrumb'
            class='breadcrumb-header float-start float-lg-end'
          >
            <ol class='breadcrumb'>
              <li class='breadcrumb-item'>
                <router-link :to="{ name: 'home' }">Dashboard</router-link>
              </li>
              <li class='breadcrumb-item active' aria-current='page'>
                All Offers
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
    <!-- Basic Tables start -->
<!--    <section class="section">-->
<!--      <div class="card">-->
<!--        <div class="card-header">-->
<!--          Bulk upload from excel-->
<!--        </div>-->
<!--        <div class="card-body">-->
<!--          <ExcelTool @ExcelProductsUpload="OnExcelItemsUpload" :itemType="'business'" :products="itemBlueprints" :payload = "payload"></ExcelTool>-->
<!--        </div>-->
<!--      </div>-->

<!--    </section>-->
    <!-- // Basic multiple Column Form section start -->
    <section id='multiple-column-form'>
      <div class='row match-height'>
        <div class='col-12'>
          <div class='card'>
            <div class='card-header d-flex justify-content-between align-items-center'>
              <button
                @click='openAddForm = !openAddForm'
                class='card-title btn btn-outline-dark'
              >
                Add Offer
              </button>
              <i class="bi bi-filter" style="font-size: 35px; display: contents; cursor: pointer" @click="modalToggle(true)"></i>
            </div>
            <div v-show='openAddForm' class='card-content'>
              <div class='card-body'>
                <Form
                  class='form'
                  v-slot='{ handleSubmit }'
                  @submit.prevent='createOrUpdateOffer'
                  :validation-schema='ValidationSchema'
                >
                  <div class='row'>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Title</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='title'
                          placeholder='Offer Title'
                          v-model="OfferModel.title"
                        />
                        <ErrorMessage class='text-danger' name='title' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Title (In Bangla)</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='title_bangla'
                          placeholder='Offer Title (In Bangla)'
                          v-model="OfferModel.title_bangla"
                        />
                        <ErrorMessage class='text-danger' name='title_bangla' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='first-name-column'>Vehicle Category</label>
                        <VueMultiselect
                          v-model="OfferModel.vehicleCategoryChilds"
                          tag-placeholder="Add this as new category"
                          placeholder="Search or add a category"
                          label="category_name"
                          track-by="category_id"
                          :options="vehicleCategoryOptions"
                          :multiple="true"
                          :taggable="true"
                          @tag="addVehicleCategory"
                        ></VueMultiselect>

                        <ErrorMessage class='text-danger' name='category' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Vehicle Type</label>
                            <Field
                              as="select"
                              class="form-control"
                              name="type"
                              placeholder="Vehicle Type"
                              v-model="selectedVehicleType"
                              @change="bindVehicleTypes"
                            >
                              <option disabled selected hidden value="">
                                Select Vehicle Type
                              </option>
                              <option v-for="vehicleType in vehicleTypesOptions" :key="vehicleType.id" :value="vehicleType.id">
                                {{ vehicleType.name }}
                              </option>
                            </Field>
                        <ErrorMessage class='text-danger' name='type' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Summary</label>
                        <textarea class='form-control' name='summary'  placeholder='Offer Summary' v-model="OfferModel.summary">
                        </textarea>
                        <ErrorMessage class='text-danger' name='summary' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Summary (In Bangla)</label>
                        <textarea class='form-control' name='summary_bangla'  placeholder='Offer Summary (In Bangla)' v-model="OfferModel.summary_bangla">
                        </textarea>
                        <ErrorMessage class='text-danger' name='summary_bangla' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class="form-group">
                        <label for="imageUrl">Offer Image</label>
                        <span class="small-text">Please upload landscape photo for better view.</span>
                        <Field @change="onOfferPhotoUpload" type="file" class="form-control" name="offerImage" placeholder="Offer Image" id="input_file"/>
                        <ErrorMessage class="text-danger" name="offerImage" />
                        <img class="img" width="200" style="margin-top: 10px" :src="editImageURL" :alt="offer">
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label class='typo__label'>Brand</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="brand"
                          placeholder="Brand"
                          v-model="selectedBrandId"
                          @change="bindBrands"
                        >
                          <option disabled selected hidden value="">
                            Select Brand
                          </option>
                          <option v-for="brand in brandOptions" :key="brand.brand_id" :value="brand.brand_id">
                            {{ brand.brand_name }}
                          </option>
                        </Field>
                        <ErrorMessage class='text-danger' name='brand' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Offer Start</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='offerStart'
                          placeholder='Offer Start date/time'
                          v-model="OfferModel.offer_start"
                        />
                        <ErrorMessage class='text-danger' name='offerStart' />
                      </div>
                    </div>

                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Offer End</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='offerEnd'
                          placeholder='Offer End date/time'
                          v-model="OfferModel.offer_end"
                        />
                        <ErrorMessage class='text-danger' name='offerEnd' />
                      </div>
                    </div>
                    <div class='col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Details</label>
                        <TinymceEditor ref="tinyMce" v-model:Body="OfferModel.details" :menubar="'visible'" name="content" class="mb-3"   v-validate="'required'" v-model.trim="OfferModel.details" :placeholder="'Write your page content here...'" ></TinymceEditor>
                        <ErrorMessage class='text-danger' name='details' />
                      </div>
                    </div>
                    <div class='col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Details (In Bangla)</label>
                        <TinymceEditor ref="tinyMce2" v-model:Body="OfferModel.details_bangla" :menubar="'visible'" name="content" class="mb-3"   v-validate="'required'" v-model.trim="OfferModel.details_bangla" :placeholder="'Write your page content here...'" ></TinymceEditor>
                        <ErrorMessage class='text-danger' name='details_bangla' />
                      </div>
                    </div>
                    <div class='form-group col-12'>
                      <div class='form-check'>
                        <div class='checkbox'>
                          <input
                            type='checkbox'
                            id='checkbox5'
                            class='form-check-input'
                            checked
                            v-model="OfferModel.status"
                          />
                          <label for='checkbox5'>Active</label>
                        </div>
                      </div>
                    </div>
                    <div class='col-12 d-flex justify-content-end'>
                      <button
                        class='btn btn-primary me-1 mb-1' v-show="OfferModel.id==='undefined' || OfferModel.id===0"
                        @click='handleSubmit($event, createOrUpdateOffer)'
                      >
                        Create
                      </button>
                      <button
                        v-show="OfferModel.id!=='undefined' && OfferModel.id!==0"
                        class='btn btn-primary me-1 mb-1'
                        @click='handleSubmit($event, updateOffer)'
                      >
                        Update
                      </button>
                      <button
                        class='btn btn-secondary me-1 mb-1'
                        @click='reset'
                      >
                        Reset
                      </button>
                      <button
                        class='btn btn-danger me-1 mb-1'
                        @click='openAddForm = false'
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- // Basic multiple Column Form section end -->
    <!-- Basic Tables start -->
    <section class='section'>
      <div class='card'>
        <div class='card-header'>
          <button class='badge bg-success' @click='loadDataTable' v-show="false">
            <i class="fa fa-magic"></i>
          </button>
        </div>
        <div class='card-body'>
          <table class='table table-striped' id='table1'>
            <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Category</th>
              <th>Type</th>
              <th>Brand</th>
              <th>Image</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for='(offer, index) in displayOffers' :key='offer.id' >
              <td>{{ index+1 }}</td>
              <td>{{ offer.title }}</td>
              <td>
                <p v-if="offer.vehicleCategoryChilds.length>0">
                    <span v-for="category in offer.vehicleCategoryChilds" :key='category.category_id'>
                      <span class="badge bg-light-info chip">
                        {{ category.category_name }}
                      </span>
                    </span>
                </p>
              </td>
              <td>{{ offer.vehicle_type}}</td>
              <td>{{ offer.brand}}</td>
              <td><img class="img" width="140" :src="offer.image_url" :alt="offer.title.toLocaleUpperCase()"></td>
              <td>
                <span :class="'badge chip' + (offer.status ? ' bg-light-success' :' bg-light-danger')">{{offer.status? "Active" : "InActive"}}</span>
              </td>
              <td>
                <button class='badge bg-danger mr-5' @click="deleteOffer(offer.id)">Delete</button>
                <button class='badge bg-primary ml-5' @click="editOffer(offer)">Edit</button>
              </td>
            </tr>
            <tr v-if='!displayOffers.length'>
              <td colspan='8' class='text-center'>No entries found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <!-- Basic Tables end -->

    <div class="overlay" v-if="modalStatus">
      <div class="filter-section">
        <button class="close btn-close" @click="modalToggle(false)"></button>
        <div class="row filter-input-section">
          <div class="col-12">
            <div class="form-group">
              <Field type="text" class="form-control" v-model="filter.searchText" name="search" placeholder="Search by title" />
            </div>
          </div>
          <div class='col-12'>
            <div class="form-group">
              <label for="first-name-column">Category</label>
              <Field
                as="select"
                class="form-control"
                name="category"
                placeholder="category"
                v-model="filter.category"
              >
                <option disabled selected hidden value="">
                  Select Category
                </option>
                <option v-for="category in vehicleCategoryOptions" :key="category.category_id" :value="category.category_id">
                  {{ category.category_name }}
                </option>
              </Field>
            </div>
          </div>
          <div class='col-12'>
            <div class="form-group">
              <label for="first-name-column">Brand</label>
              <Field
                as="select"
                class="form-control"
                name="brand"
                placeholder="Brand"
                v-model="filter.brand"
              >
                <option disabled selected hidden value="">
                  Select Brand
                </option>
                <option v-for="brand in brandOptions" :key="brand.brand_id" :value="brand.brand_id">
                  {{ brand.brand_name }}
                </option>
              </Field>
            </div>
          </div>
          <div class="col-12 text-right">
            <button class="btn btn-sm btn-success" @click="filterList">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style src='vue-multiselect/dist/vue-multiselect.css'></style>
<style scoped>
.chip{
  margin: 0px 10px 5px 0px;
}
.small-text{
  display: block;
  font-size: 12px;
  color: #555555;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.filter-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 4px;
  width: 550px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}
.filter-input-section{
  width: 90%;
  margin-top: 10px;
}
.btn-close{
  position: absolute;
  top: 14px;
  right: 14px;
  font-size: 16px;
  cursor: pointer;
}
</style>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate'
import _ from 'lodash'

import OfferModel from '../../models/OfferModel'
import brandService from '../../services/brand.service'
import FileService from '../../services/file.service'
import OfferService from '../../services/offer.service'
import VueMultiselect from 'vue-multiselect'
import TinymceEditor from '@/components/general/tinymce-editor.vue'

export default {
  name: 'Offer',
  data () {
    return {
      OfferModel: new OfferModel(),
      itemBlueprints: [],
      offers: [],
      displayOffers: [],
      openAddForm: false,
      selectedBrandId: '',
      brandOptions: [],
      brandObject: {},
      offerImage: [],
      selectedVehicleType: '',
      vehicleCategoryId: 1,
      selectedVehicleCategories: [],
      vehicleCategoryObject: {},
      vehicleCategoryOptions: [
        {
          category_id: 1,
          category_name: 'Below 1-Ton LCV'
        },
        {
          category_id: 2,
          category_name: '1-Ton To Below 1.5-Ton LCV'
        },
        {
          category_id: 3,
          category_name: '1.5-Ton LCV'
        },
        {
          category_id: 4,
          category_name: '3-Ton To Below 5-Ton Mini Truck'
        },
        {
          category_id: 5,
          category_name: '5-Ton Truck'
        },
        {
          category_id: 6,
          category_name: '7-Ton Truck'
        },
        {
          category_id: 7,
          category_name: 'Others Truck'
        },
        {
          category_id: 8,
          category_name: 'Small Tipper'
        },
        {
          category_id: 9,
          category_name: 'Medium Tipper'
        },
        {
          category_id: 10,
          category_name: 'Big Tipper'
        },
        {
          category_id: 11,
          category_name: 'Prime Mover'
        },
        {
          category_id: 12,
          category_name: 'Mixture'
        },
        {
          category_id: 13,
          category_name: 'Tanker'
        },
        {
          category_id: 14,
          category_name: 'Double Cab'
        }
      ],
      editImageURL: '',
      vehicleTypesOptions: [
        {
          id: 1,
          name: 'Commercial Vehicle'
        }
      ],
      modalStatus: false,
      filter: {
        category: 0,
        searchText: '',
        brand: -1
      }
    }
  },
  components: { Form, Field, ErrorMessage, VueMultiselect, TinymceEditor },
  watch: {
  },
  mounted () {
    this.getBrands()
    this.getOffers()
  },
  methods: {
    loadDataTable: async () => {
      // eslint-disable-next-line no-undef
      setTimeout($GLOBAL.loadTable('#table1'), 100000)
    },
    addVehicleCategory (vehicleCategory) {
      const vehicleCategoryObj = {
        category_name: vehicleCategory,
        category_id: this.vehicleCategoryId
      }
      this.vehicleCategoryId++
      this.selectedVehicleCategories.push(vehicleCategoryObj)
      this.vehicleCategoryOptions.push(vehicleCategoryObj)
      this.OfferModel.vehicleCategoryChilds.push(vehicleCategoryObj)
    },
    bindVehicleTypes () {
      this.OfferModel.vehicle_type_id = this.selectedVehicleType
      this.OfferModel.vehicle_type = this.vehicleTypesOptions.filter(item => item.id === this.selectedVehicleType)[0].name
    },
    onOfferPhotoUpload (event) {
      const files = event.target.files ? event.target.files : null
      this.offerImage = []
      if (!files.length) { return }
      FileService.prepareFileForUpload(files, this.OfferModel.id, 'offer-photo').then(
        response => {
          this.offerImage = response
          this.OfferModel.offer_photo_file = response
        },
        error => {
          console.log(error)
          alert('Error while trying to process uploaded files')
        }
      )
    },
    getBrands () {
      brandService.getBrands().then((response) => {
        if (response.data.status) {
          this.brandOptions = _.map(response.data.objects.brands, function (brand) {
            return {
              brand_id: brand.id,
              brand_name: brand.title
            }
          })
        }
      })
    },
    bindBrands () {
      this.OfferModel.brand_id = this.selectedBrandId
      this.OfferModel.brand = this.brandOptions.filter(item => item.brand_id === this.selectedBrandId)[0].brand_name
    },
    getOffers () {
      this.offers = []
      OfferService.getOffers().then(
        response => {
          if (response.data.status) {
            this.itemBlueprints = _.map(response.data.objects.offers, function (p) {
              return new OfferModel().load(p)
            })
            this.offers = _.map(response.data.objects.offers, function (b) {
              return new OfferModel().load(b)
            })
            this.displayOffers = _.map(response.data.objects.offers, function (b) {
              return new OfferModel().load(b)
            })
          }
        }
      )
    },
    reset () {
      this.OfferModel = new OfferModel()
      this.selectedBrandId = ''
      this.selectedVehicleType = ''
      this.vehicleCategoryId = 0
      this.offerImage = []

      // Clear Rich Text Field
      const parentElement = document.querySelectorAll('.ql-editor')
      for (let i = 0; i < parentElement.length; i++) {
        while (parentElement[i].firstChild) {
          parentElement[i].removeChild(parentElement[i].firstChild)
        }
        const newParagraph = document.createElement('p')
        parentElement[i].appendChild(newParagraph)
      }

      // Clear Input Field
      document.getElementById('input_file').value = ''
    },
    createOrUpdateOffer () {
      const vm = this
      OfferService.createOffer(this.OfferModel).then(
        response => {
          if (response.data.status) {
            vm.offers.push(new OfferModel().load(response.data.objects.offer))
            this.getOffers()
            vm.reset()
          }
        }
      )
    },
    editOffer (OfferModel) {
      this.OfferModel = OfferModel
      this.selectedVehicleType = this.OfferModel.vehicle_type_id
      this.selectedBrandId = this.OfferModel.brand_id
      this.editImageURL = this.OfferModel.image_url
      this.openAddForm = true
    },
    updateOffer () {
      const vm = this
      OfferService.updateOffer(this.OfferModel).then(
        response => {
          if (response.data.status) {
            const offers = _.find(vm.offers, function (p) { return p.id === vm.OfferModel.id })
            offers.load(response.data.objects.offer)
            vm.reset()
            this.openAddForm = false
            this.getOffers()
          }
        }
      )
    },
    deleteOffer (offerId) {
      const vm = this
      OfferService.deleteOffer(offerId).then(
        response => {
          if (response.data.status) {
            vm.offers = _.filter(vm.offers, function (p) { return p.id !== offerId })
            vm.reset()
            this.openAddForm = false
            this.getOffers()
          }
        }
      )
    },
    modalToggle (status) {
      this.modalStatus = status
      this.filter.category = 0
      this.filter.searchText = ''
      this.filter.brand = -1
    },
    filterList: function () {
      const vm = this
      if (vm.filter.category !== 0) {
        vm.displayOffers = vm.offers.filter(offer => {
          return offer.vehicleCategoryChilds.some(child => child.category_id === vm.filter.category)
        })
      }
      if (vm.filter.brand !== -1) {
        vm.displayOffers = vm.offers.filter(item => parseInt(item.brand_id) === parseInt(vm.filter.brand))
      }
      const key = vm.filter.searchText.trim().toLowerCase()
      if (key) {
        vm.displayOffers = vm.offers.filter(item => item.title.toLowerCase().includes(key))
      }
      this.modalToggle(false)
    }
  },
  computed: {
  }
}
</script>
