import Config from './config'
var api = Config.api

const businessAPIRoot = api.BASE_URL + '/business'

class BusinessService {
  createBusiness (BusinessModel) {
    return api.postCall(
      businessAPIRoot,
      BusinessModel,
      api.ShowAlert,
      api.ShowAlert
    )
  }

  createBusinessesInBulk (BusinessesModel) {
    return api.postCall(businessAPIRoot + '/bulk-create', BusinessesModel, api.ShowAlert, api.ShowAlert)
  }

  getBusinesses () {
    return api.getCall(businessAPIRoot, api.DoNothing, api.ShowAlert)
  }

  getBusinessesLazyLoading (page) {
    return api.getCall(businessAPIRoot + '/' + page, api.DoNothing, api.ShowAlert)
  }

  updateBusiness (BusinessModel) {
    return api.putCall(businessAPIRoot + '/' + BusinessModel.id, BusinessModel, api.ShowAlert, api.ShowAlert)
  }

  deleteBusiness (businessId) {
    return api.deleteCall(businessAPIRoot + '/' + businessId, api.ShowAlert, api.ShowAlert)
  }
}
export default new BusinessService()
