<template>
  <div class='page-heading'>
    <div class='page-title'>
      <div class='row'>
        <div class='col-12 col-md-6 order-md-1 order-last'>
          <h3>All Businesses</h3>
          <p class='text-subtitle text-muted'>
            View your added businesses and add new ones
          </p>
        </div>
        <div class='col-12 col-md-6 order-md-2 order-first'>
          <nav
            aria-label='breadcrumb'
            class='breadcrumb-header float-start float-lg-end'
          >
            <ol class='breadcrumb'>
              <li class='breadcrumb-item'>
                <router-link :to="{ name: 'home' }">Dashboard</router-link>
              </li>
              <li class='breadcrumb-item active' aria-current='page'>
                All Businesses
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
    <!-- Basic Tables start -->
    <section class="section">
      <div class="card">
        <div class="card-header">
          Bulk upload from excel
        </div>
        <div class="card-body">
          <ExcelTool @ExcelProductsUpload="OnExcelItemsUpload" :itemType="'business'" :products="itemBlueprints" :payload = "payload"></ExcelTool>
        </div>
      </div>

    </section>
    <!-- // Basic multiple Column Form section start -->
    <section id='multiple-column-form'>
      <div class='row match-height'>
        <div class='col-12'>
          <div class='card'>
            <div class='card-header d-flex justify-content-between align-items-center'>
              <button
                @click='openAddForm = !openAddForm'
                class='card-title btn btn-outline-dark'
              >
                Add Business
              </button>
              <i class="bi bi-filter" style="font-size: 35px; display: contents; cursor: pointer" @click="modalToggle(true)"></i>
            </div>
            <div v-show='openAddForm' class='card-content'>
              <div class='card-body'>
                <Form
                  class='form'
                  v-slot='{ handleSubmit }'
                  @submit.prevent='createOrUpdateBusiness'
                  :validation-schema='ValidationSchema'
                >
                  <div class='row'>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Business Title</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='title'
                          placeholder='Business Title'
                          v-model="BusinessModel.title"
                        />
                        <ErrorMessage class='text-danger' name='title' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Business Address</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='address'
                          placeholder='Business Address'
                          v-model="BusinessModel.address"
                        />
                        <ErrorMessage class='text-danger' name='address' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Business Email</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='email'
                          placeholder='Business Email'
                          v-model="BusinessModel.email"
                        />
                        <ErrorMessage class='text-danger' name='email' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Business Phone</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='phone'
                          placeholder='Business Phone'
                          v-model="BusinessModel.phone"
                        />
                        <ErrorMessage class='text-danger' name='phone' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class="form-group">
                        <label for="first-name-column">Division</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="division"
                          placeholder="Division"
                          v-model="selectedDivision"
                          @change="bindDivisions"
                        >
                          <option disabled selected hidden value="">
                            Select Division
                          </option>
                          <option v-for="division in divisions" :key="division.id" :value="division.id">
                            {{ division.name }}
                          </option>
                        </Field>
                        <ErrorMessage class='text-danger' name='property' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='first-name-column'>District</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="district"
                          placeholder="District"
                          v-model="selectedDistrict"
                          @change="bindDistricts"
                        >
                          <option disabled selected hidden value="">
                            Select District
                          </option>
                          <option v-for="district in selectedDistricts"  :key="district.id" :value="district.id">{{district.name}}</option>
                        </Field>
                        <ErrorMessage class='text-danger' name='property' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label class='typo__label'>Brand</label>
                        <VueMultiselect
                          v-model="BusinessModel.brandChilds"
                          tag-placeholder="Add this as new brand"
                          placeholder="Search or add a brand"
                          label="brand_name"
                          track-by="brand_id"
                          :options="brandOptions"
                          :multiple="true"
                          :taggable="true"
                          @tag="addBrand"
                        ></VueMultiselect>

                        <!-- <ErrorMessage class='text-danger' name='brand'/> -->
                      </div>
                    </div>

                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label class='typo__label'>Service Type</label>
                        <VueMultiselect
                          v-model="BusinessModel.serviceTypeChilds"
                          tag-placeholder="Add this as new service"
                          placeholder="Search or add a service"
                          label="service_name"
                          track-by="service_id"
                          :options="serviceTypeOptions"
                          :multiple="true"
                          :taggable="true"
                          @tag="addServiceType"
                        ></VueMultiselect>

                        <!-- <ErrorMessage class='text-danger' name='brand'/> -->
                      </div>
                    </div>

                    <div class='form-group col-12'>
                      <div class='form-check'>
                        <div class='checkbox'>
                          <input
                            type='checkbox'
                            id='checkbox5'
                            class='form-check-input'
                            checked
                            @change="bindStatusData"
                            v-model="isActive"
                          />
                          <label for='checkbox5'>Active</label>
                        </div>
                      </div>
                    </div>
                    <div class='col-12 d-flex justify-content-end'>
                      <button
                        class='btn btn-primary me-1 mb-1' v-show="BusinessModel.id==='undefined' || BusinessModel.id===0"
                        @click='handleSubmit($event, createOrUpdateBusiness)'
                      >
                        Create
                      </button>
                      <button
                        v-show="BusinessModel.id!=='undefined' && BusinessModel.id!==0"
                        class='btn btn-primary me-1 mb-1'
                        @click='handleSubmit($event, updateBusiness)'
                      >
                        Update
                      </button>
                      <button
                        class='btn btn-secondary me-1 mb-1'
                        @click='reset'
                      >
                        Reset
                      </button>
                      <button
                        class='btn btn-danger me-1 mb-1'
                        @click='openAddForm = false'
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- // Basic multiple Column Form section end -->
    <!-- Basic Tables start -->
    <section class='section'>
      <div class='card'>
        <div class='card-header'>
          <button class='badge bg-success' @click='loadDataTable' v-show="false">
            <i class="fa fa-magic"></i>
          </button>
        </div>
        <div class='card-body'>
          <table class='table table-striped' id='table1'>
            <thead>
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Mobile</th>
                <th>Brand</th>
                <th>Service</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for='(business, index) in displayBusiness' :key='business.id' >
                <td>{{ index+1 }}</td>
                <td>{{ business.title }}</td>
                <td>{{ business.phone }}</td>
                <td>
                  <p v-if="business.brandChilds.length>0">
                    <span v-for="brand in business.brandChilds" :key='brand.brand_id'>
                      <span class="badge bg-light-info chip">
                        {{ brand.brand_name }}
                      </span>
                    </span>
                  </p>
                </td>
                <td>
                  <p v-if="business.serviceTypeChilds.length>0">
                    <span v-for="service in business.serviceTypeChilds" :key='service.service_id'>
                      <span class="badge bg-light-primary chip">
                        {{ service.service_name }}
                      </span>
                    </span>
                  </p></td>
                <td>
                  <span class='badge bg-light-success chip'>Active</span>
                </td>
                <td>
                  <button class='badge bg-danger mr-5' @click="deleteBusiness(business.id)">Delete</button>
                  <button class='badge bg-primary ml-5' @click="editBusiness(business)">Edit</button>
                </td>
              </tr>
              <tr v-if='!businesses.length'>
                <td colspan='7' class='text-center'>No entries found</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <!-- Basic Tables end -->

    <div class="overlay" v-if="modalStatus">
      <div class="filter-section">
        <button class="close btn-close" @click="modalToggle(false)"></button>
        <div class="row filter-input-section">
          <div class="col-12">
            <div class="form-group">
              <Field type="text" class="form-control" v-model="filter.searchText" name="search" placeholder="Search by title" />
            </div>
          </div>
          <div class='col-12'>
            <div class="form-group">
              <label for="first-name-column">Service Type</label>
              <Field
                as="select"
                class="form-control"
                name="serviceType"
                placeholder="Service Type"
                v-model="filter.serviceType"
              >
                <option disabled selected hidden value="">
                  Select Service Type
                </option>
                <option v-for="serviceType in serviceTypeOptions" :key="serviceType.service_id" :value="serviceType.service_id">
                  {{ serviceType.service_name }}
                </option>
              </Field>
            </div>
          </div>
          <div class='col-12'>
            <div class="form-group">
              <label for="first-name-column">Brand</label>
              <Field
                as="select"
                class="form-control"
                name="brand"
                placeholder="Brand"
                v-model="filter.brand"
              >
                <option disabled selected hidden value="">
                  Select Brand
                </option>
                <option v-for="brand in brandOptions" :key="brand.brand_id" :value="brand.brand_id">
                  {{ brand.brand_name }}
                </option>
              </Field>
            </div>
          </div>
          <div class="col-12 text-right">
            <button class="btn btn-sm btn-success" @click="filterList">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style src='vue-multiselect/dist/vue-multiselect.css'></style>
<style scoped>
  .chip{
    margin: 0px 10px 5px 0px;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }

  .filter-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 4px;
    width: 550px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  .filter-input-section{
    width: 90%;
    margin-top: 10px;
  }
  .btn-close{
    position: absolute;
    top: 14px;
    right: 14px;
    font-size: 16px;
    cursor: pointer;
  }
</style>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate'
import _ from 'lodash'

import BusinessModel from '../../models/BusinessModel'
import BrandModel from '../../models/BrandModel'
import ServiceTypeModel from '../../models/ServiceTypeModel'
import BusinessService from '../../services/business.service'
import ServiceTypeService from '../../services/serviceType.service'
import VueMultiselect from 'vue-multiselect'
import brandService from '../../services/brand.service'
import divisionsData from '@/../public/assets/js/bd-divisions.json'
import districtsData from '@/../public/assets/js/bd-districts.json'
import ExcelTool from '@/components/general/bulk.upload.vue'

export default {
  name: 'Business',
  data () {
    return {
      // ValidationSchema: ValidationService.createUpdateSpecification,
      brands: [],
      businesses: [],
      BrandModel: new BrandModel(0),
      serviceTypes: [],
      BusinessModel: new BusinessModel(),
      ServiceTypeModel: new ServiceTypeModel(0),
      itemBlueprints: [],
      business: [],
      displayBusiness: [],
      payload: {},
      openAddForm: false,
      brandId: 0,
      selectedBrands: [],
      brandOptions: [],
      brandObject: {},
      serviceTypeId: 3,
      selectedServiceTypes: [],
      serviceTypeOptions: [],
      serviceTypeObject: {},
      selectedDivision: '',
      selectedDistrict: '',
      selectedDistricts: districtsData,
      isActive: true,
      divisions: divisionsData.divisions,
      districts: districtsData,
      modalStatus: false,
      filter: {
        serviceType: 0,
        searchText: '',
        brand: -1
      },
      currentPage: 1
    }
  },
  components: { ExcelTool, Form, Field, ErrorMessage, VueMultiselect },
  // watch: {
  //   'filter.searchText': function () {
  //     this.filterList()
  //   },
  //   'filter.serviceType': function () {
  //     this.filterList()
  //   },
  //   'filter.brand': function () {
  //     this.filterList()
  //   }
  // },
  mounted () {
    this.getBrands()
    this.getServiceTypes()
    this.getBusinesses()
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    loadDataTable: async () => {
      // eslint-disable-next-line no-undef
      setTimeout($GLOBAL.loadTable('#table1'), 100000)
    },
    bindDivisions () {
      this.BusinessModel.division_id = this.selectedDivision
      this.BusinessModel.division = this.divisions.filter(item => item.id === this.selectedDivision)[0].name

      this.selectedDistricts = this.districts.filter(item => item.division_id === this.selectedDivision)
    },
    bindDistricts () {
      this.BusinessModel.district_id = this.selectedDistrict
      const districtArray = this.selectedDistricts.filter(item => item.id === this.selectedDistrict)[0]

      this.BusinessModel.district = districtArray.name
      this.BusinessModel.division_id = districtArray.division_id

      this.BusinessModel.division = this.divisions.filter(item => item.id === districtArray.division_id)[0].name
    },
    bindStatusData () {
      this.BusinessModel.status = this.isActive === true ? 1 : 0
    },
    getBrands () {
      brandService.getBrands().then((response) => {
        if (response.data.status) {
          this.payload.districts = this.districts
          this.payload.divisions = this.divisions
          this.payload.brands = _.map(response.data.objects.brands, function (brand) {
            return {
              business_id: 0,
              brand_name: brand.title,
              brand_id: brand.id
            }
          })
          this.brandOptions = this.payload.brands
        }
      })
    },
    getServiceTypes () {
      ServiceTypeService.getServiceTypes().then((response) => {
        if (response.data.status) {
          this.payload.serviceTypes = _.map(response.data.objects.serviceTypes, function (serviceType) {
            return {
              business_id: 0,
              service_name: serviceType.name,
              service_id: serviceType.id
            }
          })
        }
      })
    },
    async getBusinesses () {
      // this.businesses = []
      // this.displayBusiness = []
      let businessData = []
      BusinessService.getBusinessesLazyLoading(this.currentPage).then(
        response => {
          if (response.data.status) {
            businessData = _.map(response.data.objects.data, function (p) {
              return new BusinessModel().load(p)
            })
            // this.itemBlueprints = _.map(response.data.objects.businesses, function (p) {
            //   return new BusinessModel().load(p)
            // })
            // this.businesses = _.map(response.data.objects.businesses, function (b) {
            //   return new BusinessModel().load(b)
            // })
            // this.displayBusiness = _.map(response.data.objects.businesses, function (b) {
            //   return new BusinessModel().load(b)
            // })
            console.log(businessData)
            this.itemBlueprints = this.itemBlueprints.concat(businessData)
            this.businesses = this.businesses.concat(businessData)
            this.displayBusiness = this.displayBusiness.concat(businessData)
            this.currentPage++
          }
        }
      )
    },
    handleScroll () {
      const scrollTop = window.scrollY
      const windowHeight = window.innerHeight || document.documentElement.clientHeight
      const documentHeight = document.documentElement.scrollHeight
      // return documentHeight - (scrollTop + windowHeight) < 100
      if (documentHeight - (scrollTop + windowHeight) < 1) {
        this.getBusinesses()
      }
      // if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      //   this.getBusinesses()
      // }
    },
    reset () {
      this.BusinessModel = new BusinessModel()
      this.selectedDivision = ''
      this.selectedDistrict = ''
    },
    addBrand (brand) {
      const brandObj = {
        title: brand,
        id: this.brandId
      }
      this.brandId++
      this.selectedBrands.push(brandObj)
      this.brandOptions.push(brandObj)
      this.BusinessModel.brandChilds.push(brandObj)
    },
    addServiceType (serviceType) {
      const serviceTypeObj = {
        title: serviceType,
        id: this.serviceTypeId
      }
      this.serviceTypeId++
      this.selectedServiceTypes.push(serviceTypeObj)
      this.serviceTypeOptions.push(serviceTypeObj)
      this.BusinessModel.serviceTypeChilds.push(serviceTypeObj)
    },
    createOrUpdateBusiness () {
      const vm = this
      BusinessService.createBusiness(this.BusinessModel).then(
        response => {
          if (response.data.status) {
            vm.businesses.push(new BusinessModel().load(response.data.objects.business))
            this.loadMore()
            vm.reset()
          }
        }
      )
    },
    editBusiness (BusinessModel) {
      this.BusinessModel = BusinessModel
      this.selectedDivision = this.BusinessModel.division_id
      this.selectedDistricts = this.districts.filter(item => parseInt(item.division_id) === this.selectedDivision)
      this.selectedDistrict = this.BusinessModel.district_id
      this.openAddForm = true
    },
    updateBusiness () {
      const vm = this
      BusinessService.updateBusiness(this.BusinessModel).then(
        response => {
          if (response.data.status) {
            const businesses = _.find(vm.businesses, function (p) { return p.id === vm.BusinessModel.id })
            businesses.load(response.data.objects.business)
            vm.reset()
            this.openAddForm = false
          }
        }
      )
    },
    deleteBusiness (businessId) {
      const vm = this
      BusinessService.deleteBusiness(businessId).then(
        response => {
          if (response.data.status) {
            vm.businesses = _.filter(vm.businesses, function (p) { return p.id !== businessId })
            vm.reset()
            this.openAddForm = false
          }
        }
      )
    },
    OnExcelItemsUpload (ExcelItems) {
      const vm = this
      _.forEach(ExcelItems, function (xclP) {
        xclP.id = 0
      })
      vm.createXclItems(ExcelItems)
    },
    createXclItems (Items) {
      BusinessService.createBusinessesInBulk(Items).then(
        response => {
          if (response.data.status) {
            this.getBusinesses()
          }
        },
        () => {
        }
      )
    },
    modalToggle (status) {
      this.modalStatus = status
      this.filter.serviceType = 0
      this.filter.searchText = ''
      this.filter.brand = -1
    },
    filterList: function () {
      const vm = this
      vm.displayBusiness = []
      if (vm.filter.serviceType !== 0) {
        vm.displayBusiness = vm.businesses.filter(item => {
          return item.serviceTypeChilds.some(child => child.service_id === vm.filter.serviceType)
        })
      }
      if (vm.filter.brand !== -1) {
        vm.displayBusiness = vm.businesses.filter(item => {
          return item.brandChilds.some(child => child.brand_id === vm.filter.brand)
        })
      }
      if (vm.filter.searchTex !== '') {
        const key = vm.filter.searchText.trim().toLowerCase()
        if (key) {
          vm.displayBusiness = vm.businesses.filter(item => item.title.toLowerCase().includes(key))
        }
      }
      this.modalToggle(false)
    }
  },
  computed: {
  }
}
</script>
