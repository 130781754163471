import UtilityService from '../services/utility.service'

export default class SettingsModel {
  constructor () {
    this.id = 1
    this.isOfferActive = false
    this.isNewsActive = false
    this.isVideoActive = false
    this.youtubeUrl = ''
    this.created_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
  }

  load (data) {
    this.id = data.id
    this.isOfferActive = data.isOfferActive === 1
    this.isNewsActive = data.isNewsActive === 1
    this.isVideoActive = data.isVideoActive === 1
    this.youtubeUrl = data.youtubeUrl
    this.created_at = UtilityService.formatDateTime(
      data.created_at,
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      data.updated_at,
      'MM/DD/YY hh:mm A'
    )
    return this
  }
}
