<template>
  <div class='page-heading'>
    <div class='page-title'>
      <div class='row'>
        <div class='col-12 col-md-6 order-md-1 order-last'>
          <h3>All Sliders</h3>
          <p class='text-subtitle text-muted'>
            View your added sliders and add new ones
          </p>
        </div>
        <div class='col-12 col-md-6 order-md-2 order-first'>
          <nav
            aria-label='breadcrumb'
            class='breadcrumb-header float-start float-lg-end'
          >
            <ol class='breadcrumb'>
              <li class='breadcrumb-item'>
                <router-link :to="{ name: 'home' }">Dashboard</router-link>
              </li>
              <li class='breadcrumb-item active' aria-current='page'>
                All Sliders
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <section id='multiple-column-form'>
      <div class='row match-height'>
        <div class='col-12'>
          <div class='card'>
            <div class='card-header'>
              <button
                @click='openCreateSlider'
                class='card-title btn btn-outline-dark'
              >
                Add Slider
              </button>
            </div>
            <div v-show='openAddForm' class='card-content'>
              <div class='card-body'>
                <Form
                  class='form'
                  v-slot='{ handleSubmit }'
                  @submit.prevent='createSlider'
                  :validation-schema='ValidationSchema'
                >
                  <div class='row'>
                    <div class='col-md-6 col-12'>
                      <div class="form-group">
                        <label for="input_file">Slider Image</label>
                        <span class="small-text">Please upload Landscape (1915 x 488) photo for better view.</span>
                        <Field @change="onSliderImageUpload" type="file" class="form-control" name="sliderImage" placeholder="Slider Image" id="input_file"/>
                        <ErrorMessage class="text-danger" name="sliderImage" />
                        <img class="img" width="200" style="margin-top: 10px" :src="editImageURL" alt="slider image" id="slider_image_view">
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='priority'>Priority</label>
                        <Field
                          type='number'
                          class='form-control'
                          name='priority'
                          placeholder='Priority'
                          v-model="SliderModel.priority"
                        />
                        <ErrorMessage class='text-danger' name='priority' />
                      </div>
                    </div>
                    <div class='form-group col-12'>
                      <div class='form-check'>
                        <div class='checkbox'>
                          <input
                            type='checkbox'
                            id='checkbox'
                            class='form-check-input'
                            checked
                            @change="bindStatusData"
                            v-model="isActive"
                          />
                          <label for='checkbox'>Active</label>
                        </div>
                      </div>
                    </div>

                    <div class='col-12 d-flex justify-content-end'>
                      <button
                        class='btn btn-primary me-1 mb-1' v-show="SliderModel.id==='undefined' || SliderModel.id===0"
                        @click='handleSubmit($event, createSlider)'
                      >
                        Create
                      </button>
                      <button
                        v-show="SliderModel.id!=='undefined' && SliderModel.id!==0"
                        class='btn btn-primary me-1 mb-1'
                        @click='handleSubmit($event, updateSlider)'
                      >
                        Update
                      </button>
                      <button
                        class='btn btn-secondary me-1 mb-1'
                        @click='reset'
                      >
                        Reset
                      </button>
                      <button
                        class='btn btn-danger me-1 mb-1'
                        @click='openAddForm = false'
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- // Basic multiple Column Form section end -->
    <!-- Basic Tables start -->
    <section class='section'>
      <div class='card'>
        <div class='card-header'>
          <button class='badge bg-success' @click='loadDataTable' v-show="false">
            <i class="fa fa-magic"></i>
          </button>
        </div>
        <div class='card-body'>
          <table class='table table-striped' id='table1'>
            <thead>
            <tr>
              <th>#</th>
              <th>Slider Image</th>
              <th>Priority</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for='(slider, index) in sliders' :key='slider.id' >
              <td>{{ index+1 }}</td>
              <td><img class="img" width="140" :src="slider.image_url" alt="Slider Image"></td>
              <td>{{ slider.priority}}</td>
              <td>
                <button class='badge bg-danger mr-5' @click="deleteSlider(slider.id)">Delete</button>
                <button class='badge bg-primary ml-5' @click="editSlider(slider)">Edit</button>
              </td>
            </tr>
            <tr v-if='!sliders.length'>
              <td colspan='4' class='text-center'>No entries found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <!-- Basic Tables end -->
  </div>
</template>

<style scoped>
.small-text{
  display: block;
  font-size: 12px;
  color: #555555;
}
</style>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate'
import _ from 'lodash'

import SliderModel from '../../models/SliderModel'
import SliderService from '../../services/slider.service'
import FileService from '../../services/file.service'

export default {
  name: 'Slider',
  data () {
    return {
      SliderModel: new SliderModel(),
      itemBlueprints: [],
      sliders: [],
      openAddForm: false,
      sliderImage: [],
      editImageURL: '',
      isActive: true
    }
  },
  components: { Form, Field, ErrorMessage },
  watch: {
  },
  mounted () {
    this.getSliders()
  },
  methods: {
    loadDataTable: async () => {
      // eslint-disable-next-line no-undef
      setTimeout($GLOBAL.loadTable('#table1'), 100000)
    },
    bindStatusData () {
      this.SliderModel.status = this.isActive === true ? 1 : 0
    },
    onSliderImageUpload (event) {
      const files = event.target.files ? event.target.files : null
      this.sliderImage = []
      if (!files.length) { return }
      FileService.prepareFileForUpload(files, this.SliderModel.id, 'slider').then(
        response => {
          this.sliderImage = response
          this.SliderModel.slider_photo_file = response
        },
        error => {
          console.log(error)
          alert('Error while trying to process uploaded files')
        }
      )
    },
    getSliders () {
      this.sliders = []
      SliderService.getSliders().then(
        response => {
          if (response.data.status) {
            this.itemBlueprints = _.map(response.data.objects.sliders, function (p) {
              return new SliderModel().load(p)
            })
            this.sliders = _.map(response.data.objects.sliders, function (b) {
              return new SliderModel().load(b)
            })
          }
        }
      )
    },
    openCreateSlider () {
      this.reset()
      this.openAddForm = !this.openAddForm
    },
    reset () {
      this.SliderModel = new SliderModel()
      document.getElementById('input_file').value = ''
      document.getElementById('slider_image_view').src = ''
    },
    createSlider () {
      const vm = this
      if (this.SliderModel.slider_photo_file.length === 0) {
        alert('Please upload slider image', 'error')
        return
      }

      SliderService.createSlider(this.SliderModel).then(
        response => {
          if (response.data.status) {
            vm.sliders.push(new SliderModel().load(response.data.objects.slider))
            this.getSliders()
            vm.reset()
          }
        }
      )
    },
    editSlider (SliderModel) {
      this.SliderModel = SliderModel
      this.editImageURL = this.SliderModel.image_url
      this.openAddForm = true
      this.isActive = this.SliderModel.status === 1
    },
    updateSlider () {
      const vm = this
      SliderService.updateSlider(this.SliderModel).then(
        response => {
          if (response.data.status) {
            const sliders = _.find(vm.sliders, function (p) { return p.id === vm.SliderModel.id })
            sliders.load(response.data.objects.slider)
            vm.reset()
            this.openAddForm = false
            this.getSliders()
          }
        }
      )
    },
    deleteSlider (sliderId) {
      const vm = this
      SliderService.deleteSlider(sliderId).then(
        response => {
          if (response.data.status) {
            vm.sliders = _.filter(vm.sliders, function (p) { return p.id !== sliderId })
            vm.reset()
            this.openAddForm = false
            this.getSliders()
          }
        }
      )
    }
  },
  computed: {
  }
}
</script>
