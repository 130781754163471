import Config from './config'
var api = Config.api

const adsAPIRoot = api.BASE_URL + '/bulletin'

class AdsService {
  createAds (adsModel) {
    return api.postCall(
      adsAPIRoot,
      adsModel,
      api.DoNothing,
      api.ShowAlert
    )
  }

  getAds () {
    return api.getCall(adsAPIRoot, api.DoNothing, api.ShowAlert)
  }

  getActiveAds () {
    return api.getCall(adsAPIRoot + '/activeBulletins', api.DoNothing, api.ShowAlert)
  }

  getAdsById (adsId) {
    return api.getCall(adsAPIRoot + '/single/' + adsId, api.DoNothing, api.ShowAlert)
  }

  updateAds (AdsModel) {
    return api.putCall(adsAPIRoot + '/' + AdsModel.id, AdsModel, api.ShowAlert, api.ShowAlert)
  }

  deleteAds (adsId) {
    return api.deleteCall(adsAPIRoot + '/' + adsId, api.ShowAlert, api.ShowAlert)
  }
}
export default new AdsService()
