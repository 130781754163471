import UtilityService from '../services/utility.service'
export default class OthersMenu {
  constructor (typeId) {
    this.id = 0
    this.title = ''
    this.title_bangla = ''
    this.url = ''
    this.created_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
  }

  load (data) {
    this.id = data.id
    this.title = data.title
    this.title_bangla = data.title_bangla
    this.url = data.url
    this.created_at = UtilityService.formatDateTime(
      data.created_at,
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      data.updated_at,
      'MM/DD/YY hh:mm A'
    )
    return this
  }
}
