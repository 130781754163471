import Config from './config'
var api = Config.api

const offerAPIRoot = api.BASE_URL + '/appSettings'

class SettingsService {
  getSettings () {
    return api.getCall(offerAPIRoot, api.DoNothing, api.ShowAlert)
  }

  updateSettings (SettingsModel) {
    return api.putCall(offerAPIRoot, SettingsModel, api.ShowAlert, api.ShowAlert)
  }
}
export default new SettingsService()
