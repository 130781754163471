import Config from './config'

const api = Config.api

const othersMenuAPIRoot = api.BASE_URL + '/othersMenuSettings'

class OthersMenuService {
  createOthersSubMenu (OthersMenuModel) {
    return api.postCall(othersMenuAPIRoot, OthersMenuModel, api.ShowAlert, api.ShowAlert)
  }

  getOthersSubMenu () {
    return api.getCall(othersMenuAPIRoot, api.DoNothing, api.ShowAlert)
  }

  updateOthersSubMenu (OthersMenuModel) {
    return api.putCall(othersMenuAPIRoot + '/' + OthersMenuModel.id, OthersMenuModel, api.ShowAlert, api.ShowAlert)
  }

  deleteOthersSubMenu (subMenuId) {
    return api.deleteCall(othersMenuAPIRoot + '/' + subMenuId, api.ShowAlert, api.ShowAlert)
  }
}
export default new OthersMenuService()
