import Config from './config'

const api = Config.api

const aboutUsAPIRoot = api.BASE_URL + '/aboutUs'

class AboutUsService {
  getAboutUs () {
    return api.getCall(aboutUsAPIRoot, api.DoNothing, api.ShowAlert)
  }

  updateAboutUs (AboutUsModel) {
    return api.putCall(aboutUsAPIRoot, AboutUsModel, api.ShowAlert, api.ShowAlert)
  }

  deleteAboutUs (aboutUsId) {
    return api.deleteCall(aboutUsAPIRoot + '/' + aboutUsId, api.ShowAlert, api.ShowAlert)
  }
}

export default new AboutUsService()
