import Config from './config'
const api = Config.api

const videoAPIRoot = api.BASE_URL + '/video'

class VideoService {
  createVideo (VideoModel) {
    return api.postCall(
      videoAPIRoot,
      VideoModel,
      api.ShowAlert,
      api.ShowAlert
    )
  }

  getVideos () {
    return api.getCall(videoAPIRoot, api.DoNothing, api.ShowAlert)
  }

  updateVideo (VideoModel) {
    return api.putCall(videoAPIRoot + '/' + VideoModel.id, VideoModel, api.ShowAlert, api.ShowAlert)
  }

  deleteVideo (videoId) {
    return api.deleteCall(videoAPIRoot + '/' + videoId, api.ShowAlert, api.ShowAlert)
  }
}
export default new VideoService()
