import UtilityService from '../services/utility.service'
export default class VideoModel {
  constructor () {
    this.id = 0
    this.video_url = ''
    this.title = ''
    this.title_bn = ''
    this.thumbnail = ''
    this.thumbnail_photo_file = []
    this.brand_id_1 = 0
    this.brand_id_2 = 0
    this.brand_id_3 = 0
    this.model_id_1 = 0
    this.model_id_2 = 0
    this.model_id_3 = 0
    this.category_id_1 = 0
    this.category_id_2 = 0
    this.category_id_3 = 0
    this.sub_category_id_1 = 0
    this.sub_category_id_2 = 0
    this.sub_category_id_3 = 0
    this.upload_date = ''
    this.duration = ''
    this.subscribe_url = ''
    this.priority = 0
    this.status = 1
    this.created_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
  }

  load (data) {
    this.id = data.id
    this.video_url = data.video_url ?? ''
    this.title = data.title ?? ''
    this.title_bn = data.title_bn ?? ''
    this.thumbnail = data.thumbnail ?? ''
    this.thumbnail_photo_file = data.thumbnail_photo_file ?? []
    this.brand_id_1 = data.brand_id_1 ?? ''
    this.brand_id_2 = data.brand_id_2 ?? ''
    this.brand_id_3 = data.brand_id_3 ?? ''
    this.model_id_1 = data.model_id_1 ?? ''
    this.model_id_2 = data.model_id_2 ?? ''
    this.model_id_3 = data.model_id_3 ?? ''
    this.category_id_1 = data.category_id_1 ?? ''
    this.category_id_2 = data.category_id_2 ?? ''
    this.category_id_3 = data.category_id_3 ?? ''
    this.sub_category_id_1 = data.sub_category_id_1 ?? ''
    this.sub_category_id_2 = data.sub_category_id_2 ?? ''
    this.sub_category_id_3 = data.sub_category_id_3 ?? ''
    this.upload_date = data.upload_date ?? ''
    this.duration = data.duration ?? ''
    this.subscribe_url = data.subscribe_url ?? ''
    this.priority = data.priority
    this.status = data.status
    this.created_at = UtilityService.formatDateTime(
      data.created_at,
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      data.updated_at,
      'MM/DD/YY hh:mm A'
    )
    return this
  }
}
