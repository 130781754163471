<template>
  <div class='page-heading'>
    <div class='page-title'>
      <div class='row'>
        <div class='col-12 col-md-6 order-md-1 order-last'>
          <h3>All Inquiries</h3>
          <p class='text-subtitle text-muted'>
            View your added inquiries and add new ones
          </p>
        </div>
        <div class='col-12 col-md-6 order-md-2 order-first d-flex flex-column align-items-end'>
          <nav
            aria-label='breadcrumb'
            class='breadcrumb-header float-start float-lg-end'
          >
            <ol class='breadcrumb mb-1'>
              <li class='breadcrumb-item'>
                <router-link :to="{ name: 'home' }">Dashboard</router-link>
              </li>
              <li class='breadcrumb-item active' aria-current='page'>
                All Inquiries
              </li>
            </ol>
          </nav>
          <div class="d-flex align-items-center">
            <span class="icon-container">
              <i class="bi bi-filter" style="font-size: 32px; display: contents; cursor: pointer" @click="filterModalToggle(true)"></i>
            </span>
            <span class="icon-container">
              <i class="bi bi-sort-alpha-down" style="font-size: 28px; display: contents; cursor: pointer" @click="sortModalToggle(true)"></i>
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- // Basic multiple Column Form section end -->
    <!-- Basic Tables start -->
    <section class='section mt-2'>
      <div class='card'>
        <div class='card-body table-section'>
          <table class='table table-striped' id='table1'>
            <thead>
            <tr>
              <th style="width: 5%; text-align: center">#</th>
              <th style="width: 8%">Name</th>
              <th style="width: 7%">Phone</th>
              <th style="width: 5%">District</th>
              <th style="width: 6%">Contact Demand</th>
              <th style="width: 3%">Submit</th>
              <th style="width: 4%">Created at</th>
              <th style="width: 4%">Updated at</th>
              <th style="width: 8%">Brand</th>
              <th style="width: 7%">Model</th>
              <th style="width: 6%">Category</th>
              <th style="width: 7%">Subcategory</th>
              <th style="width: 6%">Business Title</th>
              <th style="width: 5%">Business Phone</th>
              <th style="width: 4%">Business District</th>
              <th style="width: 5%">Business Address</th>
              <th style="width: 4%">Ip</th>
              <th style="width: 9%">Comment Status</th>
              <th style="width: 4%">Commented at</th>
              <th style="width: 9%">Comment</th>
              <th style="width: 4%">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for='(inquiry, index) in displayInquiries' :key='inquiry.id' >
              <td style="text-align: center">{{ index+1 }}</td>
              <td>{{ inquiry.name }}</td>
              <td>{{ inquiry.phone}}</td>
              <td>{{ inquiry.district}}</td>
              <td>{{ inquiry.contact_demand}}</td>
              <td>{{ inquiry.number_of_submit}}</td>
              <td>{{ inquiry.created_at}}</td>
              <td>{{ inquiry.updated_at}}</td>
              <td>{{ inquiry.brand}}</td>
              <td>{{ inquiry.model}}</td>
              <td>{{ inquiry.category}}</td>
              <td>{{ inquiry.subcategory}}</td>
              <td>{{ inquiry.businessTitle}}</td>
              <td>{{ inquiry.businessPhone}}</td>
              <td>{{ inquiry.businessDistrict}}</td>
              <td>{{ inquiry.businessAddress}}</td>
              <td>{{ inquiry.ip}}</td>
              <td>{{ inquiry.commentStatus}}</td>
              <td>{{ inquiry.comment_updated_at}}</td>
              <td>{{ inquiry.comment}}</td>
              <td>
                <button class='badge bg-primary' @click="updateModalAction(inquiry.id, inquiry.comment)">Update Comment</button>
              </td>
            </tr>
            <tr v-if='!displayInquiries.length'>
              <td colspan='20' class='text-center'>No entries found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <!-- Basic Tables end -->
    <div class="overlay" v-if="commentUpdateModal">
      <div class="filter-section">
        <button class="close btn-close" @click="commentUpdateModal=false"></button>
        <div class="row filter-input-section">
          <div class="col-12">
            <div class="form-group">
              <textarea class='form-control' name='comment' rows="6" placeholder='Enter Comment' v-model="InquiryModel.comment">
              </textarea>
            </div>
          </div>
          <div class="col-12 text-right">
            <button class="btn btn-sm btn-success" @click="updateComment(InquiryModel)">Submit</button>
          </div>
        </div>
      </div>
    </div>

    <div class="overlay" v-if="filterModalStatus">
      <div class="filter-section">
        <button class="close btn-close" @click="filterModalToggle(false)"></button>
        <div class="row filter-input-section">
          <div class='col-12'>
            <div class="form-group">
              <label for="first-name-column">Contact Demand</label>
              <Field
                as="select"
                class="form-control"
                name="contactDemand"
                placeholder="contactDemand"
                v-model="filter.contactDemand"
              >
                <option disabled selected hidden value="0">
                  Select Contact Demand
                </option>
                <option v-for="contactDemand in contactDemands" :key="contactDemand.id" :value="contactDemand.value">
                  {{ contactDemand.value }}
                </option>
              </Field>
            </div>
          </div>
          <div class='col-12'>
            <div class="form-group">
              <label for="first-name-column">Brand</label>
              <Field
                as="select"
                class="form-control"
                name="brand"
                placeholder="Brand"
                v-model="filter.brand"
              >
                <option disabled selected hidden value="0">
                  Select Brand
                </option>
                <option v-for="brand in brandOptions" :key="brand.brand_id" :value="brand.brand_name">
                  {{ brand.brand_name }}
                </option>
              </Field>
            </div>
          </div>
          <div class='col-12'>
            <div class="form-group">
              <label for="first-name-column">District</label>
              <Field
                as="select"
                class="form-control"
                name="district"
                placeholder="District"
                v-model="filter.district"
              >
                <option disabled selected hidden value="0">
                  Select District
                </option>
                <option v-for="district in districts"  :key="district.id" :value="district.name">{{district.name}}</option>
              </Field>
            </div>
          </div>
          <div class='col-12'>
            <div class="form-group">
              <label for="first-name-column">Comment Status</label>
              <Field
                as="select"
                class="form-control"
                name="commentStatus"
                placeholder="commentStatus"
                v-model="filter.commentStatus"
              >
                <option disabled selected hidden value="-1">
                  Select Status
                </option>
                <option value="1">True</option>
                <option value="0">False</option>
              </Field>
            </div>
          </div>
          <div class="col-12 text-right">
            <button class="btn btn-sm btn-success" @click="filterList">Submit</button>
          </div>
        </div>
      </div>
    </div>

    <div class="overlay" v-if="sortModalStatus">
      <div class="filter-section">
        <button class="close btn-close" @click="sortModalToggle(false)"></button>
        <div class="row filter-input-section">
          <div class='col-12'>
            <div class="form-group">
              <label for="first-name-column">Sort Field</label>
              <div class='form-group'>
                <div role="radiogroup" class="radio-section">
                  <div class="custom-radio">
                    <input name="sortField" id="brand" type="radio" class="sr-only" value="brand" v-model="selectedSortField">
                    <label for="brand">
                      <span></span>
                      <div class="radio-title">Brand</div>
                    </label>
                  </div>
                  <div class="custom-radio">
                    <input name="sortField" id="number_of_submit" type="radio" class="sr-only" value="number_of_submit" v-model="selectedSortField">
                    <label for="number_of_submit">
                      <span></span>
                      <div class="radio-title">Number of Submit</div>
                    </label>
                  </div>
                  <div class="custom-radio">
                    <input name="sortField" id="created_at" type="radio" class="sr-only" value="created_at" v-model="selectedSortField">
                    <label for="created_at">
                      <span></span>
                      <div class="radio-title">Created at</div>
                    </label>
                  </div>
                  <div class="custom-radio">
                    <input name="sortField" id="updated_at" type="radio" class="sr-only" value="updated_at" v-model="selectedSortField">
                    <label for="updated_at">
                      <span></span>
                      <div class="radio-title">Updated at</div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='col-12'>
            <div class="form-group">
              <label for="first-name-column">Sort Criteria</label>
              <div class='form-group'>
                <div role="radiogroup" class="radio-section radio-criteria-section">
                  <div class="custom-radio">
                    <input name="sortCriteria" id="asc" type="radio" class="sr-only" value="asc" v-model="selectedSortCriteria">
                    <label for="asc">
                      <span></span>
                      <div class="radio-title">Ascending Order</div>
                    </label>
                  </div>
                  <div class="custom-radio">
                    <input name="sortCriteria" id="desc" type="radio" class="sr-only" value="desc" v-model="selectedSortCriteria">
                    <label for="desc">
                      <span></span>
                      <div class="radio-title">Descending Order</div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 text-right">
            <button class="btn btn-sm btn-success" @click="sortList">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.table-section{
  width: 100%;
  height: calc(100vh - 140px);
  overflow-x: auto;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.filter-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 4px;
  width: 550px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}
.filter-input-section{
  width: 90%;
  margin-top: 10px;
}
.btn-close{
  position: absolute;
  top: 14px;
  right: 14px;
  font-size: 16px;
  cursor: pointer;
}
.icon-container{
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 3px 4px;
  padding-top: 5px;
}
.icon-container:first-child{
  margin-right: 15px;
}
.radio-section{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.radio-criteria-section{
  justify-content: start;
}
.radio-criteria-section .custom-radio{
  margin-right: 40px;
}
.custom-radio {
  overflow: hidden;
  margin-right: 20px;
  margin-bottom: 10px;
}
.custom-radio input[type=radio], .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
  line-height: 1.15;
}
input[type=radio]+label {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px !important;
  -ms-flex-align: center;
  align-items: center;
}
.custom-radio input[type=radio]+label span {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #afb7ad;
  padding: 6px;
  border-radius: 50%;
}

.custom-radio input[type=radio]+label span, .radio-desktop-standard--1douS {
  height: 16px;
  width: 16px;
}
.radio-title {
  margin-left: 8px;
  color: #424e4e;
  margin-top: 1px;
  width: 100%;
  font-size: 15px;
}
.custom-radio input[type=radio]:checked+label span {
  background: radial-gradient(#fff 27.5%,#ffb600 0);
  border: 1px solid #ffb600;
}
</style>

<script>
import _ from 'lodash'
import { Field } from 'vee-validate'

import InquiryModel from '../../models/InquiryModel'
import InquiryService from '../../services/inquiry.service'
import brandService from '../../services/brand.service'
import districtsData from '@/../public/assets/js/bd-districts.json'

export default {
  name: 'Inquiry',
  data () {
    return {
      InquiryModel: new InquiryModel(),
      inquiries: [],
      displayInquiries: [],
      commentUpdateModal: false,
      filterModalStatus: false,
      sortModalStatus: false,
      brandOptions: [],
      contactDemands: [
        {
          id: 1,
          value: 'price'
        },
        {
          id: 2,
          value: 'talk-to-dealer'
        },
        {
          id: 3,
          value: 'contact-to-dealer'
        }
      ],
      filter: {
        contactDemand: 0,
        district: 0,
        brand: 0,
        commentStatus: -1
      },
      districts: districtsData,
      selectedSortField: '',
      selectedSortCriteria: ''
    }
  },
  components: { Field },
  watch: {},
  mounted () {
    this.getBrands()
    this.getInquiries()
  },
  methods: {
    getInquiries () {
      this.inquiries = []
      this.displayInquiries = []
      InquiryService.getInquiries().then(
        response => {
          if (response.data.status) {
            this.inquiries = _.map(response.data.objects.inquiries, function (b) {
              return new InquiryModel().load(b)
            })
            this.displayInquiries = _.map(response.data.objects.inquiries, function (b) {
              return new InquiryModel().load(b)
            })
            this.selectedSortField = 'created_at'
            this.selectedSortCriteria = 'asc'
            this.sortList()
          }
        }
      )
    },
    updateModalAction (id, comment) {
      this.commentUpdateModal = true
      this.InquiryModel = new InquiryModel()
      this.InquiryModel.comment = comment
      this.InquiryModel.id = id
    },
    updateComment (InquiryModelData) {
      InquiryService.updateInquiry(InquiryModelData).then(
        response => {
          if (response.data.status) {
            this.inquiries = _.map(response.data.objects.inquiries, function (b) {
              return new InquiryModel().load(b)
            })
            this.displayInquiries = _.map(response.data.objects.inquiries, function (b) {
              return new InquiryModel().load(b)
            })
            this.commentUpdateModal = false
          }
        }
      )
    },
    getBrands () {
      brandService.getBrands().then((response) => {
        if (response.data.status) {
          this.brandOptions = _.map(response.data.objects.brands, function (brand) {
            return {
              brand_id: brand.id,
              brand_name: brand.title
            }
          })
        }
      })
    },
    filterModalToggle (status) {
      this.filterModalStatus = status
      this.filter.contactDemand = 0
      this.filter.district = 0
      this.filter.brand = 0
      this.filter.commentStatus = -1
    },
    sortModalToggle (status) {
      this.sortModalStatus = status
      this.selectedSortField = ''
      this.selectedSortCriteria = ''
    },
    filterList: function () {
      const vm = this

      if (vm.filter.contactDemands !== 0) {
        vm.displayInquiries = vm.inquiries.filter(item => item.contact_demand.trim() === vm.filter.contactDemand)
      }
      if (vm.filter.brand !== 0) {
        vm.displayInquiries = vm.inquiries.filter(item => item.brand.trim() === vm.filter.brand)
      }
      if (vm.filter.district !== 0) {
        vm.displayInquiries = vm.inquiries.filter(item => item.district.trim() === vm.filter.district)
      }
      if (vm.filter.commentStatus !== -1) {
        if (vm.filter.commentStatus === '0') {
          vm.displayInquiries = vm.inquiries.filter(item => item.comment.length === 0)
        } else {
          vm.displayInquiries = vm.inquiries.filter(item => item.comment.length > 0)
        }
      }
      this.filterModalToggle(false)
    },
    sortList: function () {
      if (this.selectedSortField === 'created_at' || this.selectedSortField === 'updated_at') {
        this.displayInquiries.sort((a, b) => {
          const dateA = new Date(a[this.selectedSortField])
          const dateB = new Date(b[this.selectedSortField])
          const comparison = dateA - dateB
          return this.selectedSortCriteria === 'desc' ? comparison * -1 : comparison
        })
      } else {
        this.displayInquiries.sort((a, b) => {
          const comparison = a[this.selectedSortField] > b[this.selectedSortField] ? 1 : a[this.selectedSortField] < b[this.selectedSortField] ? -1 : 0
          return this.selectedSortCriteria === 'desc' ? comparison * -1 : comparison
        })
      }
      this.sortModalToggle(false)
    }
  },
  computed: {}
}
</script>
