import Config from './config'

const api = Config.api

const termsAndConditionsAPIRoot = api.BASE_URL + '/terms'

class TermsAndConditionsService {
  getTermsAndConditions () {
    return api.getCall(termsAndConditionsAPIRoot, api.DoNothing, api.ShowAlert)
  }

  updateTermsAndConditions (TermsAndConditionsModel) {
    return api.putCall(termsAndConditionsAPIRoot, TermsAndConditionsModel, api.ShowAlert, api.ShowAlert)
  }

  deleteTermsAndConditions (termsId) {
    return api.deleteCall(termsAndConditionsAPIRoot + '/' + termsId, api.ShowAlert, api.ShowAlert)
  }
}

export default new TermsAndConditionsService()
