import Config from './config'

const api = Config.api

const sliderAPIRoot = api.BASE_URL + '/slider'

class SliderService {
  createSlider (SliderModel) {
    return api.postCall(sliderAPIRoot, SliderModel, api.ShowAlert, api.ShowAlert)
  }

  getSliders () {
    return api.getCall(sliderAPIRoot, api.DoNothing, api.ShowAlert)
  }

  updateSlider (SliderModel) {
    return api.putCall(sliderAPIRoot + '/' + SliderModel.id, SliderModel, api.ShowAlert, api.ShowAlert)
  }

  deleteSlider (sliderId) {
    return api.deleteCall(sliderAPIRoot + '/' + sliderId, api.ShowAlert, api.ShowAlert)
  }
}
export default new SliderService()
