<template>
  <div class='page-heading'>
    <div class='page-title'>
      <div class='row'>
        <div class='col-12 col-md-6 order-md-1 order-last'>
          <h3>All Brochures</h3>
          <p class='text-subtitle text-muted'>
            View your added brochures and add new ones
          </p>
        </div>
        <div class='col-12 col-md-6 order-md-2 order-first'>
          <nav
            aria-label='breadcrumb'
            class='breadcrumb-header float-start float-lg-end'
          >
            <ol class='breadcrumb'>
              <li class='breadcrumb-item'>
                <router-link :to="{ name: 'home' }">Dashboard</router-link>
              </li>
              <li class='breadcrumb-item active' aria-current='page'>
                All Brochures
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <section id='multiple-column-form'>
      <div class='row match-height'>
        <div class='col-12'>
          <div class='card'>
            <div class='card-header'>
              <button
                @click='openAddForm = !openAddForm'
                class='card-title btn btn-outline-dark'
              >
                Add Brochures
              </button>
            </div>
            <div v-show='openAddForm' class='card-content'>
              <div class='card-body'>
                <Form
                  class='form'
                  v-slot='{ handleSubmit }'
                  @submit.prevent='createBrochure'
                >
                  <div class='row'>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Brand</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="brand"
                          placeholder="Brand"
                          v-model="selectedBrand"
                        >
                          <option disabled selected hidden value="">
                            Select Brand
                          </option>
                          <option v-for="brand in brands" :key="brand.id" :value="brand.id">
                            {{ brand.title }}
                          </option>
                        </Field>
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Model</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="model"
                          placeholder="Model"
                          v-model="selectedModel"
                        >
                          <option disabled selected hidden value="">
                            Select Model
                          </option>
                          <option v-for="model in models" :key="model.product_id" :value='getModelValue(model)'>
                            {{ model.value }}
                          </option>
                        </Field>
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>File Type</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="type"
                          placeholder="Type"
                          v-model="BrochureModel.type"
                        >
                          <option disabled selected hidden value="">
                            Select Type
                          </option>
                          <option value="1">Image</option>
                          <option value="2">PDF</option>
                        </Field>
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class="form-group">
                        <label for="imageUrl">Brochure Image</label>
                        <Field @change="onBrochureUpload" type="file" class="form-control" name="brochureFile" placeholder="Brochure File" id="input_file"/>
                        <span class="small-text">Upload Image or PDF file</span>
                        <img v-if="BrochureModel.type===1" class="img" width="200" style="margin-top: 10px" :src="editFileURL" :alt="brochure">
                        <embed v-else :src="editFileURL" type="application/pdf" width="100" height="120" />
                      </div>
                    </div>

                    <div class='col-12 d-flex justify-content-end'>
                      <button
                        class='btn btn-primary me-1 mb-1' v-show="BrochureModel.id==='undefined' || BrochureModel.id===0"
                        @click='handleSubmit($event, createBrochure)'
                      >
                        Create
                      </button>
                      <button
                        v-show="BrochureModel.id!=='undefined' && BrochureModel.id!==0"
                        class='btn btn-primary me-1 mb-1'
                        @click='handleSubmit($event, updateBrochure)'
                      >
                        Update
                      </button>
                      <button
                        class='btn btn-secondary me-1 mb-1'
                        @click='reset'
                      >
                        Reset
                      </button>
                      <button
                        class='btn btn-danger me-1 mb-1'
                        @click='openAddForm = false'
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- // Basic multiple Column Form section end -->
    <!-- Basic Tables start -->
    <section class='section'>
      <div class='card'>
        <div class='card-header'>
          <button class='badge bg-success' @click='loadDataTable' v-show="false">
            <i class="fa fa-magic"></i>
          </button>
        </div>
        <div class='card-body'>
          <table class='table table-striped' id='table1'>
            <thead>
            <tr>
              <th style="width: 10%; text-align: center">#</th>
              <th style="width: 35%">product</th>
              <th style="width: 10%">Type</th>
              <th style="width: 30%; text-align: center">File</th>
              <th style="width: 15%">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for='(brochure, index) in brochures' :key='brochure.id' >
              <td style="text-align: center">{{ index+1 }}</td>
              <td>{{ brochure.title }}</td>
              <td>{{ brochure.type === 1 ? 'Image' : 'PDF'}}</td>
              <td v-if="brochure.type===1" style="text-align: center">
                <img class="img" width="120" :src="brochure.file_url" :alt="brochure">
              </td>
              <td v-else style="text-align: center">
                <embed :src="brochure.file_url" type="application/pdf" width="100" height="120" />
              </td>
              <td>
                <button class='badge bg-danger mr-5' @click="deleteBrochure(brochure.id)">Delete</button>
                <button class='badge bg-primary ml-5' @click="editBrochure(brochure)">Edit</button>
              </td>
            </tr>
            <tr v-if='!brochures.length'>
              <td colspan='5' class='text-center'>No entries found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <!-- Basic Tables end -->
  </div>
</template>

<style scoped>
.small-text{
  display: block;
  font-size: 12px;
  color: #555555;
}
</style>

<script>
import { Field, Form } from 'vee-validate'
import _ from 'lodash'

import BrochureModel from '../../models/BrochureModel'
import BrochureService from '../../services/brochure.service'
import FileService from '../../services/file.service'
import brandService from '@/services/brand.service'
export default {
  name: 'Brochure',
  data () {
    return {
      BrochureModel: new BrochureModel(),
      itemBlueprints: [],
      brochures: [],
      brands: [],
      models: [],
      openAddForm: false,
      brochureFile: [],
      editFileURL: '',
      selectedBrand: -1,
      selectedModel: -1
    }
  },
  components: { Form, Field },
  watch: {
    selectedBrand: function () {
      this.getModels(this.selectedBrand)
    }
  },
  mounted () {
    this.getBrands()
    this.getBrochures()
  },
  methods: {
    loadDataTable: async () => {
      // eslint-disable-next-line no-undef
      setTimeout($GLOBAL.loadTable('#table1'), 100000)
    },
    getBrands () {
      this.brands = []
      this.models = []
      brandService.getBrands().then(
        response => {
          if (response.data.status) {
            this.brands = response.data.objects.brands
          }
        }
      )
    },
    getModels (brandId) {
      this.models = []
      brandService.getModelByBrandId(brandId).then(
        response => {
          if (response.data.status) {
            this.models = response.data.objects.models
          }
        }
      )
    },
    getModelValue (model) {
      return `${model.product_id}*${model.value}`
    },
    onBrochureUpload (event) {
      const files = event.target.files ? event.target.files : null
      this.brochureFile = []
      if (!files.length) { return }
      FileService.prepareFileForUpload(files, this.BrochureModel.id, 'brochure').then(
        response => {
          this.brochureFile = response
          this.BrochureModel.brochure_file = response
        },
        error => {
          console.log(error)
          alert('Error while trying to process uploaded files')
        }
      )
    },
    getBrochures () {
      this.brochures = []
      BrochureService.getBrochures().then(
        response => {
          if (response.data.status) {
            this.itemBlueprints = _.map(response.data.objects.brochures, function (p) {
              return new BrochureModel().load(p)
            })
            this.brochures = _.map(response.data.objects.brochures, function (b) {
              return new BrochureModel().load(b)
            })
          }
        }
      )
    },
    reset () {
      this.BrochureModel = new BrochureModel()
      this.selectedBrand = -1
      this.selectedModel = -1
      document.getElementById('input_file').value = ''
    },
    createBrochure () {
      const vm = this
      vm.BrochureModel.brand_id = this.selectedBrand
      vm.BrochureModel.product_id = this.selectedModel.split('*')[0]
      vm.BrochureModel.title = this.selectedModel.split('*')[1]
      BrochureService.createBrochure(vm.BrochureModel).then(
        response => {
          if (response.data.status) {
            vm.brochures.push(new BrochureModel().load(response.data.objects.brochure))
            vm.getBrochures()
            vm.reset()
          }
        }
      )
    },
    editBrochure (BrochureModel) {
      this.BrochureModel = BrochureModel
      this.editFileURL = this.BrochureModel.file_url
      this.selectedBrand = this.BrochureModel.brand_id
      this.selectedModel = this.BrochureModel.product_id + '*' + this.BrochureModel.title
      this.openAddForm = true
    },
    updateBrochure () {
      const vm = this
      vm.BrochureModel.brand_id = this.selectedBrand
      vm.BrochureModel.product_id = this.selectedModel.split('*')[0]
      vm.BrochureModel.title = this.selectedModel.split('*')[1]
      BrochureService.updateBrochure(this.BrochureModel).then(
        response => {
          if (response.data.status) {
            const brochures = _.find(vm.brochures, function (p) { return p.id === vm.BrochureModel.id })
            brochures.load(response.data.objects.brochure)
            vm.reset()
            this.openAddForm = false
            this.getBrochures()
          }
        }
      )
    },
    deleteBrochure (brochureId) {
      const vm = this
      BrochureService.deleteBrochure(brochureId).then(
        response => {
          if (response.data.status) {
            vm.brochures = _.filter(vm.brochures, function (b) { return b.id !== brochureId })
            vm.reset()
            this.openAddForm = false
            this.getBrochures()
          }
        }
      )
    }
  },
  computed: {
  }
}
</script>
