import UtilityService from '../services/utility.service'
export default class VehicleCategoryModel {
  constructor (offerId) {
    this.category_id = 0
    this.category_name = ''
    this.offer_id = offerId
    this.created_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
  }

  load (data) {
    this.category_id = data.category_id
    this.category_name = data.category_name
    this.offer_id = data.offer_id
    this.created_at = UtilityService.formatDateTime(
      data.created_at,
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      data.updated_at,
      'MM/DD/YY hh:mm A'
    )
    return this
  }
}
