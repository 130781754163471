import Config from './config'

const api = Config.api

const inquiryAPIRoot = api.BASE_URL + '/inquiry'

class InquiryService {
  getInquiries () {
    return api.getCall(inquiryAPIRoot, api.DoNothing, api.ShowAlert)
  }

  updateInquiry (inquiryModel) {
    return api.putCall(inquiryAPIRoot + '/' + inquiryModel.id, inquiryModel, api.ShowAlert, api.ShowAlert)
  }
}
export default new InquiryService()
