<template>
  <div class='page-heading'>
    <div class='page-title'>
      <div class='row'>
        <div class='col-12 col-md-6 order-md-1 order-last'>
          <h3>All Others Submenus</h3>
          <p class='text-subtitle text-muted'>
            View your Submenus and add new ones
          </p>
        </div>
        <div class='col-12 col-md-6 order-md-2 order-first'>
          <nav
            aria-label='breadcrumb'
            class='breadcrumb-header float-start float-lg-end'
          >
            <ol class='breadcrumb'>
              <li class='breadcrumb-item'>
                <router-link :to="{ name: 'home' }">Dashboard</router-link>
              </li>
              <li class='breadcrumb-item active' aria-current='page'>
                All Others Submenus
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
    <section id='multiple-column-form'>
      <div class='row match-height'>
        <div class='col-12'>
          <div class='card'>
            <div class='card-header'>
              <button
                @click='openAddForm = !openAddForm'
                class='card-title btn btn-outline-dark'
              >
                Add SubMenus
              </button>
            </div>
            <div v-show='openAddForm' class='card-content'>
              <div class='card-body'>
                <Form
                  class='form'
                  v-slot='{ handleSubmit }'
                  @submit.prevent='createOthersSubMenu'
                  :validation-schema='ValidationSchema'
                >
                  <div class='row'>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Title</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='title'
                          placeholder='SubMenu Title'
                          v-model="OthersMenu.title"
                        />
                        <ErrorMessage class='text-danger' name='title' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Title (In Bangla)</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='title_bangla'
                          placeholder='SubMenu Title (In Bangla)'
                          v-model="OthersMenu.title_bangla"
                        />
                        <ErrorMessage class='text-danger' name='title_bangla' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Redirect Url</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='url'
                          placeholder='Redirect Url'
                          v-model="OthersMenu.url"
                        />
                        <ErrorMessage class='text-danger' name='url' />
                      </div>
                    </div>
                    <div class='col-12 d-flex justify-content-end'>
                      <button
                        class='btn btn-primary me-1 mb-1' v-show="OthersMenu.id==='undefined' || OthersMenu.id===0"
                        @click='handleSubmit($event, createOthersSubMenu)'
                      >
                        Create
                      </button>
                      <button
                        v-show="OthersMenu.id!=='undefined' && OthersMenu.id!==0"
                        class='btn btn-primary me-1 mb-1'
                        @click='handleSubmit($event, updateOthersSubMenu)'
                      >
                        Update
                      </button>
                      <button
                        class='btn btn-secondary me-1 mb-1'
                        @click='reset'
                      >
                        Reset
                      </button>
                      <button
                        class='btn btn-danger me-1 mb-1'
                        @click='openAddForm = false'
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- // Basic multiple Column Form section end -->
    <!-- Basic Tables start -->
    <section class='section'>
      <div class='card'>
        <div class='card-header'>
          <button class='badge bg-success' @click='loadDataTable' v-show="false">
            <i class="fa fa-magic"></i>
          </button>
        </div>
        <div class='card-body'>
          <table class='table table-striped' id='table1'>
            <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Title (Bangla)</th>
              <th>Url</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for='(subMenu, index) in othersSubMenus' :key='subMenu.id' >
              <td>{{ index+1 }}</td>
              <td>{{ subMenu.title }}</td>
              <td>{{ subMenu.title_bangla }}</td>
              <td>{{ subMenu.url }}</td>
              <td>
                <button class='badge bg-danger mr-5' @click="deleteOthersSubMenu(subMenu.id)">Delete</button>
                <button class='badge bg-primary ml-5' @click="editOthersSubMenu(subMenu)">Edit</button>
              </td>
            </tr>
            <tr v-if='!othersSubMenus.length'>
              <td colspan='5' class='text-center'>No entries found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <!-- Basic Tables end -->
  </div>
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate'
import _ from 'lodash'

import OthersMenu from '../../models/OthersMenu'
import OthersMenuService from '../../services/othersMenu.service'

export default {
  name: 'OthersMenu',
  data () {
    return {
      OthersMenu: new OthersMenu(),
      itemBlueprints: [],
      openAddForm: false,
      othersSubMenus: []
    }
  },
  components: { Form, Field, ErrorMessage },
  watch: {
  },
  mounted () {
    this.getOthersSubMenus()
  },
  methods: {
    loadDataTable: async () => {
      // eslint-disable-next-line no-undef
      setTimeout($GLOBAL.loadTable('#table1'), 100000)
    },
    getOthersSubMenus () {
      OthersMenuService.getOthersSubMenu().then((response) => {
        if (response.data.status) {
          this.othersSubMenus = _.map(response.data.objects.othersMenu, function (b) {
            return new OthersMenu().load(b)
          })
        }
      })
    },
    reset () {
      this.OthersMenu = new OthersMenu()
    },
    createOthersSubMenu () {
      const vm = this
      OthersMenuService.createOthersSubMenu(this.OthersMenu).then(
        response => {
          if (response.data.status) {
            vm.othersSubMenus.push(new OthersMenu().load(response.data.objects.othersMenu))
            this.getOthersSubMenus()
            vm.reset()
          }
        }
      )
    },
    editOthersSubMenu (OthersMenu) {
      this.OthersMenu = OthersMenu
      this.openAddForm = true
    },
    updateOthersSubMenu () {
      const vm = this
      OthersMenuService.updateOthersSubMenu(this.OthersMenu).then(
        response => {
          if (response.data.status) {
            const othersSubMenus = _.find(vm.othersSubMenus, function (p) { return p.id === vm.OthersMenu.id })
            othersSubMenus.load(response.data.objects.othersMenu)
            vm.reset()
            this.openAddForm = false
            this.getOthersSubMenus()
          }
        }
      )
    },
    deleteOthersSubMenu (subMenuId) {
      const vm = this
      OthersMenuService.deleteOthersSubMenu(subMenuId).then(
        response => {
          if (response.data.status) {
            vm.othersSubMenus = _.filter(vm.othersSubMenus, function (p) { return p.id !== subMenuId })
            vm.reset()
            this.openAddForm = false
            this.getOthersSubMenus()
          }
        }
      )
    }
  },
  computed: {
  }
}
</script>
