import Config from './config'
var api = Config.api

const serviceAPIRoot = api.BASE_URL + '/serviceType'

class ServiceTypeService {
  getServiceTypes () {
    return api.getCall(serviceAPIRoot, api.DoNothing, api.ShowAlert)
  }
}
export default new ServiceTypeService()
