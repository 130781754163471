import Config from './config'

const api = Config.api

const serviceAPIRoot = api.BASE_URL + '/service'

class MainServicesService {
  createService (ServiceModel) {
    return api.postCall(serviceAPIRoot, ServiceModel, api.ShowAlert, api.ShowAlert)
  }

  getServices () {
    return api.getCall(serviceAPIRoot, api.DoNothing, api.ShowAlert)
  }

  updateService (ServiceModel) {
    return api.putCall(serviceAPIRoot + '/' + ServiceModel.id, ServiceModel, api.ShowAlert, api.ShowAlert)
  }

  deleteService (serviceId) {
    return api.deleteCall(serviceAPIRoot + '/' + serviceId, api.ShowAlert, api.ShowAlert)
  }
}
export default new MainServicesService()
