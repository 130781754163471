import UtilityService from '../services/utility.service'
import VehicleCategoryModel from './VehicleCategoryModel'
import _ from 'lodash'
export default class OfferModel {
  constructor () {
    this.id = 0
    this.title = ''
    this.title_bangla = ''
    this.summary = ''
    this.summary_bangla = ''
    this.details = ''
    this.details_bangla = ''
    this.image_url = ''
    this.offer_photo_file = []
    this.vehicle_type = ''
    this.vehicle_type_id = 0
    this.vehicleCategory = new VehicleCategoryModel(0)
    this.vehicleCategoryChilds = []
    this.brand = ''
    this.brand_id = -1
    this.offer_start = ''
    this.offer_end = ''
    this.status = true
    this.created_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
  }

  load (data) {
    this.id = data.id
    this.title = data.title
    this.title_bangla = data.title_bangla
    this.summary = data.summary
    this.summary_bangla = data.summary_bangla
    this.details = data.details
    this.details_bangla = data.details_bangla
    this.image_url = data.image_url ?? ''
    this.offer_photo_file = data.offer_photo_file ?? []
    this.vehicle_type = data.vehicle_type
    this.vehicle_type_id = data.vehicle_type_id
    this.brand_id = data.brand_id
    this.brand = data.brand
    this.offer_start = data.offer_start
    this.offer_end = data.offer_end
    this.status = data.status === 1
    if (data.vehicleCategoryChilds) {
      this.vehicleCategoryChilds = _.map(data.vehicleCategoryChilds, function (c) {
        return new VehicleCategoryModel().load(c)
      })
    }
    this.created_at = UtilityService.formatDateTime(
      data.created_at,
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      data.updated_at,
      'MM/DD/YY hh:mm A'
    )
    return this
  }
}
