<template>
  <div class='page-heading'>
    <div class='page-title'>
      <div class='row'>
        <div class='col-12 col-md-6 order-md-1 order-last'>
          <h3>All Vehicle Categories</h3>
          <p class='text-subtitle text-muted'>
            View your added vehicle categories and add new ones
          </p>
        </div>
        <div class='col-12 col-md-6 order-md-2 order-first'>
          <nav
            aria-label='breadcrumb'
            class='breadcrumb-header float-start float-lg-end'
          >
            <ol class='breadcrumb'>
              <li class='breadcrumb-item'>
                <router-link :to="{ name: 'home' }">Dashboard</router-link>
              </li>
              <li class='breadcrumb-item active' aria-current='page'>
                All Vehicle Categories
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <section id='multiple-column-form'>
      <div class='row match-height'>
        <div class='col-12'>
          <div class='card'>
            <div class='card-header'>
              <button
                @click='openAddForm = !openAddForm'
                class='card-title btn btn-outline-dark'
              >
                Add Vehicle Category
              </button>
            </div>
            <div v-show='openAddForm' class='card-content'>
              <div class='card-body'>
                <Form
                  class='form'
                  v-slot='{ handleSubmit }'
                  @submit.prevent='createVehicleCategory'
                  :validation-schema='ValidationSchema'
                >
                  <div class='row'>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Title</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='title'
                          placeholder='Vehicle Category Title'
                          v-model="VehicleCategoryNewModel.title"
                        />
                        <ErrorMessage class='text-danger' name='title' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Title (In Bangla)</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='title_bangla'
                          placeholder='Vehicle Category Title (In Bangla)'
                          v-model="VehicleCategoryNewModel.title_bangla"
                        />
                        <ErrorMessage class='text-danger' name='title_bangla' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Vehicle Type</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="type"
                          placeholder="Vehicle Type"
                          v-model="selectedVehicleType"
                          @change="bindVehicleTypes"
                        >
                          <option disabled selected hidden value="">
                            Select Vehicle Type
                          </option>
                          <option v-for="vehicleType in vehicleTypesOptions" :key="vehicleType.id" :value="vehicleType.id">
                            {{ vehicleType.title }}
                          </option>
                        </Field>
                        <ErrorMessage class='text-danger' name='type' />
                      </div>
                    </div>
                    <div class='col-12 d-flex justify-content-end'>
                      <button
                        class='btn btn-primary me-1 mb-1' v-show="VehicleCategoryNewModel.id==='undefined' || VehicleCategoryNewModel.id===0"
                        @click='handleSubmit($event, createVehicleCategory)'
                      >
                        Create
                      </button>
                      <button
                        v-show="VehicleCategoryNewModel.id!=='undefined' && VehicleCategoryNewModel.id!==0"
                        class='btn btn-primary me-1 mb-1'
                        @click='handleSubmit($event, updateVehicleCategory)'
                      >
                        Update
                      </button>
                      <button
                        class='btn btn-secondary me-1 mb-1'
                        @click='reset'
                      >
                        Reset
                      </button>
                      <button
                        class='btn btn-danger me-1 mb-1'
                        @click='openAddForm = false'
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- // Basic multiple Column Form section end -->
    <!-- Basic Tables start -->
    <section class='section'>
      <div class='card'>
        <div class='card-header'>
          <button class='badge bg-success' @click='loadDataTable' v-show="false">
            <i class="fa fa-magic"></i>
          </button>
        </div>
        <div class='card-body'>
          <table class='table table-striped' id='table1'>
            <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Title (Bangla)</th>
              <th>Type</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for='(category, index) in vehicleCategories' :key='category.id' >
              <td>{{ index+1 }}</td>
              <td>{{ category.title }}</td>
              <td>{{ category.title_bangla}}</td>
              <td>{{ category.type_name}}</td>
              <td>
                <button class='badge bg-danger mr-5' @click="deleteVehicleCategory(category.id)">Delete</button>
                <button class='badge bg-primary ml-5' @click="editVehicleCategory(category)">Edit</button>
              </td>
            </tr>
            <tr v-if='!vehicleCategories.length'>
              <td colspan='8' class='text-center'>No entries found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <!-- Basic Tables end -->
  </div>
</template>

<style src='vue-multiselect/dist/vue-multiselect.css'></style>
<style scoped>
</style>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate'
import _ from 'lodash'

import VehicleCategoryNewModel from '../../models/VehicleCategoryNewModel'
import VehicleTypeService from '../../services/vehicleType.service'
import VehicleCategoryService from '../../services/vehicleCategory.service'
import VehicleTypeModel from '../../models/VehicleTypeModel'

export default {
  name: 'VehicleCategory',
  data () {
    return {
      // ValidationSchema: ValidationService.createUpdateSpecification,
      VehicleCategoryNewModel: new VehicleCategoryNewModel(),
      itemBlueprints: [],
      vehicleCategories: [],
      openAddForm: false,
      selectedVehicleType: '',
      vehicleTypesOptions: []
    }
  },
  components: { Form, Field, ErrorMessage },
  watch: {
  },
  mounted () {
    this.getVehicleTypes()
    this.getVehicleCategories()
  },
  methods: {
    loadDataTable: async () => {
      // eslint-disable-next-line no-undef
      setTimeout($GLOBAL.loadTable('#table1'), 100000)
    },
    bindVehicleTypes () {
      this.VehicleCategoryNewModel.type_id = this.selectedVehicleType
      this.VehicleCategoryNewModel.type_name = this.vehicleTypesOptions.filter(item => item.id === this.selectedVehicleType)[0].title
    },
    getVehicleTypes () {
      VehicleTypeService.getVehicleTypes().then((response) => {
        if (response.data.status) {
          this.vehicleTypesOptions = _.map(response.data.objects.vehicleTypes, function (b) {
            return new VehicleTypeModel().load(b)
          })
        }
      })
    },
    getVehicleCategories () {
      this.vehicleCategories = []
      VehicleCategoryService.getVehicleCategories().then(
        response => {
          if (response.data.status) {
            this.itemBlueprints = _.map(response.data.objects.vehicleCategories, function (p) {
              return new VehicleCategoryNewModel().load(p)
            })
            this.vehicleCategories = _.map(response.data.objects.vehicleCategories, function (b) {
              return new VehicleCategoryNewModel().load(b)
            })
          }
        }
      )
    },
    reset () {
      this.VehicleCategoryNewModel = new VehicleCategoryNewModel()
      this.selectedVehicleType = ''
    },
    createVehicleCategory () {
      const vm = this
      VehicleCategoryService.createVehicleCategory(this.VehicleCategoryNewModel).then(
        response => {
          if (response.data.status) {
            vm.vehicleCategories.push(new VehicleCategoryNewModel().load(response.data.objects.vehicleCategory))
            this.getVehicleCategories()
            vm.reset()
          }
        }
      )
    },
    editVehicleCategory (VehicleCategoryNewModel) {
      this.VehicleCategoryNewModel = VehicleCategoryNewModel
      this.selectedVehicleType = this.VehicleCategoryNewModel.type_id
      this.openAddForm = true
    },
    updateVehicleCategory () {
      const vm = this
      VehicleCategoryService.updateVehicleCategory(this.VehicleCategoryNewModel).then(
        response => {
          if (response.data.status) {
            const vehicleCategories = _.find(vm.vehicleCategories, function (p) { return p.id === vm.VehicleCategoryNewModel.id })
            vehicleCategories.load(response.data.objects.vehicleCategory)
            vm.reset()
            this.openAddForm = false
            this.getVehicleCategories()
          }
        }
      )
    },
    deleteVehicleCategory (categoryId) {
      const vm = this
      VehicleCategoryService.deleteVehicleCategory(categoryId).then(
        response => {
          if (response.data.status) {
            vm.vehicleCategories = _.filter(vm.vehicleCategories, function (p) { return p.id !== categoryId })
            vm.reset()
            this.openAddForm = false
            this.getVehicleCategories()
          }
        }
      )
    }
  },
  computed: {
  }
}
</script>
