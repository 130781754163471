<template>
  <div class="page-heading">
    <div class="page-title">
      <div class="row">
        <div class="col-12 col-md-6 order-md-1 order-last">
          <h3>All Properties</h3>
          <p class="text-subtitle text-muted">View your added properties and add new ones</p>
        </div>
        <div class="col-12 col-md-6 order-md-2 order-first">
          <nav aria-label="breadcrumb" class="breadcrumb-header float-start float-lg-end">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link :to="{ name: 'home' }" >Dashboard</router-link></li>
              <li class="breadcrumb-item active" aria-current="page">All Product Properties</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <!-- // Basic multiple Column Form section start -->
    <section id="multiple-column-form">
      <div class="row match-height">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <button @click="openAddForm=!openAddForm" class="card-title btn btn-outline-dark">Add Property</button>
            </div>
            <div v-show="openAddForm" class="card-content">
              <div class="card-body">
                <Form class="form" v-slot="{ handleSubmit }" @submit.prevent="createOrUpdateProperty" :validation-schema="ValidationSchema">
                  <div class="row">
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="first-name-column">Title</label>
                        <Field type="text" class="form-control" v-model="PropertyModel.title" name="title" placeholder="Property title" />
                        <ErrorMessage class="text-danger" name="title" />
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="first-name-column">Title (In Bangla)</label>
                        <Field type="text" class="form-control" v-model="PropertyModel.title_bn" name="title_bn" placeholder="Property title in bangla" />
                        <ErrorMessage class="text-danger" name="title_bn" />
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="first-name-column">Description</label>
                        <Field type="text" class="form-control" v-model="PropertyModel.description" name="description" placeholder="Property description" />
                        <ErrorMessage class="text-danger" name="description" />
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="first-name-column">Description (In Bangla)</label>
                        <Field type="text" class="form-control" v-model="PropertyModel.description_bn" name="description_bn" placeholder="Property description in bangla" />
                        <ErrorMessage class="text-danger" name="description_bn" />
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="first-name-column">Type: </label>
                        <Field as="select" :class="'form-control'" v-model="PropertyModel.isSummary" name="isSummary" placeholder="Property isSummary">
                          <option :value="1">Summary property</option>
                          <option :value="0">Detail property</option>
                        </Field>
                        <ErrorMessage class="text-danger" name="isSummary"/>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="first-name-column">Parent Property: </label>
                        <Field as="select" :class="'form-control'" v-model="PropertyModel.parent_propertyId" name="parent_propertyId"
                               placeholder="Property parent_propertyId">
                          <option selected :value="0">Root Property</option>
                          <option v-for="property in properties" :key="property.id" :value="property.id">{{ property.title }}</option>
                        </Field>
                        <ErrorMessage class="text-danger" name="parent_propertyId"/>
                      </div>
                    </div>
                    <div class="form-group col-12">
                      <div class='form-check'>
                        <div class="checkbox">
                          <input type="checkbox" id="checkbox5" class='form-check-input'
                                 checked>
                          <label for="checkbox5">Active</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 d-flex justify-content-end">
                      <button class="btn btn-primary me-1 mb-1" v-show="!PropertyModel.id" @click="handleSubmit($event, createOrUpdateProperty)">Create</button>
                      <button class="btn btn-primary me-1 mb-1" v-show="PropertyModel.id" @click="handleSubmit($event, updateProperty)">Update</button>
                      <button class="btn btn-light-secondary me-1 mb-1" @click="reset">Reset</button>
                      <button class="btn btn-light-danger me-1 mb-1" @click="openAddForm=false">Cancel</button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- // Basic multiple Column Form section end -->
    <!-- Basic Tables start -->
    <section class="section">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-12">
              <button class="badge bg-success" @click='loadDataTable' v-show="false">
                <i class="fa fa-magic"></i>
              </button>
              <button v-if="inTableProperties.length && inTableProperties[0].parent_propertyId !== 0" class="badge bg-success float-right" @click="viewRootProperties">Back to Parent ↩</button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <table class="table table-striped" id="table1">
            <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Description</th>
              <th>Summary Visibility</th>
              <th>Parent Property</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="property in inTableProperties" :key="property.id">
              <td>{{ property.id }}</td>
              <td>{{ property.title }}</td>
              <td>{{ property.description }}</td>
              <td>{{ property.isSummary ? 'true' : 'false' }}</td>
              <td>{{ property.parent_propertyId ? getPropertyName(property.parent_propertyId) : 'Root'}}</td>
              <td>
                <span class="badge bg-light-success">Active</span>
              </td>
              <td>
                <button class="badge bg-danger mr-5" @click="deleteProperty(property.id)">Delete</button>
                <button class="badge bg-primary ml-5 mr-5" @click="editProperty(property)">Edit</button>
                <button v-if="property.childs.length" class="badge bg-info ml-5" @click="viewChildProperties(property)">({{property.childs.length}})→</button>
              </td>
            </tr>
            <tr v-if="!inTableProperties.length">
              <td colspan="7" class="text-center">No entries found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

    </section>
    <!-- Basic Tables end -->
  </div>

</template>

<script>

import { Field, Form, ErrorMessage } from 'vee-validate'
import _ from 'lodash'

import PropertyService from '../../services/property.service'
import ValidationService from '../../services/validation.service'
import PropertyModel from '../../models/PropertyModel'

export default {
  name: 'Property',
  data () {
    return {
      ValidationSchema: ValidationService.createUpdateProperty,
      properties: [],
      PropertyModel: new PropertyModel(),
      openAddForm: false,
      inTableProperties: []
    }
  },
  components: { Form, Field, ErrorMessage },
  mounted () {
    this.getProperties()
  },
  methods: {
    loadDataTable: async () => {
      // eslint-disable-next-line no-undef
      setTimeout($GLOBAL.loadTable('#table1'), 100000)
    },
    getPropertyName: function (PropertyId) {
      return _.find(this.properties, p => p.id === PropertyId).title
    },
    getProperties () {
      this.properties = []
      PropertyService.getProperties().then(
        response => {
          if (response.data.status) {
            this.properties = _.map(response.data.objects.properties, function (p) {
              return new PropertyModel().load(p)
            })
            this.inTableProperties = this.properties
          }
        }
      )
    },
    createOrUpdateProperty () {
      const vm = this
      PropertyService.createProperty(this.PropertyModel).then(
        response => {
          if (response.data.status) {
            vm.properties.push(new PropertyModel().load(response.data.objects.property))
            vm.reset()
          }
        }
      )
    },
    deleteProperty (PropertyId) {
      const vm = this
      PropertyService.deleteProperty(PropertyId).then(
        response => {
          if (response.data.status) {
            vm.properties = _.filter(vm.properties, function (p) { return p.id !== PropertyId })
          }
        }
      )
    },
    editProperty (PropertyModel) {
      this.PropertyModel = PropertyModel
      this.openAddForm = true
    },
    viewChildProperties (Property) {
      this.inTableProperties = Property.childs
    },
    viewRootProperties () {
      this.inTableProperties = this.properties
    },
    updateProperty () {
      const vm = this
      PropertyService.updateProperty(this.PropertyModel).then(
        response => {
          if (response.data.status) {
            const property = _.find(vm.properties, function (p) { return p.id === vm.PropertyModel.id })
            property.load(response.data.objects.property)
          }
        }
      )
    },
    reset () {
      this.PropertyModel = new PropertyModel()
    }
  }

}
</script>
