import Config from './config'
var api = Config.api

const offerAPIRoot = api.BASE_URL + '/offer'

class OfferService {
  createOffer (OfferModel) {
    return api.postCall(
      offerAPIRoot,
      OfferModel,
      api.ShowAlert,
      api.ShowAlert
    )
  }

  // createBusinessesInBulk (BusinessesModel) {
  //   return api.postCall(offerAPIRoot + '/bulk-create', BusinessesModel, api.ShowAlert, api.ShowAlert)
  // }

  getOffers () {
    return api.getCall(offerAPIRoot, api.DoNothing, api.ShowAlert)
  }

  getOfferById (offerId) {
    return api.getCall(offerAPIRoot + '/' + offerId, api.DoNothing, api.ShowAlert)
  }

  updateOffer (OfferModel) {
    return api.putCall(offerAPIRoot + '/' + OfferModel.id, OfferModel, api.ShowAlert, api.ShowAlert)
  }

  deleteOffer (offerId) {
    return api.deleteCall(offerAPIRoot + '/' + offerId, api.ShowAlert, api.ShowAlert)
  }
}
export default new OfferService()
