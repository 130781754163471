<template>
  <div class='page-heading'>
    <div class='page-title'>
      <div class='row'>
        <div class='col-12 col-md-6 order-md-1 order-last'>
          <h3>All Vehicle Subcategories</h3>
          <p class='text-subtitle text-muted'>
            View your added vehicle subcategories and add new ones
          </p>
        </div>
        <div class='col-12 col-md-6 order-md-2 order-first'>
          <nav
            aria-label='breadcrumb'
            class='breadcrumb-header float-start float-lg-end'
          >
            <ol class='breadcrumb'>
              <li class='breadcrumb-item'>
                <router-link :to="{ name: 'home' }">Dashboard</router-link>
              </li>
              <li class='breadcrumb-item active' aria-current='page'>
                All Vehicle Subcategories
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <section id='multiple-column-form'>
      <div class='row match-height'>
        <div class='col-12'>
          <div class='card'>
            <div class='card-header'>
              <button
                @click='openAddForm = !openAddForm'
                class='card-title btn btn-outline-dark'
              >
                Add Vehicle Subcategory
              </button>
            </div>
            <div v-show='openAddForm' class='card-content'>
              <div class='card-body'>
                <Form
                  class='form'
                  v-slot='{ handleSubmit }'
                  @submit.prevent='createVehicleSubCategory'
                  :validation-schema='ValidationSchema'
                >
                  <div class='row'>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Title</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='title'
                          placeholder='Vehicle Subcategory Title'
                          v-model="VehicleSubCategoryModel.title"
                        />
                        <ErrorMessage class='text-danger' name='title' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Title (In Bangla)</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='title_bangla'
                          placeholder='Vehicle Subcategory Title (In Bangla)'
                          v-model="VehicleSubCategoryModel.title_bangla"
                        />
                        <ErrorMessage class='text-danger' name='title_bangla' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class="form-group">
                        <label for="imageUrl">Vehicle Subcategory Image</label>
                        <span class="small-text">Please upload Portrait (Square) photo for better view.</span>
                        <Field @change="onSubcategoryThumbnailUpload" type="file" class="form-control" name="subcategoryImage" placeholder="Vehicle Subcategory Image" id="input_file"/>
                        <ErrorMessage class="text-danger" name="subcategoryImage" />
                        <img class="img" width="200" style="margin-top: 10px" :src="editImageURL" :alt="thumbnail">
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Vehicle Category</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="type"
                          placeholder="Vehicle Category"
                          v-model="selectedVehicleCategory"
                          @change="bindVehicleCategories"
                        >
                          <option disabled selected hidden value="">
                            Select Vehicle Category
                          </option>
                          <option v-for="vehicleCategory in vehicleCategoriesOptions" :key="vehicleCategory.id" :value="vehicleCategory.id">
                            {{ vehicleCategory.title }}
                          </option>
                        </Field>
                        <ErrorMessage class='text-danger' name='type' />
                      </div>
                    </div>
                    <div class='col-12 d-flex justify-content-end'>
                      <button
                        class='btn btn-primary me-1 mb-1' v-show="VehicleSubCategoryModel.id==='undefined' || VehicleSubCategoryModel.id===0"
                        @click='handleSubmit($event, createVehicleSubCategory)'
                      >
                        Create
                      </button>
                      <button
                        v-show="VehicleSubCategoryModel.id!=='undefined' && VehicleSubCategoryModel.id!==0"
                        class='btn btn-primary me-1 mb-1'
                        @click='handleSubmit($event, updateVehicleSubCategory)'
                      >
                        Update
                      </button>
                      <button
                        class='btn btn-secondary me-1 mb-1'
                        @click='reset'
                      >
                        Reset
                      </button>
                      <button
                        class='btn btn-danger me-1 mb-1'
                        @click='openAddForm = false'
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- // Basic multiple Column Form section end -->
    <!-- Basic Tables start -->
    <section class='section'>
      <div class='card'>
        <div class='card-header'>
          <button class='badge bg-success' @click='loadDataTable' v-show="false">
            <i class="fa fa-magic"></i>
          </button>
        </div>
        <div class='card-body'>
          <table class='table table-striped' id='table1'>
            <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Title (Bangla)</th>
              <th>Thumbnail</th>
              <th>Category</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for='(subcategory, index) in vehicleSubCategories' :key='subcategory.id' >
              <td>{{ index+1 }}</td>
              <td>{{ subcategory.title }}</td>
              <td>{{ subcategory.title_bangla}}</td>
              <td><img class="img" width="140" :src="subcategory.thumbnail" :alt="subcategory.title.toLocaleUpperCase()"></td>
              <td>{{ subcategory.category_name}}</td>
              <td>
                <button class='badge bg-danger mr-5' @click="deleteVehicleSubCategory(subcategory.id)">Delete</button>
                <button class='badge bg-primary ml-5' @click="editVehicleSubCategory(subcategory)">Edit</button>
              </td>
            </tr>
            <tr v-if='!vehicleSubCategories.length'>
              <td colspan='8' class='text-center'>No entries found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <!-- Basic Tables end -->
  </div>
</template>

<style scoped>
.small-text{
  display: block;
  font-size: 12px;
  color: #555555;
}
</style>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate'
import _ from 'lodash'

import VehicleSubCategoryModel from '../../models/VehicleSubCategoryModel'
import VehicleCategoryNewModel from '../../models/VehicleCategoryNewModel'
import VehicleCategoryService from '../../services/vehicleCategory.service'
import VehicleSubCategoryService from '../../services/vehicleSubCategory.service'
import FileService from '../../services/file.service'
export default {
  name: 'VehicleSubCategory',
  data () {
    return {
      // ValidationSchema: ValidationService.createUpdateSpecification,
      VehicleSubCategoryModel: new VehicleSubCategoryModel(),
      itemBlueprints: [],
      vehicleSubCategories: [],
      openAddForm: false,
      selectedVehicleCategory: '',
      vehicleCategoriesOptions: [],
      subcategoryImage: [],
      editImageURL: ''
    }
  },
  components: { Form, Field, ErrorMessage },
  watch: {
  },
  mounted () {
    this.getVehicleCategories()
    this.getVehicleSubCategories()
  },
  methods: {
    loadDataTable: async () => {
      // eslint-disable-next-line no-undef
      setTimeout($GLOBAL.loadTable('#table1'), 100000)
    },
    onSubcategoryThumbnailUpload (event) {
      const files = event.target.files ? event.target.files : null
      this.subcategoryImage = []
      if (!files.length) { return }
      FileService.prepareFileForUpload(files, this.VehicleSubCategoryModel.id, 'brand-thumbnail').then(
        response => {
          this.subcategoryImage = response
          this.VehicleSubCategoryModel.subcategory_photo_file = response
        },
        error => {
          console.log(error)
          alert('Error while trying to process uploaded files')
        }
      )
    },
    bindVehicleCategories () {
      this.VehicleSubCategoryModel.category_id = this.selectedVehicleCategory
      this.VehicleSubCategoryModel.category_name = this.vehicleCategoriesOptions.filter(item => item.id === this.selectedVehicleCategory)[0].title
    },
    getVehicleCategories () {
      VehicleCategoryService.getVehicleCategories().then((response) => {
        if (response.data.status) {
          this.vehicleCategoriesOptions = _.map(response.data.objects.vehicleCategories, function (b) {
            return new VehicleCategoryNewModel().load(b)
          })
        }
      })
    },
    getVehicleSubCategories () {
      this.vehicleSubCategories = []
      VehicleSubCategoryService.getVehicleSubCategories().then(
        response => {
          if (response.data.status) {
            this.itemBlueprints = _.map(response.data.objects.vehicleSubCategories, function (p) {
              return new VehicleSubCategoryModel().load(p)
            })
            this.vehicleSubCategories = _.map(response.data.objects.vehicleSubCategories, function (b) {
              return new VehicleSubCategoryModel().load(b)
            })
          }
        }
      )
    },
    reset () {
      this.VehicleSubCategoryModel = new VehicleSubCategoryModel()
      this.selectedVehicleCategory = ''
      document.getElementById('input_file').value = ''
    },
    createVehicleSubCategory () {
      const vm = this
      VehicleSubCategoryService.createVehicleSubCategory(this.VehicleSubCategoryModel).then(
        response => {
          if (response.data.status) {
            vm.vehicleSubCategories.push(new VehicleSubCategoryModel().load(response.data.objects.vehicleSubCategory))
            this.getVehicleSubCategories()
            vm.reset()
          }
        }
      )
    },
    editVehicleSubCategory (VehicleSubCategoryModel) {
      this.VehicleSubCategoryModel = VehicleSubCategoryModel
      this.selectedVehicleCategory = this.VehicleSubCategoryModel.category_id
      this.editImageURL = this.VehicleSubCategoryModel.thumbnail
      this.openAddForm = true
    },
    updateVehicleSubCategory () {
      const vm = this
      VehicleSubCategoryService.updateVehicleSubCategory(this.VehicleSubCategoryModel).then(
        response => {
          if (response.data.status) {
            const vehicleSubCategories = _.find(vm.vehicleSubCategories, function (p) { return p.id === vm.VehicleSubCategoryModel.id })
            vehicleSubCategories.load(response.data.objects.vehicleSubCategory)
            vm.reset()
            this.openAddForm = false
            this.getVehicleSubCategories()
          }
        }
      )
    },
    deleteVehicleSubCategory (subCategoryId) {
      const vm = this
      VehicleSubCategoryService.deleteVehicleSubCategory(subCategoryId).then(
        response => {
          if (response.data.status) {
            vm.vehicleSubCategories = _.filter(vm.vehicleSubCategories, function (p) { return p.id !== subCategoryId })
            vm.reset()
            this.openAddForm = false
            this.getVehicleSubCategories()
          }
        }
      )
    }
  },
  computed: {
  }
}
</script>
