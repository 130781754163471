<template>
  <div class='page-heading'>
    <div class='page-title'>
      <div class='row'>
        <div class='col-12 col-md-6 order-md-1 order-last'>
          <h3>All Brands</h3>
          <p class='text-subtitle text-muted'>
            View your added brands and add new ones
          </p>
        </div>
        <div class='col-12 col-md-6 order-md-2 order-first'>
          <nav
            aria-label='breadcrumb'
            class='breadcrumb-header float-start float-lg-end'
          >
            <ol class='breadcrumb'>
              <li class='breadcrumb-item'>
                <router-link :to="{ name: 'home' }">Dashboard</router-link>
              </li>
              <li class='breadcrumb-item active' aria-current='page'>
                All Brands
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
    <!-- Basic Tables start -->
<!--    <section class="section">-->
<!--      <div class="card">-->
<!--        <div class="card-header">-->
<!--          Bulk upload from excel-->
<!--        </div>-->
<!--        <div class="card-body">-->
<!--          <ExcelTool @ExcelProductsUpload="OnExcelItemsUpload" :itemType="'business'" :products="itemBlueprints" :payload = "payload"></ExcelTool>-->
<!--        </div>-->
<!--      </div>-->

<!--    </section>-->
    <!-- // Basic multiple Column Form section start -->
    <section id='multiple-column-form'>
      <div class='row match-height'>
        <div class='col-12'>
          <div class='card'>
            <div class='card-header'>
              <button
                @click='openAddForm = !openAddForm'
                class='card-title btn btn-outline-dark'
              >
                Add Brand
              </button>
            </div>
            <div v-show='openAddForm' class='card-content'>
              <div class='card-body'>
                <Form
                  class='form'
                  v-slot='{ handleSubmit }'
                  @submit.prevent='createBrand'
                  :validation-schema='ValidationSchema'
                >
                  <div class='row'>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Title</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='title'
                          placeholder='Brand Title'
                          v-model="BrandModelNew.title"
                        />
                        <ErrorMessage class='text-danger' name='title' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Title (In Bangla)</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='title_bangla'
                          placeholder='Brand Title (In Bangla)'
                          v-model="BrandModelNew.title_bangla"
                        />
                        <ErrorMessage class='text-danger' name='title_bangla' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class="form-group">
                        <label for="imageUrl">Brand Thumbnail Image</label>
                        <span class="small-text">Please upload Portrait (Square) photo for better view.</span>
                        <Field @change="onBrandThumbnailUpload" type="file" class="form-control" name="brandImage" placeholder="Brand Image" id="input_file"/>
                        <ErrorMessage class="text-danger" name="brandImage" />
                        <img class="img" width="200" style="margin-top: 10px" :src="editImageURL" :alt="thumbnail">
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Vehicle Type</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="type"
                          placeholder="Vehicle Type"
                          v-model="selectedVehicleType"
                          @change="bindVehicleTypes"
                        >
                          <option disabled selected hidden value="">
                            Select Vehicle Type
                          </option>
                          <option v-for="vehicleType in vehicleTypesOptions" :key="vehicleType.id" :value="vehicleType.id">
                            {{ vehicleType.title }}
                          </option>
                        </Field>
                        <ErrorMessage class='text-danger' name='type' />
                      </div>
                    </div>
                    <div class='col-12 d-flex justify-content-end'>
                      <button
                        class='btn btn-primary me-1 mb-1' v-show="BrandModelNew.id==='undefined' || BrandModelNew.id===0"
                        @click='handleSubmit($event, createBrand)'
                      >
                        Create
                      </button>
                      <button
                        v-show="BrandModelNew.id!=='undefined' && BrandModelNew.id!==0"
                        class='btn btn-primary me-1 mb-1'
                        @click='handleSubmit($event, updateBrand)'
                      >
                        Update
                      </button>
                      <button
                        class='btn btn-secondary me-1 mb-1'
                        @click='reset'
                      >
                        Reset
                      </button>
                      <button
                        class='btn btn-danger me-1 mb-1'
                        @click='openAddForm = false'
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- // Basic multiple Column Form section end -->
    <!-- Basic Tables start -->
    <section class='section'>
      <div class='card'>
        <div class='card-header'>
          <button class='badge bg-success' @click='loadDataTable' v-show="false">
            <i class="fa fa-magic"></i>
          </button>
        </div>
        <div class='card-body'>
          <table class='table table-striped' id='table1'>
            <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Title (Bangla)</th>
              <th>Thumbnail</th>
              <th>Type</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for='(brand, index) in brands' :key='brand.id' >
              <td>{{ index+1 }}</td>
              <td>{{ brand.title }}</td>
              <td>{{ brand.title_bangla }}</td>
              <td><img class="img img-thumbnail brand-thumb" :src="brand.thumbnail" :alt="brand.title.toLocaleUpperCase()"></td>
              <td>{{ brand.type_name}}</td>
              <td>
                <button class='badge bg-danger mr-5' @click="deleteOffer(brand.id)">Delete</button>
                <button class='badge bg-primary ml-5' @click="editOffer(brand)">Edit</button>
              </td>
            </tr>
            <tr v-if='!brands.length'>
              <td colspan='6' class='text-center'>No entries found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <!-- Basic Tables end -->
  </div>
</template>

<style src='vue-multiselect/dist/vue-multiselect.css'></style>
<style scoped>
.small-text{
  display: block;
  font-size: 12px;
  color: #555555;
}
.brand-thumb{
  min-height: 10px;
  max-height: 50px;
  max-width: 60px;
  min-width: 60px;
}
</style>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate'
import _ from 'lodash'

import BrandModelNew from '../../models/BrandModelNew'
import BrandService from '../../services/brand.service'
import FileService from '../../services/file.service'
import VehicleTypeService from '../../services/vehicleType.service'
import VehicleTypeModel from '../../models/VehicleTypeModel'
export default {
  name: 'Brand',
  data () {
    return {
      // ValidationSchema: ValidationService.createUpdateSpecification,
      BrandModelNew: new BrandModelNew(),
      itemBlueprints: [],
      openAddForm: false,
      brands: [],
      brandImage: [],
      editImageURL: '',
      selectedVehicleType: '',
      vehicleTypesOptions: []
    }
  },
  components: { Form, Field, ErrorMessage },
  watch: {
  },
  mounted () {
    this.getVehicleTypes()
    this.getBrands()
  },
  methods: {
    loadDataTable: async () => {
      // eslint-disable-next-line no-undef
      setTimeout($GLOBAL.loadTable('#table1'), 100000)
    },
    bindVehicleTypes () {
      this.BrandModelNew.type_id = this.selectedVehicleType
      this.BrandModelNew.type_name = this.vehicleTypesOptions.filter(item => item.id === this.selectedVehicleType)[0].title
    },
    getVehicleTypes () {
      VehicleTypeService.getVehicleTypes().then((response) => {
        if (response.data.status) {
          this.vehicleTypesOptions = _.map(response.data.objects.vehicleTypes, function (b) {
            return new VehicleTypeModel().load(b)
          })
        }
      })
    },
    onBrandThumbnailUpload (event) {
      const files = event.target.files ? event.target.files : null
      this.brandImage = []
      if (!files.length) { return }
      FileService.prepareFileForUpload(files, this.BrandModelNew.id, 'brand-thumbnail').then(
        response => {
          this.brandImage = response
          this.BrandModelNew.brand_photo_file = response
        },
        error => {
          console.log(error)
          alert('Error while trying to process uploaded files')
        }
      )
    },
    getBrands () {
      BrandService.getBrands().then((response) => {
        if (response.data.status) {
          this.brands = _.map(response.data.objects.brands, function (b) {
            return new BrandModelNew().load(b)
          })
          // this.brands = _.map(response.data.objects.brands, function (brand) {
          //   return {
          //     brand_id: brand.id,
          //     brand_name: brand.title,
          //     brand_name_bangla: brand.title_bangla
          //   }
          // })
        }
      })
    },
    reset () {
      this.BrandModelNew = new BrandModelNew()
      this.selectedVehicleType = ''
      document.getElementById('input_file').value = ''
    },
    createBrand () {
      const vm = this
      BrandService.createBrand(this.BrandModelNew).then(
        response => {
          if (response.data.status) {
            vm.brands.push(new BrandModelNew().load(response.data.objects.brand))
            this.getBrands()
            vm.reset()
          }
        }
      )
    },
    editOffer (BrandModelNew) {
      this.BrandModelNew = BrandModelNew
      this.editImageURL = this.BrandModelNew.thumbnail
      this.selectedVehicleType = this.BrandModelNew.type_id
      this.openAddForm = true
    },
    updateBrand () {
      const vm = this
      BrandService.updateBrand(this.BrandModelNew).then(
        response => {
          if (response.data.status) {
            const brands = _.find(vm.brands, function (p) { return p.id === vm.BrandModelNew.id })
            brands.load(response.data.objects.brand)
            vm.reset()
            this.openAddForm = false
            this.getBrands()
          }
        }
      )
    },
    deleteOffer (brandId) {
      const vm = this
      BrandService.deleteBrand(brandId).then(
        response => {
          if (response.data.status) {
            vm.offers = _.filter(vm.offers, function (p) { return p.id !== brandId })
            vm.reset()
            this.openAddForm = false
            this.getBrands()
          }
        }
      )
    }
  },
  computed: {
  }
}
</script>
