<template>
  <div class='page-heading'>
    <div class='page-title'>
      <div class='row'>
        <div class='col-12 col-md-6 order-md-1 order-last'>
          <h3>All Main Services</h3>
          <p class='text-subtitle text-muted'>
            View your added services and add new ones
          </p>
        </div>
        <div class='col-12 col-md-6 order-md-2 order-first'>
          <nav
            aria-label='breadcrumb'
            class='breadcrumb-header float-start float-lg-end'
          >
            <ol class='breadcrumb'>
              <li class='breadcrumb-item'>
                <router-link :to="{ name: 'home' }">Dashboard</router-link>
              </li>
              <li class='breadcrumb-item active' aria-current='page'>
                All Main Services
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <section id='multiple-column-form'>
      <div class='row match-height'>
        <div class='col-12'>
          <div class='card'>
            <div class='card-header'>
              <button
                @click='openAddForm = !openAddForm'
                class='card-title btn btn-outline-dark'
              >
                Add Service
              </button>
            </div>
            <div v-show='openAddForm' class='card-content'>
              <div class='card-body'>
                <Form
                  class='form'
                  v-slot='{ handleSubmit }'
                  @submit.prevent='createService'
                  :validation-schema='ValidationSchema'
                >
                  <div class='row'>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Title</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='title'
                          placeholder='Service Title'
                          v-model="ServiceModel.title"
                        />
                        <ErrorMessage class='text-danger' name='title' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Title (In Bangla)</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='title_bangla'
                          placeholder='Service Title (In Bangla)'
                          v-model="ServiceModel.title_bangla"
                        />
                        <ErrorMessage class='text-danger' name='title_bangla' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class="form-group">
                        <label for="imageUrl">Service Image</label>
                        <span class="small-text">Please upload Portrait (Square) photo for better view.</span>
                        <Field @change="onServiceThumbnailUpload" type="file" class="form-control" name="serviceImage" placeholder="Service Image" id="input_file"/>
                        <ErrorMessage class="text-danger" name="serviceImage" />
                        <img class="img" width="200" style="margin-top: 10px" :src="editImageURL" :alt="thumbnail">
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Navigation URL</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='navigation_url'
                          placeholder='Service Navigation URL'
                          v-model="ServiceModel.navigation_url"
                        />
                        <ErrorMessage class='text-danger' name='navigation_url' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Vehicle Type</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="type"
                          placeholder="Vehicle Type"
                          v-model="selectedVehicleType"
                          @change="bindVehicleTypes"
                        >
                          <option disabled selected hidden value="">
                            Select Vehicle Type
                          </option>
                          <option v-for="vehicleType in vehicleTypesOptions" :key="vehicleType.id" :value="vehicleType.id">
                            {{ vehicleType.title }}
                          </option>
                        </Field>
                        <ErrorMessage class='text-danger' name='type' />
                      </div>
                    </div>
                    <div class='col-12 d-flex justify-content-end'>
                      <button
                        class='btn btn-primary me-1 mb-1' v-show="ServiceModel.id==='undefined' || ServiceModel.id===0"
                        @click='handleSubmit($event, createService)'
                      >
                        Create
                      </button>
                      <button
                        v-show="ServiceModel.id!=='undefined' && ServiceModel.id!==0"
                        class='btn btn-primary me-1 mb-1'
                        @click='handleSubmit($event, updateService)'
                      >
                        Update
                      </button>
                      <button
                        class='btn btn-secondary me-1 mb-1'
                        @click='reset'
                      >
                        Reset
                      </button>
                      <button
                        class='btn btn-danger me-1 mb-1'
                        @click='openAddForm = false'
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- // Basic multiple Column Form section end -->
    <!-- Basic Tables start -->
    <section class='section'>
      <div class='card'>
        <div class='card-header'>
          <button class='badge bg-success' @click='loadDataTable' v-show="false">
            <i class="fa fa-magic"></i>
          </button>
        </div>
        <div class='card-body'>
          <table class='table table-striped' id='table1'>
            <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Thumbnail</th>
              <th>Navigation URL</th>
              <th>Type</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for='(service, index) in services' :key='service.id' >
              <td>{{ index+1 }}</td>
              <td>{{ service.title }}</td>
              <td><img class="img" width="140" :src="service.thumbnail" :alt="service.title.toLocaleUpperCase()"></td>
              <td>{{ service.navigation_url}}</td>
              <td>{{ service.type_name}}</td>
              <td>
                <button class='badge bg-danger mr-5' @click="deleteService(service.id)">Delete</button>
                <button class='badge bg-primary ml-5' @click="editService(service)">Edit</button>
              </td>
            </tr>
            <tr v-if='!services.length'>
              <td colspan='8' class='text-center'>No entries found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <!-- Basic Tables end -->
  </div>
</template>

<style scoped>
.small-text{
  display: block;
  font-size: 12px;
  color: #555555;
}
</style>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate'
import _ from 'lodash'

import ServiceModel from '../../models/ServiceModel'
import MainServicesService from '../../services/service.service'
import FileService from '../../services/file.service'
import VehicleTypeService from '../../services/vehicleType.service'
import VehicleTypeModel from '../../models/VehicleTypeModel'
export default {
  name: 'VehicleSubCategory',
  data () {
    return {
      // ValidationSchema: ValidationService.createUpdateSpecification,
      ServiceModel: new ServiceModel(),
      itemBlueprints: [],
      services: [],
      openAddForm: false,
      serviceImage: [],
      editImageURL: '',
      selectedVehicleType: '',
      vehicleTypesOptions: []
    }
  },
  components: { Form, Field, ErrorMessage },
  watch: {
  },
  mounted () {
    this.getVehicleTypes()
    this.getServices()
  },
  methods: {
    loadDataTable: async () => {
      // eslint-disable-next-line no-undef
      setTimeout($GLOBAL.loadTable('#table1'), 100000)
    },
    bindVehicleTypes () {
      this.ServiceModel.type_id = this.selectedVehicleType
      this.ServiceModel.type_name = this.vehicleTypesOptions.filter(item => item.id === this.selectedVehicleType)[0].title
    },
    getVehicleTypes () {
      VehicleTypeService.getVehicleTypes().then((response) => {
        if (response.data.status) {
          this.vehicleTypesOptions = _.map(response.data.objects.vehicleTypes, function (b) {
            return new VehicleTypeModel().load(b)
          })
        }
      })
    },
    onServiceThumbnailUpload (event) {
      const files = event.target.files ? event.target.files : null
      this.serviceImage = []
      if (!files.length) { return }
      FileService.prepareFileForUpload(files, this.ServiceModel.id, 'brand-thumbnail').then(
        response => {
          this.serviceImage = response
          this.ServiceModel.service_photo_file = response
        },
        error => {
          console.log(error)
          alert('Error while trying to process uploaded files')
        }
      )
    },
    getServices () {
      this.services = []
      MainServicesService.getServices().then(
        response => {
          if (response.data.status) {
            this.itemBlueprints = _.map(response.data.objects.services, function (p) {
              return new ServiceModel().load(p)
            })
            this.services = _.map(response.data.objects.services, function (b) {
              return new ServiceModel().load(b)
            })
          }
        }
      )
    },
    reset () {
      this.ServiceModel = new ServiceModel()
      this.selectedVehicleType = ''
      document.getElementById('input_file').value = ''
    },
    createService () {
      const vm = this
      MainServicesService.createService(this.ServiceModel).then(
        response => {
          if (response.data.status) {
            vm.services.push(new ServiceModel().load(response.data.objects.service))
            this.getServices()
            vm.reset()
          }
        }
      )
    },
    editService (ServiceModel) {
      this.ServiceModel = ServiceModel
      this.editImageURL = this.ServiceModel.thumbnail
      this.selectedVehicleType = this.ServiceModel.type_id
      this.openAddForm = true
    },
    updateService () {
      const vm = this
      MainServicesService.updateService(this.ServiceModel).then(
        response => {
          if (response.data.status) {
            const services = _.find(vm.services, function (p) { return p.id === vm.ServiceModel.id })
            services.load(response.data.objects.service)
            vm.reset()
            this.openAddForm = false
            this.getServices()
          }
        }
      )
    },
    deleteService (serviceId) {
      const vm = this
      MainServicesService.deleteService(serviceId).then(
        response => {
          if (response.data.status) {
            vm.services = _.filter(vm.services, function (p) { return p.id !== serviceId })
            vm.reset()
            this.openAddForm = false
            this.getServices()
          }
        }
      )
    }
  },
  computed: {
  }
}
</script>
