<template>
  <div class='page-heading'>
    <div class='page-title'>
      <div class='row'>
        <div class='col-12 col-md-6 order-md-1 order-last'>
          <h3>Terms and Conditions</h3>
          <p class='text-subtitle text-muted'>
            View your Terms and Conditions and update it
          </p>
        </div>
        <div class='col-12 col-md-6 order-md-2 order-first'>
          <nav
            aria-label='breadcrumb'
            class='breadcrumb-header float-start float-lg-end'
          >
            <ol class='breadcrumb'>
              <li class='breadcrumb-item'>
                <router-link :to="{ name: 'home' }">Dashboard</router-link>
              </li>
              <li class='breadcrumb-item active' aria-current='page'>
                Terms and Conditions
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <section id='multiple-column-form'>
      <div class='row match-height'>
        <div class='col-12'>
          <div class='card'>
            <div class='card-header'>
              <button
                @click='openAddForm = !openAddForm'
                class='card-title btn btn-outline-dark'
                v-if="termsAndConditions.length===0"
              >
                Add Terms and Conditions
              </button>
              <button
                @click='editTermsAndConditions(termsAndConditions[0])'
                class='card-title btn btn-outline-dark'
                v-else
              >
                Edit Terms and Conditions
              </button>
            </div>
            <div v-show='openAddForm' class='card-content'>
              <div class='card-body'>
                <Form
                  class='form'
                  v-slot='{ handleSubmit }'
                  @submit.prevent='createOrUpdateTermsAndConditions'
                  :validation-schema='ValidationSchema'
                >
                  <div class='row'>
                    <div class='col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Details</label>
                        <TinymceEditor ref="tinyMce" v-model:Body="TermsAndConditionsModel.details" :menubar="'visible'" name="content" class="mb-3"   v-validate="'required'" v-model.trim="TermsAndConditionsModel.details" :placeholder="'Write your page content here...'" ></TinymceEditor>
                        <ErrorMessage class='text-danger' name='details' />
                      </div>
                    </div>
                    <div class='col-12'>
                      <div class='form-group'>
                        <label for='spec-value'>Details (In Bangla)</label>
                        <TinymceEditor ref="tinyMce2" v-model:Body="TermsAndConditionsModel.details_bangla" :menubar="'visible'" name="content" class="mb-3"   v-validate="'required'" v-model.trim="TermsAndConditionsModel.details_bangla" :placeholder="'Write your page content here...'" ></TinymceEditor>
                        <ErrorMessage class='text-danger' name='details_bangla' />
                      </div>
                    </div>
                    <div class='col-12 d-flex justify-content-end'>
                      <button
                        class='btn btn-primary me-1 mb-1'
                        @click='handleSubmit($event, updateTermsAndConditions)'
                      >
                        Update
                      </button>
                      <button
                        class='btn btn-secondary me-1 mb-1'
                        @click='reset'
                      >
                        Reset
                      </button>
                      <button
                        class='btn btn-danger me-1 mb-1'
                        @click='openAddForm = false'
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- // Basic multiple Column Form section end -->
    <!-- Basic Tables start -->
    <section class='section'>
      <div class='card'>
        <div class='card-header'>
          <button class='badge bg-success' @click='loadDataTable' v-show="false">
            <i class="fa fa-magic"></i>
          </button>
        </div>
        <div class='card-body'>
          <table class='table table-striped' id='table1'>
            <thead>
            <tr>
              <th>#</th>
              <th>Details</th>
              <th>Details (Bangla)</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for='(data, index) in termsAndConditions' :key='data.id' >
              <td>{{ index+1 }}</td>
              <td v-html="data.details"></td>
              <td v-html="data.details_bangla"></td>
              <td>
                <button class='badge bg-danger mr-5' @click="deleteTermsAndConditions(data.id)">Delete</button>
                <button class='badge bg-primary ml-5' @click="editTermsAndConditions(data)">Edit</button>
              </td>
            </tr>
            <tr v-if='!termsAndConditions.length'>
              <td colspan='4' class='text-center'>No entries found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <!-- Basic Tables end -->
  </div>
</template>

<style src='vue-multiselect/dist/vue-multiselect.css'></style>
<style scoped>
.small-text{
  display: block;
  font-size: 12px;
  color: #555555;
}
</style>

<script>
import { Form, ErrorMessage } from 'vee-validate'
import _ from 'lodash'
import TermsAndConditionsModel from '../../models/TermsAndConditionsModel'
import TermsAndConditionsService from '../../services/termsAndConditions.service'
import TinymceEditor from '@/components/general/tinymce-editor.vue'

export default {
  name: 'TermsAndConditions',
  data () {
    return {
      TermsAndConditionsModel: new TermsAndConditionsModel(),
      itemBlueprints: [],
      openAddForm: false,
      termsAndConditions: []
    }
  },
  components: { Form, ErrorMessage, TinymceEditor },
  watch: {
  },
  mounted () {
    this.getTermsAndConditions()
  },
  methods: {
    loadDataTable: async () => {
      // eslint-disable-next-line no-undef
      setTimeout($GLOBAL.loadTable('#table1'), 100000)
    },
    getTermsAndConditions () {
      TermsAndConditionsService.getTermsAndConditions().then((response) => {
        if (response.data.status) {
          this.termsAndConditions = _.map(response.data.objects.termsAndConditions, function (b) {
            return new TermsAndConditionsModel().load(b)
          })
        }
      })
    },
    reset () {
      this.TermsAndConditionsModel = new TermsAndConditionsModel()
      // Clear Rich Text Field
      const parentElement = document.querySelectorAll('.ql-editor')
      for (let i = 0; i < parentElement.length; i++) {
        while (parentElement[i].firstChild) {
          parentElement[i].removeChild(parentElement[i].firstChild)
        }
        const newParagraph = document.createElement('p')
        parentElement[i].appendChild(newParagraph)
      }
    },
    editTermsAndConditions (TermsAndConditionsModel) {
      this.TermsAndConditionsModel = TermsAndConditionsModel
      this.openAddForm = true
    },
    updateTermsAndConditions () {
      const vm = this
      TermsAndConditionsService.updateTermsAndConditions(this.TermsAndConditionsModel).then(
        response => {
          if (response.data.status) {
            this.getTermsAndConditions()
            this.openAddForm = false
            vm.reset()
          }
        }
      )
    },
    deleteTermsAndConditions (termsId) {
      const vm = this
      TermsAndConditionsService.deleteTermsAndConditions(termsId).then(
        response => {
          if (response.data.status) {
            vm.termsAndConditions = _.filter(vm.termsAndConditions, function (p) { return p.id !== termsId })
            vm.reset()
            this.openAddForm = false
            this.getTermsAndConditions()
          }
        }
      )
    }
  }
}
</script>
