<template>
  <div class='page-heading'>
    <div class='page-title'>
      <div class='row'>
        <div class='col-12 col-md-6 order-md-1 order-last'>
          <h3>All Ads Settings</h3>
          <p class='text-subtitle text-muted'>
            View your added Ads Settings and add new ones
          </p>
        </div>
        <div class='col-12 col-md-6 order-md-2 order-first'>
          <nav
            aria-label='breadcrumb'
            class='breadcrumb-header float-start float-lg-end'
          >
            <ol class='breadcrumb'>
              <li class='breadcrumb-item'>
                <router-link :to="{ name: 'home' }">Dashboard</router-link>
              </li>
              <li class='breadcrumb-item active' aria-current='page'>
                All Ads Settings
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <section id='multiple-column-form'>
      <div class='row match-height'>
        <div class='col-12'>
          <div class='card'>
            <div class='card-header'>
              <button
                @click='addAction'
                class='card-title btn btn-outline-dark'
              >
                Add New Ads
              </button>
            </div>
            <div v-show='openAddForm' class='card-content'>
              <div class='card-body'>
                <Form
                  class='form'
                  v-slot='{ handleSubmit }'
                  @submit.prevent='createAdsSettings'
                  :validation-schema='ValidationSchema'
                >
                  <div class='row'>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='siteUrl'>Site Url</label>
                        <Field
                          type='text'
                          class='form-control'
                          name='siteUrl'
                          placeholder='Site Url'
                          v-model="AdsSettings.site_url"
                        />
                        <ErrorMessage class='text-danger' name='siteUrl' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class="form-group">
                        <label for="imageUrl">Ads Image</label>
                        <Field @change="onAdsUpload" type="file" class="form-control" name="adsImage" placeholder="Ads Image" id="input_file"/>
                        <ErrorMessage class="text-danger" name="adsImage" />
                        <img class="img mt-2" :height="selectedPosition===2 || selectedPosition===3 ? 200 : 60" :width="selectedPosition!==2 && selectedPosition!==3 ? 500 : 80" :src="editImageURL" alt="ad Image">
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='pageType'>Page</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="pageType"
                          placeholder="Ads Page type"
                          v-model="AdsSettings.page_type"
                        >
                          <option disabled selected hidden value="">
                            Select Page Type
                          </option>
                          <option v-for="pageType in pageTypeOptions" :key="pageType.id" :value="pageType.id">
                            {{ pageType.title }}
                          </option>
                        </Field>
                        <ErrorMessage class='text-danger' name='pageTypeError' />
                      </div>
                    </div>
                    <div class='col-md-6 col-12'>
                      <div class='form-group'>
                        <label for='position'>Position</label>
                        <Field
                          as="select"
                          class="form-control"
                          name="position"
                          placeholder="Ads position"
                          v-model="AdsSettings.position"
                        >
                          <option disabled selected hidden value="">
                            Select Ads Position
                          </option>
                          <option v-for="position in positionOptions" :key="position.id" :value="position.id">
                            {{ position.title }}
                          </option>
                        </Field>
                        <ErrorMessage class='text-danger' name='positionError' />
                      </div>
                    </div>
                    <div class='col-12 d-flex justify-content-end'>
                      <button
                        class='btn btn-primary me-1 mb-1' v-show="AdsSettings.id==='undefined' || AdsSettings.id===0"
                        @click='handleSubmit($event, createAdsSettings)'
                      >
                        Create
                      </button>
                      <button
                        v-show="AdsSettings.id!=='undefined' && AdsSettings.id!==0"
                        class='btn btn-primary me-1 mb-1'
                        @click='handleSubmit($event, updateService)'
                      >
                        Update
                      </button>
                      <button
                        class='btn btn-secondary me-1 mb-1'
                        @click='reset'
                      >
                        Reset
                      </button>
                      <button
                        class='btn btn-danger me-1 mb-1'
                        @click='openAddForm = false'
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- // Basic multiple Column Form section end -->
    <!-- Basic Tables start -->
    <section class='section'>
      <div class='card'>
        <div class='card-body'>
          <table class='table table-striped' id='table1'>
            <thead>
            <tr>
              <th>#</th>
              <th>Site Url</th>
              <th>Ads Image</th>
              <th>Page Type</th>
              <th>Position</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for='(ad, index) in ads' :key='ad.id' >
              <td style="width: 5%">{{ index+1 }}</td>
              <td style="width: 20%">{{ ad.site_url }}</td>
              <td style="width: 40%; text-align: center"><img class="img" :height="ad.position===2 || ad.position===3 ? 180 : 60" :width="ad.position!==2 && ad.position!==3 ? 450 : 80" :src="ad.image_url" alt="ad Image"></td>
              <td style="width: 10%">{{ ad.page_type === 1 ? 'Home Page' : 'Other Page'}}</td>
              <td style="width: 8%">{{ ad.position}}</td>
              <td style="width: 17%">
                <button class='badge bg-danger mr-5' @click="deleteAdsSettings(ad.id)">Delete</button>
                <button class='badge bg-primary ml-5' @click="editAdsSettings(ad)">Edit</button>
              </td>
            </tr>
            <tr v-if='!ads.length'>
              <td colspan='6' class='text-center'>No entries found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <!-- Basic Tables end -->
  </div>
</template>

<style scoped>
</style>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate'
import _ from 'lodash'

import AdsSettings from '../../models/AdsSettings'
import AdsSettingsService from '@/services/adsSettings.service'
import FileService from '../../services/file.service'
export default {
  name: 'AdsSettings',
  data () {
    return {
      // ValidationSchema: ValidationService.createUpdateSpecification,
      AdsSettings: new AdsSettings(),
      itemBlueprints: [],
      ads: [],
      openAddForm: false,
      adsImage: [],
      editImageURL: '',
      selectedPageType: '',
      selectedPosition: '',
      pageTypeOptions: [
        {
          id: 1,
          title: 'Home Page'
        },
        {
          id: 2,
          title: 'Other Pages'
        }
      ],
      positionOptions: [
        {
          id: 1,
          title: 'Top of the Page'
        },
        {
          id: 2,
          title: 'Left side of the Page'
        },
        {
          id: 3,
          title: 'Right side of the Page'
        },
        {
          id: 4,
          title: 'Bottom of the Page'
        },
        {
          id: 5,
          title: 'Bottom of the Banner Section (Home Page)'
        },
        {
          id: 6,
          title: 'Bottom of the Our Major Services Section (Home Page)'
        },
        {
          id: 7,
          title: 'Bottom of the All Commercial Vehicle Section (Home Page)'
        },
        {
          id: 8,
          title: 'Bottom of the Popular Brands Section (Home Page)'
        }
      ]
    }
  },
  components: { Form, Field, ErrorMessage },
  watch: {
  },
  mounted () {
    this.getAdsSettings()
  },
  methods: {
    onAdsUpload (event) {
      const files = event.target.files ? event.target.files : null
      this.adsImage = []
      if (!files.length) { return }
      FileService.prepareFileForUpload(files, this.AdsSettings.id, 'website-ads').then(
        response => {
          this.adsImage = response
          this.AdsSettings.image_url = response
        },
        error => {
          console.log(error)
          alert('Error while trying to process uploaded files')
        }
      )
    },
    getAdsSettings () {
      this.ads = []
      AdsSettingsService.getAds().then(
        response => {
          if (response.data.status) {
            this.itemBlueprints = _.map(response.data.objects.adsSettings, function (p) {
              return new AdsSettings().load(p)
            })
            this.ads = _.map(response.data.objects.adsSettings, function (b) {
              return new AdsSettings().load(b)
            })
          }
        }
      )
    },
    addAction () {
      this.reset()
      this.openAddForm = !this.openAddForm
    },
    reset () {
      this.AdsSettings = new AdsSettings()
      this.selectedPageType = ''
      this.selectedPosition = ''
      this.editImageURL = ''
      document.getElementById('input_file').value = ''
    },
    createAdsSettings () {
      const vm = this
      AdsSettingsService.createAds(this.AdsSettings).then(
        response => {
          if (response.data.status) {
            vm.ads.push(new AdsSettings().load(response.data.objects.adsSettings))
            this.getAdsSettings()
            vm.reset()
          }
        }
      )
    },
    editAdsSettings (AdsSettings) {
      this.AdsSettings = AdsSettings
      this.editImageURL = this.AdsSettings.image_url
      this.selectedPageType = this.AdsSettings.page_type
      this.selectedPosition = this.AdsSettings.position
      this.openAddForm = true
    },
    updateService () {
      const vm = this
      AdsSettingsService.updateAds(this.AdsSettings).then(
        response => {
          if (response.data.status) {
            const services = _.find(vm.ads, function (p) { return p.id === vm.AdsSettings.id })
            services.load(response.data.objects.adsSettings)
            vm.reset()
            this.openAddForm = false
            this.getAdsSettings()
          }
        }
      )
    },
    deleteAdsSettings (adsId) {
      const vm = this
      AdsSettingsService.deleteAds(adsId).then(
        response => {
          if (response.data.status) {
            vm.adsSettings = _.filter(vm.adsSettings, function (p) { return p.id !== adsId })
            vm.reset()
            this.openAddForm = false
            this.getAdsSettings()
          }
        }
      )
    }
  },
  computed: {
  }
}
</script>
