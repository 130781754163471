import Config from './config'

const api = Config.api

const brochureAPIRoot = api.BASE_URL + '/brochure'

class BrochureService {
  createBrochure (BrochureModel) {
    return api.postCall(brochureAPIRoot, BrochureModel, api.ShowAlert, api.ShowAlert)
  }

  getBrochures () {
    return api.getCall(brochureAPIRoot, api.DoNothing, api.ShowAlert)
  }

  updateBrochure (BrochureModel) {
    return api.putCall(brochureAPIRoot + '/' + BrochureModel.id, BrochureModel, api.ShowAlert, api.ShowAlert)
  }

  deleteBrochure (brochureId) {
    return api.deleteCall(brochureAPIRoot + '/' + brochureId, api.ShowAlert, api.ShowAlert)
  }
}
export default new BrochureService()
