import UtilityService from '../services/utility.service'
export default class VehicleTypeModel {
  constructor (typeId) {
    this.id = 0
    this.question = ''
    this.question_bangla = ''
    this.answer = ''
    this.answer_bangla = ''
    this.status = true
    this.created_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
  }

  load (data) {
    this.id = data.id
    this.question = data.question
    this.question_bangla = data.question_bangla
    this.answer = data.answer
    this.answer_bangla = data.answer_bangla
    this.status = data.status === 1
    this.created_at = UtilityService.formatDateTime(
      data.created_at,
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      data.updated_at,
      'MM/DD/YY hh:mm A'
    )
    return this
  }
}
